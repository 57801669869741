import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import baobab from '@data/state';
import { CustomerModel, ReservationGuestReferenceModel, ReservationModel } from '@common/modelDefinition';
import translate from '@data/translations';
import { DocumentScanner } from './DocumentScanner';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import _ from 'lodash';
import { paymentCategoryEVisitor } from '@common/eGuestsData/eVisitor';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
interface VisaDataModalProps {
    visaData: ReservationGuestReferenceModel | undefined;
    saveHandler: any;
    customer: CustomerModel;
    reservation: ReservationModel;
    docScan: any;
    otherSettings: any;
}

const VisaDataModal = (props: VisaDataModalProps) => {
    const { visaData, saveHandler, customer, reservation } = props;
    const [visaDataClone, setVisaDataClone] = React.useState({ ...visaData } as ReservationGuestReferenceModel);
    const [scannedCustomer, setScannedCustomer] = React.useState(null as CustomerModel | null);
    const [showDocumentScannerData, setShowDocumentScannerData] = React.useState(false);
    const [customersAfterScan, setCustomersAfterScan] = React.useState({
        data: [],
        typeOfScan: 0,
        visaInfo: {} as ReservationGuestReferenceModel,
        customer: null as CustomerModel | null,
    });

    const documentId = React.useRef(null);
    const documentType = React.useRef(null);

    const { otherSettings } = useBranch({
        otherSettings: ['otherSettings'],
    });

    React.useEffect(() => {
        if (visaData) {
            checkValidationInputs(visaData);
            setVisaDataClone({ ...visaData });
        }
        // eslint-disable-next-line
    }, [visaData]);

    React.useEffect(() => {
        const loadData = async () => {
            if (customersAfterScan.visaInfo.customerId) {
                let _visaData: any = { ...visaDataClone };
                const scannedVisaData: any = { ...customersAfterScan.visaInfo };
                Object.keys(visaDataClone).forEach((d: any) => {
                    if (scannedVisaData && scannedVisaData[d]) {
                        _visaData[d] = scannedVisaData[d];
                    }
                });
                // const _visaData = { ...customersAfterScan.visaInfo, id: visaDataClone.id };
                checkValidationInputs(_visaData);
                setVisaDataClone(_visaData);
            }
            if (customersAfterScan.customer) {
                setScannedCustomer({ ...customersAfterScan.customer });
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [customersAfterScan]);

    const handleInputChange = (e: any) => {
        const _visaDataClone: any = { ...visaDataClone };
        _visaDataClone[e.target.name] = e.target.value === 'noSelected' ? null : e.target.value;
        if (_visaDataClone[e.target.name] === '') {
            _visaDataClone[e.target.name] = null;
        }
        if (e.target.name === 'documentId' || e.target.name === 'documentType') {
            checkValidationInputs(_visaDataClone);
        }
        setVisaDataClone({ ..._visaDataClone });
    };

    const handleDate = (attribute: string, d: any) => {
        if (attribute === 'visaEntryDate') {
            let date = moment(d).format('YYYY-MM-DD');
            visaDataClone['visaEntryDate'] = date;
        }
        if (attribute === 'visaValidityPeriod') {
            let date = moment(d).format('YYYY-MM');
            visaDataClone['visaValidityPeriod'] = date;
        }
        if (attribute === 'documentValidityPeriod') {
            let date = moment(d).format('YYYY-MM');
            visaDataClone['documentValidityPeriod'] = date;
        }
        setVisaDataClone({ ...visaDataClone });
    };

    const closeModal = () => {
        baobab.root.select(['modalData', 'display']).set(false);
        baobab.root.select(['modalData', 'content']).set(null);
    };

    const checkValidationInputs = (objToValidate: any) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    };

    const markInvalidInputRefs = (validationResults: any) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data: any) => {
        const invalidInputs = [],
            validInputs = [];
        // clearValidationRefs();
        if (data.documentId === null || (data.documentId && data.documentId.trim() === '')) {
            invalidInputs.push(documentId);
        } else {
            validInputs.push(documentId);
        }
        if (data.documentType === null || (data.documentType && data.documentType.trim() === '')) {
            invalidInputs.push(documentType);
        } else {
            validInputs.push(documentType);
        }
        return { invalidInputs, validInputs };
    };

    return (
        <div className="">
            <div className="display-flex">
                <div
                    className="button-info pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowDocumentScannerData(!showDocumentScannerData);
                    }}
                >
                    <small>{translate('READ FROM SCANNER')}</small>
                </div>
            </div>

            {showDocumentScannerData ? (
                <DocumentScanner
                    docScan={props.docScan}
                    customer={customer}
                    reservation={reservation}
                    setCustomersAfterScan={setCustomersAfterScan}
                    setShowDocumentScannerData={setShowDocumentScannerData}
                    visa={true}
                />
            ) : (
                <>
                    <div className="mt-12p display-flex">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Visa Type And Number')}
                            </label>
                            <input
                                autoComplete="off"
                                name="visaTypeAndNumber"
                                placeholder={translate('246694821')}
                                type="text"
                                className="input input-modal"
                                value={visaDataClone.visaTypeAndNumber ? visaDataClone.visaTypeAndNumber : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Visa Validity Period')}
                            </label>
                            <div>
                                <DayPickerInput
                                    // name="visaValidityPeriod"
                                    value={
                                        visaDataClone.visaValidityPeriod
                                            ? moment(visaDataClone.visaValidityPeriod).format(
                                                  props.otherSettings?.dateFormat
                                                      ? props.otherSettings.dateFormat
                                                      : 'DD/MM/YYYY'
                                              )
                                            : ''
                                    }
                                    format={
                                        props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'
                                    }
                                    placeholder={
                                        props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'
                                    }
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    onDayChange={handleDate.bind(this, 'visaValidityPeriod')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="display-flex mt-12p">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Date entry in country')}
                            </label>
                            <div>
                                <DayPickerInput
                                    // name="visaEntryDate"
                                    value={
                                        visaDataClone.visaEntryDate
                                            ? moment(visaDataClone.visaEntryDate).format(
                                                  props.otherSettings?.dateFormat
                                                      ? props.otherSettings.dateFormat
                                                      : 'DD/MM/YYYY'
                                              )
                                            : ''
                                    }
                                    format={
                                        props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'
                                    }
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    placeholder={
                                        props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'
                                    }
                                    onDayChange={handleDate.bind(this, 'visaEntryDate')}
                                />
                            </div>
                        </div>
                        <div className="ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Entry place')}
                            </label>
                            <input
                                autoComplete="off"
                                name="placeEntry"
                                placeholder={translate('Kamensko')}
                                type="text"
                                className="input input-modal"
                                value={visaDataClone.placeEntry ? visaDataClone.placeEntry : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="display-flex mt-12p">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Document id')}
                            </label>
                            <input
                                ref={documentId}
                                autoComplete="off"
                                name="documentId"
                                placeholder={
                                    customer.documentId ? "'" + customer.documentId + "'" : translate('128674512')
                                }
                                type="text"
                                className="input input-modal"
                                value={visaDataClone.documentId ? visaDataClone.documentId : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Document type')}
                            </label>
                            <select
                                ref={documentType}
                                name="documentType"
                                className="input input-modal"
                                value={visaDataClone.documentType ? visaDataClone.documentType : ''}
                                onChange={(e) => {
                                    handleInputChange(e);
                                }}
                            >
                                <option value={0}>{translate('Nothing selected')}</option>
                                <option value={'P'}>{translate('Passport')}</option>
                                <option value={'I'}>{translate('Identity card')}</option>
                                <option value={'V'}>{translate('Visa')}</option>
                                <option value={'D'}>{translate('Driving licence')}</option>
                                <option value={'X'}>{translate('Other')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="display-flex mt-12p">
                        <div className="flex mr-8p">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Document Validity Period')}
                            </label>
                            <div className="">
                                <DayPickerInput
                                    // name="documentValidityPeriod"
                                    value={
                                        visaDataClone.documentValidityPeriod
                                            ? moment(visaDataClone.documentValidityPeriod).format(
                                                  props.otherSettings?.dateFormat
                                                      ? props.otherSettings.dateFormat
                                                      : 'DD/MM/YYYY'
                                              )
                                            : ''
                                    }
                                    format={
                                        props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'
                                    }
                                    placeholder={
                                        props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'
                                    }
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    onDayChange={handleDate.bind(this, 'documentValidityPeriod')}
                                />
                            </div>
                        </div>
                    </div>
                    {otherSettings?.csvDownloadTemplate === 'eVisitor' ? (
                        <div className="display-flex mt-12p">
                            <div className="flex ml-8p">
                                <label className="text-bold ml-4p" htmlFor="">
                                    {translate('Payment category')}:
                                </label>
                                <select
                                    name="paymentCategory"
                                    className="input input-modal"
                                    value={visaDataClone.paymentCategory ? visaDataClone.paymentCategory : ''}
                                    onChange={(e: any) => {
                                        handleInputChange(e);
                                    }}
                                >
                                    <option value={'noSelected'}>{translate('Nothing selected')}</option>
                                    {paymentCategoryEVisitor?.map((p) => {
                                        return <option value={p.Code}>{p.Name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    ) : null}
                    <div className="mt-12p">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Notes')}
                        </label>
                        <input
                            autoComplete="off"
                            name="note"
                            placeholder={translate('Notes')}
                            type="text"
                            className="input input-modal"
                            value={visaDataClone.note ? visaDataClone.note : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <br />
                    <div className="display-flex mt-16p mb-8p justify-content-end">
                        <div data-dismiss="modal" onClick={() => closeModal()} className="button-secondary mr-8p">
                            {translate('Cancel')}
                        </div>
                        <div
                            data-dismiss="modal"
                            onClick={() => {
                                let updateCustomer = false;
                                if (
                                    scannedCustomer ||
                                    (visaDataClone.documentId && visaDataClone.documentId !== customer.documentId) ||
                                    (visaDataClone.documentType && visaDataClone.documentType !== customer.documentType)
                                ) {
                                    updateCustomer = true;
                                }
                                saveHandler(visaDataClone, scannedCustomer, updateCustomer);
                            }}
                            className="button-primary"
                        >
                            {translate('Save')}
                        </div>
                    </div>{' '}
                </>
            )}
        </div>
    );
};

export default branch(
    {
        docScan: ['model', 'DocumentScan'],
        otherSettings: ['otherSettings'],
    },
    VisaDataModal
);
