import Baobab from 'baobab';

var tags = {
    RoomInfo: Baobab.monkey({
        cursors: {
            RoomInfo: ['model', 'RoomInfo'],
        },
        get: (data) => {
            const arrayOfTags = [];
            data.RoomInfo.forEach((p) => {
                if (p.tags) {
                    arrayOfTags.push(p.tags);
                }
            });
            const roomsTags = [];
            arrayOfTags.forEach((opt) => {
                opt.split(';').forEach((o) => {
                    if (!roomsTags.includes(o)) {
                        roomsTags.push(o);
                    }
                });
            });
            return roomsTags;
        },
    }),

    AccommodationPriceList: Baobab.monkey({
        cursors: {
            AccommodationPriceList: ['model', 'AccommodationPriceList'],
        },
        get: (data) => {
            const arrayOfTags = [];
            data.AccommodationPriceList.forEach((p) => {
                if (p.tags) {
                    arrayOfTags.push(p.tags);
                }
            });

            let accommodationsTags = [];
            arrayOfTags.forEach((opt) => {
                opt.split(';').forEach((o) => {
                    if (!accommodationsTags.includes(o)) {
                        accommodationsTags.push(o);
                    }
                });
            });
            return accommodationsTags;
        },
    }),

    WhiteList: Baobab.monkey({
        cursors: {
            WhiteList: ['model', 'WhiteList'],
        },
        get: (data) => {
            const arrayOfTags = [];
            data.WhiteList.forEach((p) => {
                if (p.tags) {
                    arrayOfTags.push(p.tags);
                }
            });

            const whiteListsTags = [];
            arrayOfTags.forEach((opt) => {
                opt.split(';').forEach((o) => {
                    if (!whiteListsTags.includes(o)) {
                        whiteListsTags.push(o);
                    }
                });
            });

            return whiteListsTags;
        },
    }),
};
export default tags;
