import React, { Component } from 'react';

class SRCDeviceError extends Component {
    render() {
        return (
            <div className="alert alert-danger" role="alert">
                {' '}
                {window.translate("SRC DEVICE COMMUNICATION ERROR!")}{' '}
            </div>
        );
    }
}

export default SRCDeviceError;
