import React, { Component } from 'react';
// import Navbar from '../components/header/navbar';
// import Foother from '../components/footer/footer';
import Toaster from '../components/toaster';
import ModalController from '../components/modal';
import BackendDrivenToaster from './backendDrivenToaster';
import { GlobalLoading } from '../components/loaders/globalLoading';
import SideMenu from '../components/sideMenu';
import SideMenuMobile from '../components/sideMenu/sideMenuMobile';
import rest from '../data/restWrapper';

// const zendeskSearchEnum = {
//     '/household':'Domaćinstvo',
//     '/reservations':'Rezervacija',
//     '/':"Rezervacija",
//     '/groupReservations':'Grupna rezervacija',
//     '/roomsAdministration':'Sobe cjenici',
//     '/priceLists/accommodation':'Sobe cjenici',
//     '/posTerminal':'POS', // ...

// }

class MasterLayout extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
        this.state = {
            otherSettings: {},
            disableSidebarMenu: false,
            mobileView: false,
        };
    }
    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname && this.myRef.current) {
            this.myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        if (prevProps.fixedRoute !== this.props.fixedRoute) {
            this.redrawComponent();
        }
              this.hideWidget()
    }

    async redrawComponent() {
        try {
            if (this.props.fixedRoute && this.props.fixedRoute !== '') {
                this.setState({ disableSidebarMenu: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        try {
            if (this.getWidth() <= 600) {
                this.setState({ mobileView: true });
            }
            // const navbarEL = document.getElementById('app-navbar');

            // if (window.location?.origin?.includes(`test`) || window.location?.origin?.includes(`demo`)) {
            //     navbarEL.classList.add('special-class-for-demo-and-test');
            // }

            // let time = new Date().getTime();
            // const setActivityTime = (e) => {
            //     time = new Date().getTime();
            // }
            // document.body.addEventListener("mousemove", setActivityTime);
            // document.body.addEventListener("keypress", setActivityTime);
            // const refresh = () => {
            //     if (new Date().getTime() - time >= 60000) { // nakon minute
            //         window.location.reload(true);
            //     } else {
            //         setTimeout(refresh, 10000);
            //     }
            // }
            // setTimeout(refresh, 10000);

            const data = await rest('/getGeneralSettingByKey/otherSettings');
            if (data?.params) {
                this.setState({ otherSettings: JSON.parse(data.params) });
            }
            if (this.props.fixedRoute && this.props.fixedRoute !== '') {
                this.setState({ disableSidebarMenu: true });
            }
        //    this.loadZendeskScript()
        } catch (error) {
            console.log(error);
        }
    }

    // <!-- Start of pimatico Zendesk Widget script -->
    // <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=de6261c0-d16b-43bb-88a2-eb8fc4f89a03"> </script>
    // <!-- End of pimatico Zendesk Widget script -->

    // loadZendeskScript() {
    //     const script = document.createElement('script');
    //     script.src = "https://static.zdassets.com/ekr/snippet.js?key=8c755774-9ddc-49bf-86c7-8f0e5cd2d252";
    //     script.id = 'ze-snippet';
    //     document.body.appendChild(script);
    //   }

    //   updateZendeskWidgetSearch() {
    //     if (window.zE) {
    //         const {location} = this.props
    //         const pathName = location?.pathname && location.pathname.length>0 ? location.pathname :
    //          location?.search && location.search.length>0 ? location.search : null

    //          const searchTerm = zendeskSearchEnum[pathName] ? zendeskSearchEnum[pathName] : 'Rezervacija'
    //         window.zE('webWidget', 'close');  
    //         window.zE('webWidget', 'show');  

    //          if(pathName){
    //             window.zE('webWidget', 'helpCenter:setSuggestions',{ search:`${searchTerm}` })
    //          }else{
    //             window.zE('webWidget', 'helpCenter:setSuggestions',{ search: 'Rezervacija' })
    //          }
    //   }
    // }

    hideWidget() {
        if (window.zE && this.props.location.pathname.lastIndexOf('/help') === -1) {              
            window.zE('webWidget', 'close');  
            window.zE('webWidget', 'hide');  
      }
    }

    render() {
        if (
            this.props.location.pathname.lastIndexOf('/invoiceView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/proformaView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/printPreview') !== -1 ||
            this.props.location.pathname.lastIndexOf('/cashRegReportView') !== -1 ||
            this.props.location.search.lastIndexOf('printJournal') !== -1 || 
            this.props.location.pathname.lastIndexOf('/billingSummaryInvoiceView') !== -1
        ) {
            return (
                <div>
                    {' '}
                    <ModalController />
                    <GlobalLoading />
                    {this.props.children}{' '}
                    <div className="d-print-none">
                        <Toaster />
                    </div>
                    <BackendDrivenToaster />
                </div>
            );
        } else if (this.props.location.pathname.startsWith('/documentScanner')) {
            const bgCl = ' docScan-bg ';
            return (
                <div>
                    <div className={bgCl + ' docScanner-background'}>
                        {this.props.children}
                    </div>
                    <div className="d-print-none">
                        <Toaster />
                    </div>
                    <BackendDrivenToaster />
                </div>
            );
        } else {
            return (
                <div
                    ref={this.myRef}
                    id={
                        this.state.mobileView ? 'master-layout-mobile' : 'master-layout'
                    } /* style={{ userSelect: 'none' }} */
                >
                    <GlobalLoading />
                    {/* <Navbar {...this.props} showSideMenu={this.state?.otherSettings?.showSideMenu} /> */}
                    <ModalController />
                    <div
                        className={'reception-background  w-100'}
                    >
                        <div className="d-flex h-100 app-flex-div">
                            {/* {showSideMenu ? <SideMenu {...this.props} /> : null} */}

                            <SideMenu {...this.props} />
                            <div className="scrollX" id="main-app-div" style={{ width: '100%' }}>
                                {!this.props.authTokenInfo?.user?.role ? (
                                    <div>Insufficient permissions</div>
                                ) : (
                                    this.props.children
                                )}
                            </div>

                            <div className="master-layout-clear clear pt-2">&ensp;</div>
                        </div>
                    </div>

                    <div className="d-print-none">
                        <Toaster />
                    </div>
                    {/* <Foother {...this.props} /> */}
                    <BackendDrivenToaster />

                    <SideMenuMobile {...this.props}></SideMenuMobile>
                </div>
            );
        }
    }
}

export default MasterLayout;
