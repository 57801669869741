// import React from "react";
// import menuItems2 from "@components/sideMenu/menuItems2";
import asyncComponent from "@components/asyncComponent";

// import PageNotFound from'../../pages/pageNotFound';
// import PageLoader from '@components/loaders/loader1';

// import MasterLayout from'../../pages/masterLayout';
// import Dashboard from'@pages/dashboard';

import FeatureFlagsList from "@pages/featureFlags"
import FinancialJournal from '@pages/dashboard/financialJournal';
import Dashboard from "@pages/dashboard"


const BMS = asyncComponent(() => import('../../pages/BMS'));

const PosItem = asyncComponent(() => import('../../pages/priceList/pos/posItem/index'));
const Tax = asyncComponent(() => import('../../pages/priceList/tax/index'));
const AccommodationPriceList = asyncComponent(() => import('../../pages/priceList/accommodation/index'));
const PricelistLookup = asyncComponent(() => import('../../pages/priceList/pricelistLookup'));
const AccommodationPriceListStack = asyncComponent(() => import('../../pages/priceList/accommodation/index2'));

const Currencies = asyncComponent(() => import('../../pages/priceList/currencies/index'));
const ExchangeRate = asyncComponent(() => import('../../pages/priceList/currencies/exchangeRate'));
const GuestsBook = asyncComponent(() => import('../../pages/priceList/businessConclusion/guestsBook'));

const RoomsAdministration = asyncComponent(() => import('../../pages/roomInfo/roomsAdministration'));
const RoomInfoForm = asyncComponent(() => import('../../pages/roomInfo/form'));
const RoomAttributes = asyncComponent(() => import('../../pages/roomInfo/roomAttributes'));

const ReservationsAdmin = asyncComponent(() => import('../../pages/reservations2/administration'));

const AccessControl = asyncComponent(() => import('../../pages/accessControl'));
const Whitelist = asyncComponent(() => import('../../pages/whitelist'));
const WhiteListItems = asyncComponent(() => import('../../pages/whitelist/whiteListItems'));

const SRCProxyForm = asyncComponent(() => import('../../pages/SRCProxy/form'));
const SRCProxy = asyncComponent(() => import('../../pages/SRCProxy/index'));
const SRCProxyAdministration = asyncComponent(() => import('../../pages/SRCProxy/controllerAdministration'));
const SRCRoomBinder = asyncComponent(() => import('../../pages/SRCProxy/roomBinder'));
const SRCBroadcastMulticast = asyncComponent(() => import('../../pages/SRCBroadcastMulticast/index.js'));

const RoomInfo = asyncComponent(() => import('../../pages/roomInfo'));

const SrcTemplate = asyncComponent(() => import('../../pages/srcTemplate/index'));
const SrcTemplateForm = asyncComponent(() => import('../../pages/srcTemplate/form'));

const AllRoomEvents = asyncComponent(() => import('../../pages/allRoomEvents'));
const RoomEventHistory = asyncComponent(() => import('../../pages/allRoomEvents/roomEventHistory'));

const SRCSettings = asyncComponent(() => import('../../pages/SRCSettings'));
const NotificationTemplateSettings = asyncComponent(() => import('../../pages/notificationTemplateSettings'));
const NotificationTemplates = asyncComponent(() => import('../../pages/notificationTemplateSettings/templates'));
const QrCodeGenerator = asyncComponent(() => import('../../pages/roomInfo/qrGenerator'));
const AccessControlQRGenerator = asyncComponent(() => import('../../pages/accessControl/qrGenerator'));
const DoorRelayControl = asyncComponent(() => import('../../pages/accessControl/doorRelayControl'));

// const Login = asyncComponent(() => import('../../authPages/login/index'));
const ApiKeys = asyncComponent(() => import('../../pages/users/apiKeys'));

const CheckoutAndInvoice = asyncComponent(() => import('../../pages/checkoutAndInvoice/checkout/CheckoutAndInvoice'));
const InvoiceView = asyncComponent(() => import('../../pages/checkoutAndInvoice/printView/PrintView'));
//import ProFormaView from '../../pages/checkoutAndInvoice/proforma';

const BillingSummaryInvoiceView = asyncComponent(() => import('../../pages/billing/billingInvoiceView'))

const Users = asyncComponent(() => import('../../pages/users'));
// const UserForm = asyncComponent(() => import('../../pages/users/form'));

const ItemsList = asyncComponent(() => import('../../pages/posTerminal/admin/itemsList'));
const PosDisplayConfig = asyncComponent(() => import('../../pages/posDisplayConfig'));
const TablesLayout = asyncComponent(() => import('../../pages/posTables'));
const TableArrangement = asyncComponent(() => import('../../pages/posTables/newTableArrangement'));

// import PosInvoice from "../../pages/posTerminal/posInvoice"

const Licence = asyncComponent(() => import('../../pages/licence'));
const RequestLicence = asyncComponent(() => import('../../pages/licence/requestLicence'));

// import GeneralSettingsForm from '../../pages/generalSettings';

const CheckRoomMinibar = asyncComponent(() => import('../../pages/household/minibar/checkRoomMinibar2'));
const VisaInfo = asyncComponent(() => import('../../pages/visaInfo'));
const Search = asyncComponent(() => import('../../pages/search/results'));
const PosTerminalAdmin = asyncComponent(() => import('../../pages/posTerminal/admin/admin'));
const PosTerminalView = asyncComponent(() => import('../../pages/posTerminal/posView/posView'));
const PosTerminalInvoices = asyncComponent(() => import('../../pages/posTerminal/sideMenu/invoices'));
// const PosTerminalInvoiceConclusions = asyncComponent(() => import('../../pages/posTerminal/sideMenu/invoiceConclusion'));
const StateAndInvoiceConclusion = asyncComponent(() => import('../../pages/posTerminal/sideMenu/stateAndInvoiceConclusion'));

const DocScannerDevices = asyncComponent(() => import('../../pages/documentScannerDevices'));

const GeneralSettings = asyncComponent(() => import( "../../pages/generalSettings"))
const RoomsAndPriceLists = asyncComponent(() => import( "../../pages/roomPriceListTags"))
const FirstTimeSetup = asyncComponent(() => import( "../../pages/generalSettings/firstTimeSetup"))
const DocumentScanner = asyncComponent(() => import( "../../pages/documentScanner"))
const Billing = asyncComponent(() => import( "../../pages/billing/index"))
const BillingInvoicePage = asyncComponent(() => import( "../../pages/billing/billingInvoicePage"))

const SelfCheckInAdministrative = asyncComponent(() => import( "../../pages/selfCheckIn"))

const CashRegistars = asyncComponent(() => import( "../../pages/cashRegistar"))
const CashRegistarPage = asyncComponent(() => import( "../../pages/cashRegistar/cashRegistar"))
const UncollectedBills = asyncComponent(() => import( "../../pages/cashRegistar/parts/uncollectedBills"))
const CashRegPaymentPrintView = asyncComponent(() => import( "../../pages/cashRegistar/parts/printPreview"))

//REPORTS
const FinancialReport = asyncComponent(() => import( "../../reports/financialReport/financialReport"))
const GuestsBookForEditing = asyncComponent(() => import( "../../reports/reservationsReport/guestsBookForEditing"))
const GuestsBookReservations = asyncComponent(() => import( "../../reports/reservationsReport/guestsBookReservations"))

const AccessControlDetails = asyncComponent(() => import( '../../reports/accessControl/doorAccessLogs'))

const SosGroups = asyncComponent(() => import( "../../pages/sosGroups"))

//page menus
// const SystemMenu = asyncComponent(() => import( '../../pages/menus/system'))
const ReportsMenu = asyncComponent(() => import( '../../pages/menus/reports'))

const ReservationReportMenu = asyncComponent(() => import( '../../reports/reservationsReport/index'))
const InvoicesReportMenu = asyncComponent(() => import( '../../reports/invoicesReport/index'))
const RoomEventsReportMenu = asyncComponent(() => import( '../../reports/roomEventsReport/index'))
const AccessControlReportMenu = asyncComponent(() => import( '../../reports/accessControl/index'))
const RetailManagementReportMenu = asyncComponent(() => import( '../../reports/goodsManagement/index'))


const Test = asyncComponent(() => import( '../../pages/test'))

//END REPORTS

const Household = asyncComponent(() => import( '../../pages/household/householdDashboard'))
// const Household = asyncComponent(() => import( '../../pages/householdNew/index'))
// const Maintainance = asyncComponent(() => import( '../../pages/householdNew/janitor'))


const HouseholdManagePrintPreview = asyncComponent(() => import( '../../pages/household/householdAdmin/printPreview'))

const UserProfile = asyncComponent(() => import( '../../pages/userProfile'))

const RolesManagement = asyncComponent(() => import( '../../pages/rolesManagement/index'))

const Minibar = asyncComponent(() => import( '../../pages/roomInfo/minibar/'))

const FiscalPrinterTools = asyncComponent(() => import( '../../pages/posTerminal/sideMenu/fiscalPrinterTools/index'))


const DepositRegister = asyncComponent(() => import( '../../pages/depositRegister/index'))


const DatabaseManagement = asyncComponent(() => import( "../../pages/administrative/database"))

//GROUP RESERVATIONS

const  CondoOwnerDashboard = asyncComponent(() => import( '../../pages/reservations2/condoOwnerView/dashboard'))

const HouseholdPlanner = asyncComponent(() => import("../../pages/household/planner/householdPlanner"))

const GroupReservations2 = asyncComponent(() => import("@pages/groupInfo/groupReservations2/groupReservationsList"));
const GroupInvoices = asyncComponent(() => import("@pages/groupInfo/groupInvoices/groupInvoicesList"));
const GroupInfoList = asyncComponent(() => import("@pages/groupInfo/selectGroupList"));

const Companies = asyncComponent(() => import("@pages/company"));
// const CompanyForm = asyncComponent(() => import("@pages/company/form"));

const Customers = asyncComponent(() => import("@pages/customers"));
// const CustomerForm = asyncComponent(() => import("@pages/customers/form"));

const AlarmAndRoomModeInterpreter = asyncComponent(() => import("@pages/generalSettings/forms/alarmAndRoomModeInterpreter"));
const RoomCardCodeManagement = asyncComponent(() => import("@pages/SRCSettings/roomCardCodeManagement"));
const ChannelManager = asyncComponent(() => import("@pages/channelManager"));

//GOODS MANAGEMENT

const SupplierPriceListsForm = asyncComponent(() => import("@pages/goodsManagement/supplierPriceLists/form"))
const SupplierPriceLists = asyncComponent(() => import("@pages/goodsManagement/supplierPriceLists"))
const Documents = asyncComponent(() => import("@pages/goodsManagement/documents"))
const DocumentForm = asyncComponent(() => import("@pages/goodsManagement/documents/form"))
const SkuItems = asyncComponent(() => import("@pages/goodsManagement/skuItems"))
const Warehouses = asyncComponent(()=> import("@pages/goodsManagement/warehouses"))
const WarehouseStock = asyncComponent(()=>import("@pages/goodsManagement/warehouses/warehouseStock"))
const WarehouseRequisition = asyncComponent(()=>import("@pages/goodsManagement/warehouses/warehouseRequisition"))
const EntryCalculation = asyncComponent(()=> import("@pages/goodsManagement/entryCalculation"))
const EntryCalcFrom = asyncComponent(() => import("@pages/goodsManagement/entryCalculation/form"))
const AutoPrecomfortMode = asyncComponent(() => import('../../pages/roomInfo/autoPrecomfortMode'));

//HELP AND SUPPORT
const HelpAndSupport = asyncComponent(()=> import("../../pages/helpAndInformations/helpAndSupport"))
const InternalDocumentation = asyncComponent(()=> import("../../pages/helpAndInformations/internalDocumentation"))


const returnPageTitleBasedOnLink = (link:string) => {
    const foundElement = componentPageTitle.find((element) => element.path === link);
    return {title:foundElement?.pageTitle, link:foundElement?.path}
}

const getBreadcrumbObjectForComponentsLink = (link: string) => {
    const foundElement = componentPageTitle.find((element) => element.path === link);
    let konj: any[] = []


    foundElement?.breadcrumbData.forEach((path: string) => {
        konj.push(returnPageTitleBasedOnLink(path))
    })
    return konj
}

const componentPageTitle = [
    { path: "/", pageTitle: "Dashboard", component: Dashboard, breadcrumbData: [] },

    { path: "/userProfile", pageTitle: "Your profile", component: UserProfile, breadcrumbData: ["/userProfile"] },
    
    { path: "/priceLists/tax", pageTitle: "Taxes", component: Tax, breadcrumbData: ["/priceLists/tax"] },
    { path: "/priceLists/accommodation", pageTitle: "Accommodation price list", component: AccommodationPriceList, breadcrumbData: ["/priceLists/accommodation"] },
    { path: "/priceLists/accommodation/stack/:accommodationPriceListId", pageTitle: "Accommodation price list stack", component: AccommodationPriceListStack, breadcrumbData: ["/priceLists/accommodation", "/priceLists/accommodation/stack/:accommodationPriceListId"] },
    { path: "/priceLists/currencies", pageTitle: "Currencies", component: Currencies, breadcrumbData: ["/priceLists/currencies"] },
    { path: "/priceLists/currencies/exchangeRates", pageTitle: "Exchange rates", component: ExchangeRate, breadcrumbData: ["/priceLists/currencies","/priceLists/currencies/exchangeRates" ] },
    { path: "/priceLists/priceListLookup", pageTitle: "Pricelist lookup", component: PricelistLookup, breadcrumbData: ["/priceLists/priceListLookup"] },
    { path: "/priceLists/posItem", pageTitle: "POS goods", component: PosItem, breadcrumbData: ["/priceLists/posItem"] },
    { path: "/priceLists/depositRegister", pageTitle: "Deposit register", component: DepositRegister, breadcrumbData: [] },
    
    { path: "/tablesLayout", pageTitle: "POS Tables layout", component: TablesLayout, breadcrumbData: ["/tablesLayout"] },
    { path: "/tableArrangement/:tablesLayoutId", pageTitle: "Table arrangement", component: TableArrangement, breadcrumbData: ["/tablesLayout","/tableArrangement/:tablesLayoutId"] },
    { path: "/guestsBook", pageTitle: "Guests book", component: GuestsBook, breadcrumbData: ["/guestsBook"] },
    { path: "/roomsAdministration/minibar/:roomInfoId", pageTitle: "Minibar", component: Minibar, breadcrumbData: ["/roomsAdministration","/roomsAdministration/minibar/:roomInfoId"] },
    
    { path: "/household", pageTitle: "Household", component: Household, breadcrumbData: ["/household"] },
    { path: "/household/minibar/:roomInfoId", pageTitle: "Minibar", component: CheckRoomMinibar, breadcrumbData: ["/household", "/household/minibar/:roomInfoId"] },
    { path: "/household/manage/printPreview/:cleaningStatusHeaderId", pageTitle:"Household management", component: HouseholdManagePrintPreview, breadcrumbData: [] },
    { path: "/household/manage/planner", pageTitle: "Household management", component: HouseholdPlanner, breadcrumbData: ["/household", "/household/manage/planner"] },
    
    { path: "/bms", pageTitle: "BMS", component: BMS, breadcrumbData: ["/bms"] },
    { path: "/sosGroups", pageTitle: "SOS groups", component: SosGroups, breadcrumbData: ["/sosGroups"] },
    { path: "/roomInfo/:roomInfoId/:remoteDeviceId", pageTitle: "Room informations", component: RoomInfo, breadcrumbData: [] },
    { path: "/roomInfo/:roomInfoId", pageTitle: "Room informations", component: RoomInfo, breadcrumbData: [] },
    { path: "/reservations", pageTitle: "Reservation administration", component: ReservationsAdmin, breadcrumbData: ["/reservations"] },
    
    { path: "/checkoutAndInvoice/:reservationId", pageTitle: "Checkout", component: CheckoutAndInvoice, breadcrumbData: [] },
    { path: "/issueGroupInvoice/:groupUuid", pageTitle: "Checkout", component: CheckoutAndInvoice, breadcrumbData: [] },
    { path: "/issueTerminalInvoice/:invoiceRefUuid", pageTitle: "Issue invoice", component: CheckoutAndInvoice, breadcrumbData: [] },
    { path: "/invoiceView/:invoiceId", pageTitle: "Invoices", component: InvoiceView, breadcrumbData: [] },
    
    { path: "/allRoomEvents", pageTitle: "Situation center", component: AllRoomEvents, breadcrumbData: ["/allRoomEvents"] },
    { path: "/roomEventHistory/:roomInfoId", pageTitle: "Room events history", component: RoomEventHistory, breadcrumbData: ["/?rooms", "/roomEventHistory/:roomInfoId"] },
    
    { path: "/srcTemplates", pageTitle: "SRC templates", component: SrcTemplate, breadcrumbData: ["/srcTemplates"] },
    { path: "/srcTemplate/new", pageTitle: "SRC templates", component: SrcTemplateForm, breadcrumbData: ["/srcTemplates","/srcTemplate/new"] },
    { path: "/srcTemplate/:srcTemplateId/edit", pageTitle: "SRC templates", component: SrcTemplateForm, breadcrumbData: ["/srcTemplates","/srcTemplate/:srcTemplateId/edit"] },
    { path: "/SRCBroadcastMulticast", pageTitle: "SRC settings broadcast & multicast", component: SRCBroadcastMulticast, breadcrumbData: ["/srcProxies","/SRCBroadcastMulticast"] },
    { path: "/srcProxies", pageTitle: "SRC Proxy configuration", component: SRCProxy, breadcrumbData: ["/srcProxies"] },
    { path: "/srcProxies/:srcProxyId", pageTitle: "SRC Proxy configuration", component: SRCProxy, breadcrumbData: [] },
    { path: "/srcRoomBinder/:srcProxyId", pageTitle: "SRC Proxy configuration", component: SRCRoomBinder, breadcrumbData: ["/srcProxies", "/srcRoomBinder/:srcProxyId"] },
    { path: "/srcProxy/new", pageTitle: "SRC Proxy configuration", component: SRCProxyForm, breadcrumbData: ["/srcProxies","/srcProxy/new"] },
    { path: "/srcProxy/:srcProxyId/edit", pageTitle: "SRC Proxy configuration", component: SRCProxyForm, breadcrumbData: ["/srcProxies","/srcProxy/:srcProxyId/edit"] },
    { path: "/srcProxy/:srcProxyId/administration", pageTitle: "SRC Proxy management", component: SRCProxyAdministration, breadcrumbData: ["/srcProxies",  "/srcProxy/:srcProxyId/administration"] },
    { path: "/srcSettings/:roomInfoId/:remoteDeviceId", pageTitle: "SRC Proxy configuration", component: SRCSettings, breadcrumbData: ["/roomsAdministration", "/srcSettings/:roomInfoId/:remoteDeviceId"] },
    { path: "/notificationTemplateSettings", pageTitle: "Notification Service Settings", component: NotificationTemplateSettings, breadcrumbData: ["/notificationTemplateSettings"] },
    { path: "/notificationTemplates", pageTitle: "Notification Templates", component: NotificationTemplates, breadcrumbData: ["/notificationTemplateSettings", "/notificationTemplates"] },
    { path: "/qrCodeGenerator", pageTitle: "QR Generator", component: QrCodeGenerator, breadcrumbData: ["/roomsAdministration","/qrCodeGenerator"] },
    { path: "/accessControlQRGenerator", pageTitle: "Acces control QR Generator", component: AccessControlQRGenerator, breadcrumbData: ["/accessControl","/accessControlQRGenerator"] },
    { path: "/doorRelayControl", pageTitle: "Door relay control", component: DoorRelayControl, breadcrumbData: ["/accessControl", "/doorRelayControl"] },
    
    { path: "/newRoomInfo", pageTitle: "Rooms management", component: RoomInfoForm, breadcrumbData: ["/roomsAdministration","/newRoomInfo"] },
    { path: "/roomAttributes", pageTitle: "Room attributes", component: RoomAttributes, breadcrumbData: ["/roomsAdministration","/roomAttributes"] },
    { path: "/roomsAdministration", pageTitle: "Rooms management", component: RoomsAdministration, breadcrumbData: ["/roomsAdministration"] },
    { path: "/roomsAdministration/:roomInfoId", pageTitle: "Rooms management", component: RoomInfoForm, breadcrumbData: ["/roomsAdministration" ,"/roomsAdministration/:roomInfoId"] },
    { path: "/apiKeys", pageTitle: "API keys management", component: ApiKeys, breadcrumbData: ["/users", "/apiKeys"] },
    { path: "/users", pageTitle: "User management", component: Users, breadcrumbData: ["/users"] },
    { path: "/customers", pageTitle: "Customers", component: Customers, breadcrumbData: ["/customers"] },
    { path: "/companies", pageTitle: "Companies", component: Companies, breadcrumbData: ["/companies"] },
    
    { path: "/generalSettings", pageTitle: "General settings", component: GeneralSettings, breadcrumbData: ["/generalSettings"] },
    { path: "/licence", pageTitle: "Licence informations", component: Licence, breadcrumbData: ["/licence"] },
    { path: "/requestLicence", pageTitle: "REQUEST LICENCE", component: RequestLicence, breadcrumbData: [] },
    { path: "/accessControl", pageTitle: "Access Control", component: AccessControl, breadcrumbData: ["/accessControl"] },
    { path: "/whiteLists", pageTitle: "White list access", component: Whitelist, breadcrumbData: ["/whiteLists"] },
    { path: "/whiteListItems/:whiteListId", pageTitle: "White list access", component: WhiteListItems, breadcrumbData: ["/whiteLists","/whiteListItems/:whiteListId"] },
    
    { path: "/test", pageTitle: "TEST", component: Test, breadcrumbData: [] },
    { path: "/menu/reports", pageTitle: "Reports menu", component: ReportsMenu, breadcrumbData: [] },
    { path: "/groupReservations", pageTitle: "Group Reservations", component: GroupReservations2, breadcrumbData: ["/groupReservations"] },
    { path: "/groupReservations/:reservationUuid", pageTitle: "Group Reservations", component: GroupReservations2, breadcrumbData: [] },
    { path: "/visaInfo", pageTitle: "Visa Info", component: VisaInfo, breadcrumbData: [] },
    { path: "/search", pageTitle: "Search...", component: Search, breadcrumbData: [] },
    { path: "/roles", pageTitle: "Roles management", component: RolesManagement, breadcrumbData: ["/users", "/roles"] },
    { path: "/database", pageTitle: "Database management", component: DatabaseManagement, breadcrumbData: [] },
    { path: "/posTerminal", pageTitle: "POS Terminals", component: PosTerminalAdmin, breadcrumbData: ["/posTerminal"] },
    { path: "/posTerminal/:posTerminalId", pageTitle: "", component: PosTerminalView, breadcrumbData: ["/posTerminal/:posTerminalId"] },
    { path: "/posTerminal/invoices/:posTerminalId", pageTitle: "Invoices", component: PosTerminalInvoices, breadcrumbData: ["/posTerminal/invoices/:posTerminalId"] },
    { path: "/posTerminal/stateAndInvoiceConclusion/:posTerminalId", pageTitle: "Closing revenue balance", component: StateAndInvoiceConclusion, breadcrumbData: ["/posTerminal/stateAndInvoiceConclusion/:posTerminalId"] },
    { path: "/posTerminal/fiscalTools/:posTerminalId", pageTitle: "Other fiscal tools", component: FiscalPrinterTools, breadcrumbData: ["/posTerminal/:posTerminalId", "/posTerminal/fiscalTools/:posTerminalId"] },
    { path: "/attachItemsToPosTerminal/:posTerminalId", component: ItemsList, breadcrumbData: ["/attachItemsToPosTerminal/:posTerminalId"] },
    { path: "/posDisplayConfig", pageTitle: "POS display config", component: PosDisplayConfig, breadcrumbData: ["/posDisplayConfig"] },
    
    { path: "/docScannerDevices", pageTitle: "DOCUMENT SCANNER DEVICES", component: DocScannerDevices, breadcrumbData: ["/docScannerDevices"] },
    { path: "/fflags", pageTitle: "Feature flags", component: FeatureFlagsList, breadcrumbData: ["/fflags"] },
    { path: "/condoView", pageTitle: "My apartments", component: CondoOwnerDashboard, breadcrumbData: [] },
    { path: "/roomsAndPriceLists", pageTitle: "Rooms and pricelists", component: RoomsAndPriceLists, breadcrumbData: ["/roomsAdministration","/roomsAndPriceLists"] },
    { path: "/firstTimeSetup", pageTitle: "Setup", component: FirstTimeSetup, breadcrumbData: [] },
    { path: "/documentScanner", pageTitle: "Document scanner", component: DocumentScanner, breadcrumbData: [] },
    
    { path: "/billing", pageTitle: "Billing", component: Billing, breadcrumbData: [] },
    { path: "/billingInvoices", pageTitle: "Billing invoices", component: BillingInvoicePage, breadcrumbData: [] },
    { path: "/billingSummaryInvoiceView/:invoiceId", pageTitle: "Invoices", component: BillingSummaryInvoiceView, breadcrumbData: [] },
    { path: "/autoPrecomfortMode", pageTitle: "Auto precomfort mode", component: AutoPrecomfortMode, breadcrumbData: ["/roomsAdministration","/autoPrecomfortMode"] },
    { path: "/selfCheckInAdministrative", pageTitle: "Self check in administration", component: SelfCheckInAdministrative, breadcrumbData: ["/selfCheckInAdministrative"] },
    { path: "/channelManagerMapping/:providerName", pageTitle: "Channel Manager", component: ChannelManager, breadcrumbData: ["/channelManagerMapping/:providerName"] },
    { path: "/alarmAndRoomModeInterpreter", pageTitle: "Alarm and room mode interpreter", component: AlarmAndRoomModeInterpreter, breadcrumbData: ["/alarmAndRoomModeInterpreter"] },
    { path: "/cashRegistars", pageTitle: "Cash Registars", component: CashRegistars, breadcrumbData: ["/cashRegistars"] },
    { path: "/cashRegistarPage/:cashRegistarId", pageTitle: "Cash Registar", component: CashRegistarPage, breadcrumbData: ["/cashRegistars","/cashRegistarPage/:cashRegistarId" ] },
    { path: "/uncollectedBills", pageTitle: "Uncollected Bills", component: UncollectedBills, breadcrumbData: ["/cashRegistars", "/uncollectedBills"] },
    { path: "/cashRegReportView/:reportId", pageTitle: "Cash registar report", component: CashRegPaymentPrintView, breadcrumbData: [] },
    { path: "/groupInvoices", pageTitle: "Group invoices", component: GroupInvoices, breadcrumbData: ["/groupInvoices"] },
    { path: "/groupInfos", pageTitle: "Groups", component: GroupInfoList, breadcrumbData: [] },
    { path: "/cardCodeManagement", pageTitle: "Card Code Management", component: RoomCardCodeManagement, breadcrumbData: ["/cardCodeManagement"] },
    
    { path: "/goodsManagement/supplierPriceLists", pageTitle: "Active supplier price lists", component: SupplierPriceLists, breadcrumbData: ["/goodsManagement/supplierPriceLists"] },
    { path: "/goodsManagement/supplierPriceLists/new", pageTitle: "Supplier price lists", component: SupplierPriceListsForm, breadcrumbData: ["/goodsManagement/supplierPriceLists" ,"/goodsManagement/supplierPriceLists/new"] },
    { path: "/goodsManagement/supplierPriceLists/:headerId", pageTitle: "Supplier price lists", component: SupplierPriceListsForm, breadcrumbData: ["/goodsManagement/supplierPriceLists","/goodsManagement/supplierPriceLists/:headerId"] },
    { path: "/goodsManagement/documents", pageTitle: "Documents", component: Documents, breadcrumbData: ["/goodsManagement/documents"] },
    { path: "/goodsManagement/documents/new", pageTitle: "New document", component: DocumentForm, breadcrumbData: ["/goodsManagement/documents", "/goodsManagement/documents/new"] },
    { path: "/goodsManagement/documents/edit/:headerId", pageTitle: "Edit document", component: DocumentForm, breadcrumbData: ["/goodsManagement/documents", "/goodsManagement/documents/edit/:headerId"] },
    { path: "/goodsManagement/skuItems", pageTitle: "SKU Items", component: SkuItems, breadcrumbData: ["/goodsManagement/skuItems"] },
    { path: "/goodsManagement/warehouses", pageTitle: "Warehouses", component: Warehouses, breadcrumbData: ["/goodsManagement/warehouses"] },
    { path: "/goodsManagement/warehouse/:warehouseId", pageTitle: "Warehouse stock", component: WarehouseStock, breadcrumbData: ["/goodsManagement/warehouses", "/goodsManagement/warehouse/:warehouseId"] },
    { path: "/goodsManagement/warehouseRequisition/:warehouseId", pageTitle: "Warehouse requisition", component: WarehouseRequisition, breadcrumbData: ["/goodsManagement/warehouses", "/goodsManagement/warehouseRequisition/:warehouseId"] },
    { path: "/goodsManagement/entryCalculation", pageTitle: "Retail price calculation", component: EntryCalculation, breadcrumbData: ["/goodsManagement/entryCalculation"] },
    { path: "/goodsManagement/entryCalc/new", pageTitle: "New retail price calculation", component: EntryCalcFrom, breadcrumbData: ["/goodsManagement/entryCalculation","/goodsManagement/entryCalc/new"] },
    { path: "/goodsManagement/entryCalc/edit/:entryCalcId", pageTitle: "Edit retail price calculation", component: EntryCalcFrom, breadcrumbData: ["/goodsManagement/entryCalculation", "/goodsManagement/entryCalc/edit/:entryCalcId"] },
    
    { path: "/financialJournal", pageTitle: "Financial Journal", component: FinancialJournal, breadcrumbData: ["/financialJournal"] },

    { path: "/report/reservations", pageTitle: "Reservations", component: ReservationReportMenu, breadcrumbData: ["/report/reservations"] },
    { path: "/report/invoices", pageTitle: "Invoices", component: InvoicesReportMenu, breadcrumbData: ["/report/invoices"] },
    { path: "/report/roomEvents", pageTitle: "Room Events", component: RoomEventsReportMenu, breadcrumbData: ["/report/roomEvents"] },
    { path: "/report/accessControl", pageTitle: "Access Control", component: AccessControlReportMenu, breadcrumbData: ["/report/accessControl"] },
    { path: "/report/retailManagement", pageTitle: "Retail Management", component: RetailManagementReportMenu, breadcrumbData: ["/report/retailManagement"] },
    { path: "/report/invoices/financialReport", pageTitle: "Financial report", component: FinancialReport, breadcrumbData: [] },
    { path: "/report/guestsBookReservations/:headerId", pageTitle: "Reservations by guests book report", component: GuestsBookReservations, breadcrumbData: [] },
    { path: "/report/guestsBookForEditing/:headerId", pageTitle: "Guests book by report for editing", component: GuestsBookForEditing, breadcrumbData: [] },
    { path: "/report/accessControl/:doorControlPointId", pageTitle: "Door access report", component: AccessControlDetails, breadcrumbData: [] },
    { path: "/help", pageTitle: "Help and support", component: HelpAndSupport, breadcrumbData: ["/Help and support"] },
    { path: "/documentation", pageTitle: "Documentation", component: InternalDocumentation, breadcrumbData: ["/Documentation"] },


]



export default componentPageTitle

export {getBreadcrumbObjectForComponentsLink}