import React from 'react';
import moment from 'moment/min/moment-with-locales';
import Helmet from 'react-helmet';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { branch } from 'baobab-react/higher-order';
import { formatDate, parseDate } from 'react-day-picker/moment';

class Example extends React.Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.state = {
            from: this.props.from ? this.props.from : null, //new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()),
            to: this.props.to ? this.props.to : null, //new Date(),
        };
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    handleFromChange(from) {
        // Change the from date and focus the "to" input field
        const _from = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0);
        this.setState({ _from });
        if (this.props.onChange) {
            this.props.onChange('from', _from);
        }
    }

    handleToChange(to) {
        const _to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59);
        this.setState({ to: _to }, this.showFromMonth);
        if (this.props.onChange) {
            this.props.onChange('to', _to);
        }
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };

        return (
            <div className="InputFromTo">
                <DayPickerInput
                    value={from}
                    placeholder="From"
                    format={this.props.otherSettings?.dateFormat ? this.props.otherSettings.dateFormat : 'DD/MM/YYYY'}
                    inputProps={{ className: 'date-picker-input-custom' }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to, before: this.props.updateAvail ? new Date() : null },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 1,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                />{' '}
                —{' '}
                <span className="InputFromTo-to">
                    <DayPickerInput
                        ref={(el) => (this.to = el)}
                        value={to}
                        placeholder="To"
                        format={this.props.otherSettings?.dateFormat ? this.props.otherSettings.dateFormat : 'DD/MM/YYYY'}
                        inputProps={{ className: 'date-picker-input-custom' }}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: from,
                            fromMonth: from,
                            numberOfMonths: 1,
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
                <Helmet></Helmet>
            </div>
        );
    }
}

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    Example
);
