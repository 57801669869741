import React from 'react';
import baobabTree from '../data/state';
import translate from '@data/translations';

const PermissionCheck = (props) => {
    const { name = 'permissionName', noPermision } = props;
    const permissions = baobabTree.root.select(['state', 'userPermissions']).get()
    if(permissions?.features?.includes(name)){
         return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return noPermision ? <div className='font-18p ml-24p mt-16p'>{translate("No access.")}</div> : null;
    }
};

export default PermissionCheck;