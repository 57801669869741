import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../reservations2/resController';
import { setModalContent } from '@components/modal';
import ShowAvailableRoomsForReservationModal from '../reservations2/ChangeRoomModal';
import { RoomInfoModel, AccommodationPricelistModel } from '@common/modelDefinition';
import { condoStatusCheck } from '../reservations2/utils';
import { reservationEnums } from '@data/reservationStatusEnums';
import { ReactComponent as Clock } from '../../assets/custom/icons/clock.svg';
import { ReactComponent as ValidImg } from '../../assets/custom/icons/tick_inside_circle.svg';
import { ReactComponent as Warning } from '../../assets/custom/icons/warning.svg';
import { ReactComponent as Plus } from '../../assets/custom/icons/plus.svg';
import TabsBody from './tabsBody';

export const ActiveIcon = () => <Clock />;
export const ValidIcon = () => <ValidImg />;
export const InvalidIcon = () => <Warning />;
const ctrl = new ReservationController();

interface TabsWrapperProps {
    history: any;
    activeReservationIndex: number;
    roomInfoMap: any;
    acp: AccommodationPricelistModel[];
    showReservationDetails?: Function;
}

function TabsWrapper(props: TabsWrapperProps) {
    const { roomInfoMap, history, activeReservationIndex, acp, showReservationDetails } = props;

    const tabIndex: number = activeReservationIndex;
    const reservations = ctrl.getReservations();
    const groupReservation = ctrl.getGroupReservation();
    const [loading, setLoading] = React.useState(false);

    function validateReservation(r: Reservation, index: number) {
        const { isFullyValid } = ctrl.getReservationValidationReport(r);
        if (!isFullyValid) {
            return <InvalidIcon />;
        } else if (index === tabIndex) {
            return <ActiveIcon />;
        } else {
            return <ValidIcon />;
        }
    }

    const addRoom = () => {
        setModalContent(
            <ShowAvailableRoomsForReservationModal
                reservation={{ ...reservations[0] }}
                changeRoomHandler={(room: RoomInfoModel) => ctrl.createNewRes(room, acp)}
            />,
            translate('Add room to reservation'),
            false,
            'modal-lg'
        );
    };

    const { isCondoReservation } = condoStatusCheck(reservations[tabIndex]);
    const isAccommodationInvoiceIssued: boolean = reservations[tabIndex]?.genuineInvoiceIssued === 1 ? true : false;
    
    return (
        <div className="reservation-form-main-wrapper">
            <div className="reservation-tabs-wrapper">
                {reservations.map((r, index) => {
                    const { isFullyValid } = ctrl.getReservationValidationReport(r);
                    const room = r.roomInfoId ? roomInfoMap[r.roomInfoId] : null;
                    if (!room) {
                        return (
                            <div
                                onClick={async () => {
                                    setLoading(true);
                                    await ctrl.setActiveReservationsModels(index);
                                    ctrl.setActiveReservationIndex(index);
                                    setLoading(false);
                                }}
                                key={index}
                                className="reservation-tabs-single"
                                style={{ order: index }}
                            >
                                <div
                                    className={
                                        reservations.length > tabIndex
                                            ? index === tabIndex
                                                ? 'active'
                                                : isFullyValid
                                                ? ' valid-res-form '
                                                : ' invalid-res-form '
                                            : index === tabIndex - 1
                                            ? 'active'
                                            : isFullyValid
                                            ? ' valid-res-form '
                                            : ' invalid-res-form '
                                    }
                                >
                                    <InvalidIcon />
                                </div>
                            </div>
                        );
                    }

                    const removedFromGroup = groupReservation && groupReservation.uuid !== r.groupUuid;
                    return (
                        <div
                            onClick={async () => {
                                setLoading(true);
                                await ctrl.setActiveReservationsModels(index);
                                setLoading(false);
                                ctrl.setActiveReservationIndex(index);
                            }}
                            key={index}
                            className="reservation-tabs-single"
                        >
                            <div
                                className={
                                    reservations.length > tabIndex
                                        ? index === tabIndex
                                            ? ' active '
                                            : isFullyValid
                                            ? ' valid-res-form '
                                            : ' invalid-res-form '
                                        : index === tabIndex - 1
                                        ? ' active '
                                        : isFullyValid
                                        ? ' valid-res-form '
                                        : ' invalid-res-form '
                                }
                            >
                                {validateReservation(r, index)}
                                {room.name} ({r.adultsNumber},{r.childrenNumber})
                                {removedFromGroup ? (
                                    <i
                                        className="fa fa-toggle-up text-danger ml-2"
                                        title={translate('Removed from group')}
                                    />
                                ) : null}
                                {r.statusEnum === reservationEnums.canceled ? (
                                    <i className="fa fa-toggle-up text-warning ml-2" title={translate('Canceled')} />
                                ) : null}
                            </div>
                        </div>
                    );
                })}

                {!isCondoReservation ? (
                    <div
                        style={isAccommodationInvoiceIssued ? { pointerEvents: 'none' } : {}}
                        onClick={() => {
                            if (!isAccommodationInvoiceIssued) {
                                addRoom();
                            } else {
                                return null;
                            }
                        }}
                        className="reservation-tabs-single fill-primary"
                    >
                        <Plus />
                    </div>
                ) : null}
            </div>

            <TabsBody history={history} loading={loading} setLoading={setLoading} showReservationDetails={showReservationDetails} />
        </div>
    );
}

export default branch(
    {
        roomInfoMap: ['map', 'RoomInfo'],
        activeReservationIndex: ['state', 'reservationForm2', 'activeReservationIndex'],
        acp: ['model', 'AccommodationPriceList'],
    },
    TabsWrapper
);
