import * as React from 'react';
import { ReservationController } from '../reservations2/resController';
import { translate } from '@data/translations';
import { NotificationTemplateModel, SelfServiceCheckInModel, ReservationStatus } from '@common/modelDefinition';
import FeatureFlag from '@components/FeatureFlag';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
//@ts-ignore
import TimePicker from 'rc-time-picker';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { getNotificationTemplate } from '../reservations2/api';
import SelfCheckInLogs from './selfCheckInLogs';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

interface SelfCheckInFormProps {
    handleTimeChange: any;
    handleSelfCheckInValidDateChange: any;
    selfServiceCheckIn: SelfServiceCheckInModel;
    selfScheckInLanguageOptions: any;
    otherSettings: any;
}

function SelfCheckIn(props: any) {
    const ctrl = new ReservationController();
    const reservation = ctrl.getActiveReservation();
    const customer = reservation?.Customer;
    const selfServiceCheckIn = reservation?.SelfServiceCheckIn;

    const [selfScheckInLanguageOptions, setSelfScheckInLanguageOptions] = React.useState({} as any);
    const customerEmail = customer?.email ? customer.email : undefined;

    React.useEffect(() => {
        const getNotificationTemplatesAndSetLanguageOptions = async () => {
            const notificationTemplates: NotificationTemplateModel[] = await getNotificationTemplate();
            let languageOptionsByKey: any = {};
            notificationTemplates.forEach((templ) => {
                if (templ.params) {
                    let key = templ.uniqueName;
                    if (templ.uniqueName.toLowerCase().includes('hardware')) {
                        key = 'hardware';
                    }
                    if (templ.uniqueName.toLowerCase().includes('software')) {
                        key = 'software';
                    }
                    languageOptionsByKey[key] = Object.keys(JSON.parse(templ.params));
                }
            });
            setSelfScheckInLanguageOptions(languageOptionsByKey);
        };

        getNotificationTemplatesAndSetLanguageOptions().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reservation?.id]);

    const onSelfCheckInTypeChange = (checkInType: string) => {
        if (customerEmail) {
            const s: SelfServiceCheckInModel = { ...selfServiceCheckIn };
            s['selfCheckInType'] = checkInType;
            ctrl.setSelfServiceCheckIn(s, null);
        } else {
            console.warn('Customer email is missing!!!');
        }
    };

    const onEnableCheckInChange = () => {
        const s: SelfServiceCheckInModel = { ...selfServiceCheckIn };
        ctrl.setSelfServiceCheckIn(s, !reservation.enableSelfCheckIn);
    };

    const handleSelfCheckInValidDateChange = (type: string, selDate: any) => {
        const s: any = { ...selfServiceCheckIn };
        const currDate = new Date(s[type]);
        const currValue = selDate;
        s[type] = new Date(currValue.setHours(currDate.getHours(), currDate.getMinutes(), 0, 0)).getTime();
        ctrl.setSelfServiceCheckIn(s, null);
    };

    const handleTimeChange = (type: string, e: any) => {
        switch (type) {
            case 'sendSelfCheckInCodeAfterTimestamp':
            case 'selfCheckInValidTo':
            case 'selfCheckInValidFrom':
                const s = { ...selfServiceCheckIn };
                const currDate = new Date(selfServiceCheckIn[type]);
                const currValue = new Date(e.valueOf());
                s[type] = new Date(currDate.setHours(currValue.getHours(), currValue.getMinutes(), 0, 0)).getTime();
                ctrl.setSelfServiceCheckIn(s, null);

                break;
            default:
                break;
        }
    };

    if (reservation && reservation.enableSelfCheckIn && customerEmail === undefined) {
        return (
            <FeatureFlag flag="selfCheckInOptions">
                <div className="mt-8p mb-8p">
                    {translate('Reservation holder email is required for Self Check In feature!')}
                </div>
            </FeatureFlag>
        );
    }

    if (!reservation || !reservation.id || reservation.id === null || !selfServiceCheckIn) {
        return null;
    }

    return (
        <FeatureFlag flag="selfCheckInOptions">
            <div className=" mt-2 mb-2">
                {reservation.statusEnum === ReservationStatus.confirmed ? (
                    <div>
                        <label>{translate('Enable self check-in')}:&ensp;</label>
                        <i
                            onClick={() => onEnableCheckInChange()}
                            className={
                                reservation.enableSelfCheckIn
                                    ? 'fa fa-check-square-o  pointer'
                                    : 'fa fa-square-o  pointer'
                            }
                        ></i>
                    </div>
                ) : null}

                {reservation.statusEnum === ReservationStatus.confirmed && reservation.enableSelfCheckIn ? (
                    <div>
                        <label>{translate('Self check-in type')}:&ensp;</label>
                        <label>{'Hardware'}:&ensp;</label>
                        {selfServiceCheckIn?.selfCheckInType === 'hardware' ? (
                            <i
                                onClick={() => onSelfCheckInTypeChange('hardware')}
                                className="fa fa-check-square-o  pointer mr-3"
                            ></i>
                        ) : (
                            <i
                                onClick={() => onSelfCheckInTypeChange('hardware')}
                                className="fa fa-square-o  pointer mr-3"
                            ></i>
                        )}
                        <label>{'Software'}:&ensp;</label>
                        {selfServiceCheckIn?.selfCheckInType === 'software' ? (
                            <i
                                onClick={() => onSelfCheckInTypeChange('software')}
                                className="fa fa-check-square-o  pointer mr-1"
                            ></i>
                        ) : (
                            <i
                                onClick={() => onSelfCheckInTypeChange('software')}
                                className="fa fa-square-o  pointer mr-1"
                            ></i>
                        )}
                        <small className="text-secondary">{customerEmail ? '' : '(no receiver email)'}</small>
                    </div>
                ) : null}

                {reservation.statusEnum === ReservationStatus.confirmed && reservation.enableSelfCheckIn ? (
                    <SelfCheckInForm
                        handleTimeChange={handleTimeChange}
                        handleSelfCheckInValidDateChange={handleSelfCheckInValidDateChange}
                        // handleLanguageChange={handleLanguageChange}
                        selfServiceCheckIn={selfServiceCheckIn}
                        selfScheckInLanguageOptions={selfScheckInLanguageOptions}
                        otherSettings={props.otherSettings}
                    />
                ) : reservation.statusEnum === ReservationStatus.confirmed ? null : (
                    <div>{translate('Reservation status must be confirmed')}</div>
                )}
                <br />
                <SelfCheckInLogs />
            </div>
        </FeatureFlag>
    );
}

const SelfCheckInForm = (props: SelfCheckInFormProps) => {
    return (
        <div>
            <div className="mt-8p">
                <label className="text-bold ml-4p mb-4p" htmlFor="">
                    {translate('Send self check in code after')}:&ensp;
                </label>
                <div className="display-flex">
                    <div className="mr-4p flex">
                        <DayPickerInput
                            inputProps={{ className: 'form-control form-control-sm', readOnly: true }}
                            value={new Date(props.selfServiceCheckIn?.sendSelfCheckInCodeAfterTimestamp)}
                            placeholder="From"
                            format={props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            onDayChange={props.handleSelfCheckInValidDateChange.bind(
                                this,
                                'sendSelfCheckInCodeAfterTimestamp'
                            )}
                        />
                    </div>
                    <div className="ml-4p flex">
                        <TimePicker
                            id="sendSelfCheckInCodeAfterTimestamp"
                            allowEmpty={false}
                            showSecond={false}
                            value={moment(props.selfServiceCheckIn?.sendSelfCheckInCodeAfterTimestamp)}
                            onChange={props.handleTimeChange.bind(this, 'sendSelfCheckInCodeAfterTimestamp')}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-12p">
                <label className="text-bold ml-4p mb-4p" htmlFor="">
                    {translate('Self check in valid from')}:&ensp;
                </label>
                <div className="display-flex">
                    <div className="mr-4p flex">
                        <DayPickerInput
                            inputProps={{ className: 'form-control form-control-sm', readOnly: true }}
                            value={new Date(props.selfServiceCheckIn?.selfCheckInValidFrom)}
                            placeholder="From"
                            format={props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            onDayChange={props.handleSelfCheckInValidDateChange.bind(this, 'selfCheckInValidFrom')}
                        />
                    </div>

                    <div className="ml-4p flex">
                        <TimePicker
                            id="selfCheckInValidFrom"
                            allowEmpty={false}
                            showSecond={false}
                            value={moment(props.selfServiceCheckIn?.selfCheckInValidFrom)}
                            onChange={props.handleTimeChange.bind(this, 'selfCheckInValidFrom')}
                        />
                    </div>
                </div>{' '}
            </div>
            <div className="mt-12p">
                <label className="text-bold ml-4p mb-4p" htmlFor="">
                    {translate('Self check in valid to')}:&ensp;
                </label>
                <div className="display-flex">
                    <div className="mr-4p flex">
                        <DayPickerInput
                            inputProps={{ className: 'form-control form-control-sm', readOnly: true }}
                            value={new Date(props.selfServiceCheckIn?.selfCheckInValidTo)}
                            placeholder="From"
                            format={props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            onDayChange={props.handleSelfCheckInValidDateChange.bind(this, 'selfCheckInValidTo')}
                        />
                    </div>

                    <div className="ml-4p flex">
                        <TimePicker
                            id="selfCheckInValidTo"
                            allowEmpty={false}
                            showSecond={false}
                            value={moment(props.selfServiceCheckIn?.selfCheckInValidTo)}
                            onChange={props.handleTimeChange.bind(this, 'selfCheckInValidTo')}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-12p">
                <label className="text-bold ml-4p mb-4p" htmlFor="">
                    {translate('Pin code')}:&ensp;
                </label>
                <div className="ml-4p text-bold" style={{ fontSize: '14px' }}>
                    {props.selfServiceCheckIn?.pinCode}
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    SelfCheckIn
);
