
import baobabTree from '../state';
import { rest } from '../restWrapper';

import {loadUserCondoData} from "../../pages/reservations2/condoOwnerView/condoApi"

const reloadReservations = async ()=>{

	const loggedUser = baobabTree.root.select(['authTokenInfo', 'user']).get();
	const permissions = baobabTree.root.select(['state', 'userPermissions']).get();

	if(loggedUser.role==="condoUser"){
		await loadUserCondoData();

	} else if(permissions?.readModels.includes("Reservation")){
		const incommingReservations = await rest('/getIncommingReservations');
    	const checkedInReservations = await rest('/getAllCheckedInReservations');
    	const Reservation = incommingReservations.concat(checkedInReservations);
    	baobabTree.root.select(['model', 'Reservation']).set(Reservation);
	}
}


export {reloadReservations}