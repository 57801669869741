import { toast } from 'react-toastify';

var SRCAlarmsAndAlertsBaobabTemplate = {
    //cache in baobab to remember the configuration!!!
    activeAlarmsAndEvents: [], //object received from the server
    // alarmAndEventGroups,
    toastDisplayConfig: {
        info: { //low
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 8000,
        },
        warning: { //medium
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 60000,
        },
        important: { //high
            type: toast.TYPE.ERROR,
            autoClose: false,
            position: toast.POSITION.BOTTOM_LEFT,
            closeButton: false,
        },
        danger: { //ultra
            type: toast.TYPE.ERROR,
            autoClose: false,
            position: toast.POSITION.BOTTOM_LEFT,
            closeButton: false,
            draggable: false, //prevent from close by draging
            closeOnClick: false,
            //onClose:()=>{}
        },
    },
};

//info | warning | important | danger

export default SRCAlarmsAndAlertsBaobabTemplate;
