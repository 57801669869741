import React from 'react';
import PageTitle from '../../components/pageTitle';
import { useBranch } from 'baobab-react/hooks';
import rest from '../../data/restWrapper';
import baobab from '../../data/state/index';

const FeatureFlagsList = (props) => {
    const { featureFlag, userInfo } = useBranch({
        featureFlag: ['model', 'FeatureFlag'],
        userInfo: ['authTokenInfo', 'user'],
    });

    if (userInfo?.role !== 'admin' && userInfo?.role !== 'superAdmin') {
        return 'Access denied';
    }

    return (
        <div>
            <div>
                <div className="title-with-options display-flex space-between">
                    <PageTitle />
                </div>
            </div>

            {featureFlag
                ? featureFlag.map((f, key) => {
                      const checkboxIcon = f.isEnabled ? (
                          <i className="fa fa-2x fa-check-square-o pull-right" />
                      ) : (
                          <i className="fa fa-2x fa-square-o pull-right " />
                      );

                      return (
                          <div
                              onClick={async () => {
                                  const fflag = { ...f };
                                  fflag.isEnabled = fflag.isEnabled ? false : true;
                                  try {
                                      await rest('/FeatureFlag', 'POST', fflag);
                                      let featureFlags = [];
                                      featureFlag.forEach((flag) => {
                                          if (f.name === flag.name) {
                                              let ff = { ...flag };
                                              ff.isEnabled = fflag.isEnabled;
                                              featureFlags.push(ff);
                                          } else {
                                              featureFlags.push(flag);
                                          }
                                      });
                                      baobab.root.select('model', 'FeatureFlag').set(featureFlags);
                                  } catch (error) {
                                      console.log('error', error);
                                  }
                              }}
                              className="custom-list-item"
                              key={key}
                          >
                              <div className="">{f.name}</div>
                              <div className="">{f.description}</div>
                              <div className="">{f.version}</div>
                              <div className="pointer">{checkboxIcon}</div>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};

export default FeatureFlagsList;
