import React  from "react";
import {ReactComponent as Home} from "../../assets/custom/icons/home.svg";
import {ReactComponent as PropertyManagement} from "../../assets/custom/icons/propertyManagement.svg";
// import {ReactComponent as Bms} from "../../assets/custom/icons/building.svg";
import {ReactComponent as Reports} from "../../assets/custom/icons/reports.svg";
import {ReactComponent as Pos} from "../../assets/custom/icons/pos.svg";
import {ReactComponent as RoomsAndHardware} from "../../assets/custom/icons/hardware.svg";
import {ReactComponent as Database} from "../../assets/custom/icons/database.svg";
import {ReactComponent as AdvancedSettings} from "../../assets/custom/icons/settings_cog.svg";
import {ReactComponent as Dollar} from "../../assets/custom/icons/price.svg";
// import {ReactComponent as BusinessConclusion} from "../../assets/custom/icons/tick_inside_circle.svg";
import {ReactComponent as Finance} from "../../assets/custom/icons/bill.svg";
import {ReactComponent as Logout} from "../../assets/custom/icons/sign_out.svg";
import {ReactComponent as Help} from "../../assets/custom/icons/search.svg";

import baobabTree from "../../data/state"
import _ from "lodash"
import { logout } from '../../data/actions';

function logMeOut () {
    logout()
}

function toggleMainViewButtons (mainViewType) {
    
    let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
    if (_.includes(temp, 'timeline')) {
        _.remove(temp, (t) => t === 'timeline');
    }
    if (_.includes(temp, 'tiles')) {
        _.remove(temp, (t) => t === 'tiles');
    }
    if (_.includes(temp, 'activity')) {
        _.remove(temp, (t) => t === 'activity');
    }
    if (_.includes(temp, 'journal')) {
        _.remove(temp, (t) => t === 'journal');
    }
    if (_.includes(temp, 'occupancy')) {
        _.remove(temp, (t) => t === 'occupancy');

    }
    // if (_.includes(temp, 'jourWithAct')) {
    //     _.remove(temp, (t) => t === 'jourWithAct');

    // }
// --   OVO OVDJE PROMIJENITI JOPA_-----
    if(mainViewType === "occupancy"){
        temp.push("sidebar_dailyInfo")
    }
    
    temp.push(mainViewType);
    baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    // setTimeout(() => {
    //     clearReservationsFilterFields();
    // }, 10);
}


export default {
    
    home: {
        id:"home",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Home',
        label: 'Home',
        icon: <Home/>,
        items:null,
        link:"/",
        whenClicked:()=>{toggleMainViewButtons("timeline")},
        description:"Hologic home page"
    },

    dashboard: {        
        id:"dashboard",
        permissionCheckName: "dashboard",
        featureFlags: [],
        fullLabel: 'Front desk',
        label: 'Rooms',
        icon: <PropertyManagement/>,
        items: [
            { itemLabel: 'Reservations', link: '/?reservations', permissionCheckName:"dashboard_reservations", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("timeline")} },
            { itemLabel: 'Group Reservations', link: '/groupReservations', permissionCheckName:"dashboard_groupReservations", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("timeline")} },
            { itemLabel: 'Journal', link: '/?journal', permissionCheckName:"dashboard_journal", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("journal")} },
            { itemLabel: 'Financial journal', link: '/financialJournal', permissionCheckName:"dashboard_financialJournal", featureFlags:null, whenClicked:()=>{} },
            { itemLabel: 'Room controllers', link:  '/?rooms', permissionCheckName:"dashboard_roomControllers", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("tiles")} },
            { itemLabel: 'Card code administration', link:  '/cardCodeManagement', permissionCheckName:"dashboard_cardCodeAdmin", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("tiles")} },
            { itemLabel: 'Household', link:  '/household', permissionCheckName:"household", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("household")} },
            { itemLabel: 'Situation center', link:  '/allRoomEvents', permissionCheckName:"situationCenter", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("situationCenter")} },
            { itemLabel: 'POS terminal', link: '/posTerminal/:posTerminalId', permissionCheckName:"dashboard_pos", featureFlags:null,  },
            { itemLabel: 'Closing revenue balance', link: '/posTerminal/stateAndInvoiceConclusion/:posTerminalId', permissionCheckName:"dashboard_inventory_revenueBalance", featureFlags:null,  },
            { itemLabel: 'Invoices', link: '/posTerminal/invoices/:posTerminalId', permissionCheckName:"dashboard_invoices", featureFlags:null,  },
            { itemLabel: 'Group invoices', link: '/groupInvoices', permissionCheckName:"dashboard_groupInvoices", featureFlags:null, whenClicked:()=>{} },
            { itemLabel: 'Guests book', link: '/guestsBook', permissionCheckName:"dashboard_reservationConclusion", featureFlags:["development" ]},
         ],
        // link:"/",
        description:"Everything for reception."
    },
    
    dataFolders: {
        id:"dataFolders",
        permissionCheckName: "foldersPanel",
        featureFlags: ["dataFolders"],
        fullLabel: 'Database',
        label: 'Data',
        icon: <Database/>,
        items: [
            { itemLabel: 'Users', link: '/users', permissionCheckName:"foldersPanel_users", featureFlags:null },
            { itemLabel: 'Customers', link: '/customers', permissionCheckName:"foldersPanel_customers", featureFlags:null },
            { itemLabel: 'Companies', link: '/companies', permissionCheckName:"foldersPanel_companies", featureFlags:null },
            { itemLabel: 'Reservations', link: '/reservations', permissionCheckName:"foldersPanel_reservations", featureFlags:null },
            { itemLabel: 'Rooms', link: '/roomsAdministration', permissionCheckName:"foldersPanel_rooms", featureFlags:null },
            { itemLabel: 'Taxes', link: '/priceLists/tax', permissionCheckName:"foldersPanel_taxes", featureFlags:null },
            { itemLabel: 'Accommodation', link: '/priceLists/accommodation', permissionCheckName:"foldersPanel_accomodation", featureFlags:null },
            { itemLabel: 'Currencies', link: '/priceLists/currencies', permissionCheckName:"foldersPanel_currencies", featureFlags:null },
            { itemLabel: 'POS goods', link: '/priceLists/posItem', permissionCheckName:"posConfigurationPanel_posGoods", featureFlags:["minibar"] },
            {
                itemLabel: 'POS Tables Layout',
                link: '/tablesLayout',
                permissionCheckName: 'posConfigurationPanel_tablesLayout',
                featureFlags: ['posDevelopment'],
            },
            {
                itemLabel: 'POS Terminals',
                link: '/posTerminal',
                permissionCheckName: 'posConfigurationPanel_posTerminal',
                featureFlags: ['posDevelopment'],
            },
            { itemLabel: 'Pricelist lookup', link: '/priceLists/priceListLookup', permissionCheckName:"foldersPanel_pricelistLookup", featureFlags:null },
            ],
        description:"Create all models needed in application."
    },

    goodsManagement: {
        id:"goodsManagement",
        permissionCheckName: "goodsManagementPanel",
        featureFlags: ["goodsManagement"],
        fullLabel: 'Retail management',
        label: 'Retail management',
        icon: <Pos />,
        items: [  
            { itemLabel: 'SKU Items', link: '/goodsManagement/skuItems', permissionCheckName:"goodsManagementPanel_stockKeepingUnits", featureFlags:["goodsManagement" ]},
            { itemLabel: 'POS goods', link: '/priceLists/posItem', permissionCheckName:"goodsManagementPanel_posItems", featureFlags:["minibar"] },
            { itemLabel: 'Supplier price lists', link: '/goodsManagement/supplierPriceLists', permissionCheckName:"goodsManagementPanel_supplierPriceLists", featureFlags:["goodsManagement"] },
            { itemLabel: 'Warehouses', link: '/goodsManagement/warehouses', permissionCheckName:"goodsManagementPanel_warehouses", featureFlags:["goodsManagement"] },
            { itemLabel: 'Documents', link: '/goodsManagement/documents', permissionCheckName:"goodsManagementPanel_documents", featureFlags:["goodsManagement"] },
            { itemLabel: 'Retail price calculation', link: '/goodsManagement/entryCalculation', permissionCheckName:"goodsManagementPanel_entryCalculation", featureFlags:["goodsManagement"] },
            { itemLabel: 'Pricelist lookup', link: '/priceLists/priceListLookup', permissionCheckName:"goodsManagementPanel_pricelistLookup", featureFlags:null },
        ],
        description:"All about goods management process."
    },

    roomsAndHardware: {
        id:"roomsAndHardware",
        permissionCheckName: "roomsPanel",
        featureFlags: [],
        fullLabel: 'Hardware',
        label: 'Rooms',
        icon: <RoomsAndHardware/>,
        items: [
            { itemLabel: 'SOS groups', link: '/sosGroups', permissionCheckName:"roomsPanel_sosGroups", featureFlags:["sosGroupsAndLogs"] },
            { itemLabel: 'Controllers', link: '/srcProxies', permissionCheckName:"roomsPanel_controllers", featureFlags:null },
            { itemLabel: 'SRC templates', link: '/srcTemplates', permissionCheckName:"roomsPanel_SRCTemplates", featureFlags:["hardware"] },
            { itemLabel: 'Alarm settings', link: '/alarmAndRoomModeInterpreter', permissionCheckName:"roomsPanel_AlarmAndRoomMode", featureFlags: null },
            { itemLabel: 'Doors & rules', link: '/accessControl', permissionCheckName:"roomsPanel_doorsAndRules", featureFlags:null },
            { itemLabel: 'Whitelists', link: '/whiteLists', permissionCheckName:"roomsPanel_whitelists", featureFlags:null },
            { itemLabel: 'Document Scanners', link: '/docScannerDevices', permissionCheckName:"roomsPanel_documentScannerDevices", featureFlags:null },
        ],
        description:"Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms."
    },

    // bms: {
    //     id:"bms",
    //     permissionCheckName: "gatewayIntegration",
    //     featureFlags: ["BMS"],
    //     fullLabel: 'BMS',
    //     label: 'Price lists',
    //     icon: <Bms/>,
    //     items:null,
    //     link:"/bms",
    //     description:"BMS"
    // },

    reports: {
        id:"reports",
        permissionCheckName: "reports",
        featureFlags: [],
        fullLabel: 'Reports',
        label: 'Reports',
        icon: <Reports/>,
        items: [
            { itemLabel: 'Reservations', link: '/report/reservations', permissionCheckName:"reservationsPanel", featureFlags: null  },
            { itemLabel: 'Invoices', link: '/report/invoices', permissionCheckName:"invoicesPanel", featureFlags: null  },
            { itemLabel: 'Room events', link: '/report/roomEvents', permissionCheckName:"roomEventsPanel", featureFlags: null  },
            { itemLabel: 'Access control report', link: '/report/accessControl', permissionCheckName:"accessControlReportPanel", featureFlags: null  },
            { itemLabel: 'Retail management reports', link: '/report/retailManagement', permissionCheckName:"goodsManagementReportPanel", featureFlags: ['goodsManagement']  },
        ],
        description:"Reports list."
    },

    finance: {
        id:"finance",
        permissionCheckName: "financePanel",
        featureFlags: ["finance"],
        fullLabel: 'Finance',
        label: 'Finance',
        icon: <Finance/>,
        items: [
            { itemLabel: 'Cash Registar', link: '/cashRegistars', permissionCheckName:"financePanel_cashRegistar", featureFlags:["cashRegistar"] },
       ],
        description:"Finance pages."
    },
    
    advancedSettings: {
        id:"advancedSettings",
        permissionCheckName: "advancedSettingsPanel",
        featureFlags: [],
        fullLabel: 'Config',
        label: 'Config',
        icon: <AdvancedSettings/>,
        items: [
            { itemLabel: 'Settings', link: '/generalSettings', permissionCheckName:"advancedSettingsPanel_settings", featureFlags:null },
            { itemLabel: 'Notification service settings', link: '/notificationTemplateSettings', permissionCheckName:"advancedSettingsPanel_notificationServiceSettings", featureFlags:["outgoingNotificationChannel"] },
            { itemLabel: 'Visa Info', link: '/visaInfo', permissionCheckName:"advancedSettingsPanel_visaInfo", featureFlags:null },
            { itemLabel: 'Self check in administration', link: '/selfCheckInAdministrative', permissionCheckName:"advancedSettingsPanel_selfCheckInAdministration", featureFlags:null },
            { itemLabel: 'Channel manager', link: '/channelManagerMapping/wuBook', permissionCheckName:"advancedSettingsPanel_channelManager", featureFlags:["channelManager"]},
            {
                itemLabel: 'POS Display Config',
                link: '/posDisplayConfig',
                permissionCheckName: 'advancedSettingsPanel_displayConfigInfo',
                featureFlags: ['posDevelopment'],
            },
            { itemLabel: 'Licence', link: '/licence', permissionCheckName:"advancedSettingsPanel_licence", featureFlags:null },

            // { itemLabel: 'About', link: '/about', permissionCheckName:"advancedSettingsPanel", featureFlags:null },
        ],
        description:"Setup language, company informations, currency and modems configuration. Manage API keys."
    },
    billing: {
        id:"billing",
        permissionCheckName: "",
        featureFlags: ["billingModule"],
        fullLabel: 'Billing',
        label: 'Billing',
        icon: <Dollar/>,
        items: [
            { itemLabel: 'Features', link: '/billing', permissionCheckName:"", featureFlags:null },
            { itemLabel: 'Billing invoices', link: '/billingInvoices', permissionCheckName:"", featureFlags:null },
        ],
        description:"Setup language, company informations, currency and modems configuration. Manage API keys."
    },
    help_documentation: {
        id:"help_documentation",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Help and documentation',
        label: 'Help and documentation',
        icon: <Help/>,
        items: [
            { itemLabel: 'Help and support', link: '/help', permissionCheckName:"", featureFlags: null  },
            { itemLabel: 'Documentation', link: '/documentation', permissionCheckName:"", featureFlags: null  },
            
        ],
        description:"Find help or contact us."
    },

    logout: {
        id:"logout",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Log out',
        label: 'Log out',
        icon: <Logout/>,
        whenClicked:()=>{logMeOut()},
        // items: [],
        description:"All business conclusions in one place."
    },
};