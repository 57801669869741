import React, { Component } from 'react';
import { ToastContainer /* toast*/ } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import pubsub from "../../data/pubsub"
//import baobabTree from "../../data/state"
//import _ from "lodash"

// One of "default", "success", "info", "warning", "error". You can use
class Toaster extends Component {
    /*
	componentDidMount(){
		pubsub.on("SRCalarm", data=>{
			let toastDisplayConfig = _.clone(baobabTree.root.select(["SRCAlarmsAndAlerts","toastDisplayConfig",data.type]).get());
			toastDisplayConfig.toastId = data.toastId;
			switch(data.type){
				default:
				case "low":
					toast(data.alarm, toastDisplayConfig);
				break;
				case "medium":
					toast(data.alarm, toastDisplayConfig);
				break;
				case "high":
					toast(data.alarm, toastDisplayConfig);
				break;
				case "ultra":
					toast(data.alarm, toastDisplayConfig);
				break;
			}
			//console.log("AHA", data);
		})
	}
	*/
    render() {
        return <ToastContainer />;
    }
}
//SRCAlerts
/*
export default branch({
     SRCAlerts: ["SRCAlerts"]
}, Toaster);
*/
export default Toaster;
