import * as React from 'react';
import RoomAttributes from '@components/attributes';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { RoomInfoModel } from '@common/modelDefinition';

interface ReservationRoomAttributes {
    attributes: any[];
    room?: RoomInfoModel;
}

function ReservationRoomAttributes(props: ReservationRoomAttributes) {
    const { room = undefined, attributes } = props;
    if (!room) {
        return null;
    }
    const roomAttributesList = room.roomAttributes ? JSON.parse(room.roomAttributes) : [];

    if (roomAttributesList.length === 0) {
        return null;
    } else {
        return (
            <div className="pt-1">
                <RoomAttributes room={room} attributes={attributes} />
            </div>
        );
    }
}

export default branch(
    {
        attributes: ['model', 'RoomAttribute'],
    },
    ReservationRoomAttributes
);
