import {
    ReservationModel,
    GroupInfoModel,
    CustomerModel,
    CompanyModel,
    RoomInfoModel,
    ReservationGuestReferenceModel,
    ReservationInvoicedStatus,
    GroupInfoStatus,
    ReservationStatus,
    SelfServiceCheckInModel,
    SelfCheckInLogModel,
    UserInfo,
    MinibarLogWithIncludedModels,
    InvoiceModelWithInvoiceItems,
    AccommodationPricelistModel,
} from '@common/modelDefinition';
import baobab from '@data/state/index';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
import { v4 as uuidv4 } from 'uuid';
import { loadUserCondoData } from './condoOwnerView/condoApi';

import {
    getReservation,
    getSelfServiceCheckInByResId,
    getGroupInfo,
    addGuestToRes,
    saveOrUpdateReservation,
    saveSelfCheckIn,
    saveGroupRes,
    getResGuests,
    getCustomer,
    getCompany,
    getRoom,
    removeGuestFromRes,
    getPosTerminalAndMinibarProformaInvoices,
    getMinibarConsumption,
    getSelfCheckInLogsForRes,
    sendSelfCheckInDataNotification,
    updateResGuestReference,
    getUserInfo,
    getDoorAccessLogsForRes,
    _getAllReservationsFromTo,
    deleteRes,
    createMinibarProformaInvoice,
    saveInvoice,
    updateCus,
    updateResGuests,
    deleteMinibarLog,
} from './api';

import { _selfServiceCheckInTemplate } from './utils';
//@ts-ignore
import { toast } from 'react-toastify';
import translate from '@data/translations';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { reservationEnums } from '@data/reservationStatusEnums';
import { getRoomPricelist } from './AssignPriceList';
const moment: any = extendMoment(Moment as any);

export interface Reservation extends ReservationModel {
    sessionReservationId: string;
    Customer: CustomerModel | null;
    Company: CompanyModel | null;
    RoomInfo: RoomInfoModel;
    VisaInfo: ReservationGuestReferenceModel[];
    Guests: CustomerModel[];
    //DODANO 7.11.2021
    MinibarLogs: MinibarLogWithIncludedModels[]; //proširivamo model rezervacija - tako da se ne koristi useEffect
    SelfCheckInLogs: SelfCheckInLogModel[]; //proširujemo model tako da nigdje ne koristiš useEffect, već naloadaj podatke prilikom loadanja rezervacije!!!
    SelfServiceCheckIn: SelfServiceCheckInModel;
    Invoices: any[]; //ovo će se nekad morat tipizirati... ali neka sada stoji ovako kao any[] - predračuni s posterminala ili minibara
    Recepcionist: UserInfo | null;
}

export interface GroupReservation extends GroupInfoModel {
    Customer: CustomerModel | null;
    Company: CompanyModel | null;
    Reservations: ReservationModel[] | null;
}

export interface ReservationGuestsEndpoint {
    reservationGuestReferences: ReservationGuestReferenceModel[];
    customers: CustomerModel[];
}

export interface ReservationValidationResult {
    messages: string[];
    isValid: boolean;
}

export interface ValidationReport {
    overlaps: ReservationModel[];
    validationInfo: ReservationValidationResult;
    isFullyValid: boolean;
}

export interface ReservationHolder {
    customer: CustomerModel | null;
    company: CompanyModel | null;
}

export interface ValidationParams {
    checkInTimestamp: boolean;
    accommodationPriceListIdOrOpenPrice: boolean;
    roomInfoId: boolean;
    groupReservationHolder: boolean;
    reservationHolder: boolean;
}

const defaultReservationValidationParams: ValidationParams = {
    checkInTimestamp: true,
    accommodationPriceListIdOrOpenPrice: true,
    roomInfoId: true,
    groupReservationHolder: true,
    reservationHolder: true,
};

async function getReservationFromReservationModels(
    reservation: ReservationModel
): Promise<Reservation | ReservationModel> {
    if (reservation.id) {
        const newReservation = { ...reservation, sessionReservationId: uuidv4() } as Reservation;
        const ref = await getResGuests(reservation.id);
        const newResGuestRef: ReservationGuestReferenceModel[] = [];
        for (let i = 0; i < ref.reservationGuestReferences.length; i++) {
            const guestRef = { ...ref.reservationGuestReferences[i] };
            const customer = ref.customers.find((c: CustomerModel) => c.id === guestRef.customerId);
            if (guestRef.documentId === null || guestRef.documentType === null) {
                if (guestRef.documentId === null && customer?.documentId) {
                    guestRef.documentId = customer.documentId;
                }
                if (guestRef.documentType === null && customer?.documentType) {
                    guestRef.documentType = customer.documentType;
                }
                const updatedRef = await updateResGuests(guestRef);
                newResGuestRef.push(updatedRef);
            } else {
                newResGuestRef.push(guestRef);
            }
        }
        ref.reservationGuestReferences = [...newResGuestRef];

        //add visa info and guestReferences to reservation

        newReservation.VisaInfo = ref.reservationGuestReferences;
        newReservation.Guests = ref.customers;
        newReservation.MinibarLogs = await getMinibarConsumption(reservation.id);
        newReservation.Invoices = await getPosTerminalAndMinibarProformaInvoices(reservation.id);
        if (reservation.customerId) {
            //load reservation holders (this should be optimised to load with visa info)
            const customer = await getCustomer(reservation.customerId);

            newReservation.Customer = customer;
        }
        if (reservation.companyId) {
            //load reservation company holders
            const company = await getCompany(reservation.companyId);
            newReservation.Company = company;
        }
        newReservation.RoomInfo = await getRoom(reservation.roomInfoId);
        if (reservation.id) {
            if (reservation.enableSelfCheckIn) {
                newReservation.SelfServiceCheckIn = await getSelfServiceCheckInByResId(reservation.id);
                newReservation.SelfCheckInLogs = await getSelfCheckInLogsForRes(reservation.id);
            } else {
                newReservation.SelfServiceCheckIn = _selfServiceCheckInTemplate;
                newReservation.SelfCheckInLogs = [];
            }
        }

        if (reservation.receptionistUuid) {
            newReservation.Recepcionist = await getUserInfo(reservation.receptionistUuid);
        }

        return newReservation;
    } else {
        return reservation;
    }
}

function validateReservation(reservation: ReservationModel, params: ValidationParams): ReservationValidationResult {
    const messages: string[] = [];
    let isValid = true;
    if (!reservation) {
        return { isValid: false, messages };
    }
    if (params.checkInTimestamp && reservation.checkInTimestamp > reservation.checkOutTimestamp) {
        isValid = false;
        messages.push(translate("Checkin date can't be greater than checkout date!"));
    }

    if (
        params.accommodationPriceListIdOrOpenPrice &&
        reservation.accommodationPriceListId === null &&
        reservation.openPrice === null &&
        reservation.statusEnum !== reservationEnums.canceled
    ) {
        isValid = false;
        messages.push(translate('Please, select a price list or apply custom price!'));
    }

    if (params.reservationHolder && reservation.customerId === null && reservation.companyId === null) {
        isValid = false;
        messages.push(translate('Please, select customer or company!'));
    }

    if (params.roomInfoId && !reservation.roomInfoId) {
        isValid = false;
        messages.push(translate('Please, select a room!'));
    }
    //zašto je se ovo zakomentiralo????? zato sto se ne moze ni spremit onda ako namjerno hoces staviti da je closed
    // if (reservation.statusEnum === ReservationStatus.closed) {
    //     isValid = false;
    //     messages.push('Reservation is closed!');
    // }

    if (params.groupReservationHolder && reservation.groupUuid) {
        const group = baobab.root.select(['state', 'reservationForm2', 'groupReservation']).get();
        if (!group.customerId && !group.companyId) {
            isValid = false;
            messages.push(translate('Please select group reservation holder!'));
        }
    }

    return { messages, isValid };
}

function getOverlappingReservations(
    reservation: Reservation | ReservationModel,
    reservations: ReservationModel[]
): ReservationModel[] {
    const overlaps: ReservationModel[] = [];
    const currentRange = moment().range(
        new Date(reservation.checkInTimestamp),
        new Date(reservation.checkOutTimestamp)
    );
    let roomOtherReservations = reservations.filter((r) => {
        return (
            (Number(r.statusEnum) === ReservationStatus.earlyBird ||
                Number(r.statusEnum) === ReservationStatus.confirmed ||
                Number(r.statusEnum) === ReservationStatus.checkedIn ||
                Number(r.statusEnum) === ReservationStatus.autoBlocked) &&
            r.roomInfoId === Number(reservation.roomInfoId)
        );
    });
    if (reservation.id) {
        //filter out currently editing reservations
        roomOtherReservations = roomOtherReservations.filter((r) => r.id !== reservation.id);
        //filter out all canceled reservation - because they are unrelevant
        roomOtherReservations = roomOtherReservations.filter(
            (r) => Number(r.statusEnum) !== ReservationStatus.canceled
        );
    }
    roomOtherReservations.forEach((r) => {
        const tempRange = moment().range(new Date(r.checkInTimestamp), new Date(r.checkOutTimestamp));
        //Contains / Within / Overlaps / Intersect / Subtract
        if (tempRange.intersect(currentRange)) {
            overlaps.push(r);
        }
    });
    if (reservation.statusEnum === ReservationStatus.canceled) {
        return [];
    } else {
        return overlaps;
    }
}

async function getReservationTemplate(
    roomInfoId: number,
    checkInTimestamp: number,
    checkOutTimestamp: number,
    adultsNumber: number
): Promise<Reservation> {
    return {
        sessionReservationId: uuidv4(),
        checkInTimestamp: checkInTimestamp,
        checkOutTimestamp: checkOutTimestamp,
        roomInfoId: roomInfoId,
        statusEnum: ReservationStatus.confirmed,
        invoicedStatus: ReservationInvoicedStatus.notPaid,
        adultsNumber,
        enableSelfCheckIn: false,
        childrenNumber: 0,
        tags: null,
        accommodationPriceListId: null,
        customerId: null,
        companyId: null,
        receptionistUuid: baobab.root.select(['authTokenInfo', 'user', 'uuid']).get(),
        RoomInfo: await getRoom(roomInfoId),
        Customer: null,
        Company: null,
        VisaInfo: [],
        Guests: [],
        SelfServiceCheckIn: _selfServiceCheckInTemplate,
        SelfCheckInLogs: [],
        MinibarLogs: [],
        Invoices: [],
        Recepcionist: null,
        condoUserUuid: null,
        groupUuid: null,
        openPrice: null,
        canceledReservationInvoicingStatus: 0,
    };
}

interface ReservationControllerInterface {
    getReservations(): Reservation[];
    setActiveReservationIndex(index: number): void; //change selected tabs
    setActiveReservationsModels(index: number): void; //change selected tabs
    getActiveReservation(): Reservation;
    getActiveReservationIndex(): number;
    getGroupReservation(): GroupReservation | null;
    updateGroupReservation(groupInfo: GroupReservation): void;

    loadReservation(reservationId: number): void;
    loadGroup(groupUuid: string, reservationId?: number): void;

    clearAll(): void; //call only on unmount of main form component for memory cleanup

    addReservation(
        clearPrevious: boolean,
        roomInfoId: number,
        checkInTimestamp: number,
        checkOutTimestamp: number,
        adultsNumber: number,
        accommodationPriceListId: number | null,
       // board: string | null,
        customerId?: number | null
    ): void;

    getFullValidationReport(params: ValidationParams | null): ValidationReport;
    getReservationValidationReport(reservation: Reservation): ValidationReport;

    save(isGroupForm: boolean): Promise<string>;

    setGroupReservationHolder(holder: ReservationHolder): void;
    updateReservationGuestReference(updatedGuestReference: any): void; //OVO MI JE SUMNJIVO??? GDJE GA UPDEJTAŠ???? AHA - SVUGDJE! OK!!

    //odnosi se od sada na aktivnu rezervaciju!!!!
    updateActiveReservation(updatedReservation: ReservationModel): void;
    updateActiveReservationRoomInfo(roomInfo: RoomInfoModel): void;
    setSelfServiceCheckIn(s: SelfServiceCheckInModel, enableSelfCheckIn: boolean | null): void;
    setReservationHolder(holder: ReservationHolder): void;
    sendCheckInDataNotification(): void;
    removeGuestFromReservation(resGuestRefId: number): void;
    addGuestToReservation(customer: CustomerModel, fixedTaxId: number): void;
    deleteMinibarDataLog(minibarLog: MinibarLogWithIncludedModels): void;
    removeFromGroupRes(): void;
    getCustomersWithInvalidVisaIds(): number[]; //za koju rezervaciju su invalidni ili je ovo za save - provjeriti ovaj dio!!!! Koliko vidim samo za provjeru jedne rezervacije - aktivne!!!

    //doublecheck
    getGrupReservationHolder(): ReservationHolder;
    setGroupName(value?: string): void;
    getDoorAccessLogsForReservation(reservationId: number): Promise<any[]>;
    getAllReservationsFromTo(from: any, to: any, page: number, rows: number): Promise<ReservationModel[]>;
    deleteReservation(reservationId: number): void;

    setCondoUserUuid(condoUserUuid: string | null): void;
    addExistingGroup(groupUuid: string): void;
}

class ReservationController implements ReservationControllerInterface {
    setActiveReservationIndex(index: number) {
        baobab.root.select(['state', 'reservationForm2', 'activeReservationIndex']).set(index);
    }

    async setActiveReservationsModels(index: number) {
        const reservations = this.getReservations();
        const targetReservation = reservations[index];

        const updatedActiveReservation = await getReservationFromReservationModels(targetReservation);
        const updatedReservations = reservations.map((r: Reservation, key: number) => {
            if (key === index) {
                const newReservation = { ...r, ...updatedActiveReservation };
                return newReservation;
            } else {
                return r;
            }
        });

        baobab.root.select(['state', 'reservationForm2', 'reservations']).set(updatedReservations);
    }

    getActiveReservation(): Reservation {
        const activeReservationIndex: number = baobab.root
            .select(['state', 'reservationForm2', 'activeReservationIndex'])
            .get();
        return this.getReservations()[activeReservationIndex];
    }

    getActiveReservationIndex(): number {
        const activeReservationIndex: number = baobab.root
            .select(['state', 'reservationForm2', 'activeReservationIndex'])
            .get();
        return activeReservationIndex;
    }

    setReservationHolder(holder: ReservationHolder) {
        const { customer, company } = holder;
        const target = { ...this.getActiveReservation() };
        target.Customer = customer ? customer : null;
        target.Company = company ? company : null;
        target.companyId = company ? (company as any).id : null;
        target.customerId = customer ? (customer as any).id : null;
        this.updateActiveReservation(target);
    }

    setGroupReservationHolder(holder: ReservationHolder) {
        const { customer, company } = holder;
        const group = this.getGroupReservation();
        if (group) {
            const gr = { ...group };
            gr.Customer = customer ? customer : null;
            gr.Company = company ? company : null;
            gr.companyId = company ? (company as any).id : null;
            gr.customerId = customer ? (customer as any).id : null;
            baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(gr);
            if (gr.Customer || gr.Company) {
                this.setGroupName();
            }
            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
        }
    }

    setGroupName(value?: string) {
        const group = this.getGroupReservation();
        if (group) {
            const gr = { ...group };
            if (!value) {
                gr.groupName = new Date().toISOString().substring(0, 7);
                if (gr.Customer) {
                    gr.groupName = `${gr.groupName} ${gr.Customer.firstName ? gr.Customer.firstName : ''} ${
                        gr.Customer.lastName ? gr.Customer.lastName : ''
                    }`;
                }
                if (gr.Company) {
                    gr.groupName = `${gr.groupName} (${gr.Company.name})`;
                }
            } else {
                gr.groupName = value;
            }
            baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(gr);
            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
        }
    }

    setNote(value?: string) {
        const group = this.getGroupReservation();
        if (group) {
            const gr = { ...group };
            gr.note = value;
            baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(gr);
            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
        }
    }

    getGrupReservationHolder() {
        const group = this.getGroupReservation();
        if (group) {
            return { customer: group.Customer, company: group.Company };
        } else {
            return { customer: null, company: null };
        }
    }

    getReservations(): Reservation[] {
        const reservations = baobab.root.select(['state', 'reservationForm2', 'reservations']).get();
        return reservations as Reservation[];
    }

    getGroupReservation(): GroupReservation | null {
        const groupReservationCover = baobab.root.select(['state', 'reservationForm2', 'groupReservation']).get();
        if (groupReservationCover.uuid) {
            return groupReservationCover as GroupReservation;
        } else {
            return null;
        }
    }

    setSelfServiceCheckIn(s: SelfServiceCheckInModel, enableSelfCheckIn: boolean | null) {
        const activeReservation = { ...this.getActiveReservation() };
        activeReservation.enableSelfCheckIn =
            enableSelfCheckIn === null ? activeReservation.enableSelfCheckIn : enableSelfCheckIn;
        activeReservation.SelfServiceCheckIn = { ...s };
        this.updateActiveReservation(activeReservation);
    }

    updateGroupReservation(groupInfo: GroupReservation) {
        const groupUuid = baobab.root.select(['state', 'reservationForm2', 'groupReservation', 'uuid']).get();
        if (groupUuid) {
            const target = this.getGroupReservation() as any;
            if (target) {
                //update only provided atributes
                Object.keys(groupInfo).forEach((attribute: string) => {
                    target[attribute] = (groupInfo as any)[attribute];
                });
                baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
                baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(target);
            }
        } else {
            throw new Error(translate('Reservations group does not exists!'));
        }
    }

    async addReservation(
        clearPrevious: boolean,
        roomInfoId: number,
        checkInTimestamp: number,
        checkOutTimestamp: number,
        adultsNumber: number,
        accommodationPriceListId: number | null,
      //  board: string | null,
        customerId?: number
    ) {
        startGlobalLoading();
        const newReservation: Reservation = await getReservationTemplate(
            roomInfoId,
            checkInTimestamp,
            checkOutTimestamp,
            adultsNumber
        );
        if (customerId) {
            newReservation.customerId = customerId;
        }
        if (accommodationPriceListId) {
            newReservation.accommodationPriceListId = accommodationPriceListId;
        }
        // if (board) {
        //     newReservation.board = board;
        // }
        if (clearPrevious) {
            this.clearAll();
        }
        
        await this.addUpdateReservation(newReservation);
        const reservations = this.getReservations();
        this.setActiveReservationIndex(reservations.length - 1);
        baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
        stopGlobalLoading();
    }

    async loadReservation(reservationId: number) {
        startGlobalLoading();
        this.clearAll();
        try {
            const reservation: ReservationModel = await getReservation(reservationId);
            if (reservation) {
                if (reservation.groupUuid || reservation.previousGroupUuid) {
                    if (reservation.previousGroupUuid) {
                        await this.loadGroup(reservation.previousGroupUuid, reservationId);
                    } else if (reservation.groupUuid) {
                        await this.loadGroup(reservation.groupUuid, reservationId);
                    }
                } else {
                    const reservations: ReservationModel[] = [reservation];

                    const updatedActiveReservation: ReservationModel | Reservation =
                        await getReservationFromReservationModels(reservations[0]);
                    const updatedReservations = reservations.map((r: ReservationModel, index: number) => {
                        if (index === 0) {
                            const newReservation: ReservationModel | Reservation = {
                                ...r,
                                ...updatedActiveReservation,
                            };
                            return newReservation;
                        } else {
                            return r;
                        }
                    });
                    baobab.root.select(['state', 'reservationForm2', 'reservations']).set(updatedReservations);
                }
            }
            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(false);
            stopGlobalLoading()
        } catch (err) {
            console.log('err', err);
        }
        stopGlobalLoading();
    }

    async loadGroup(groupUuid: string, reservationId?: number) {
        startGlobalLoading();
        try {
            const groupRes: GroupReservation = await getGroupInfo(groupUuid);
            if (groupRes.Reservations) {
                this.clearAll();
                let resIndex: number = 0;
                const reservations: ReservationModel[] = groupRes.Reservations;
                if (reservations.length === 0) {
                    toast(translate('Group does not have any reservation.'), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: toast.TYPE.ERROR,
                    });
                }
                if (reservationId) {
                    resIndex = reservations.findIndex((r) => r.id === reservationId);
                }
                this.setActiveReservationIndex(resIndex);
                const targetReservation = reservations[resIndex];
                const updatedActiveReservation = await getReservationFromReservationModels(targetReservation);
                const updatedReservations = reservations.map((r: ReservationModel, index: number) => {
                    if (index === resIndex) {
                        const newReservation = { ...r, ...updatedActiveReservation };
                        return newReservation;
                    } else {
                        return r;
                    }
                });

                baobab.root.select(['state', 'reservationForm2', 'reservations']).set(updatedReservations);
                baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(groupRes);
            }
        } catch (err) {
            console.log('err', err);
        }
        stopGlobalLoading();
    }

    //stavlja rezervaciju u aktivnu ili ako postoji aktivna, onda kreira grupu i stavlja je u grupu kao drugu rezervaciju
    //ova je namijenjena da bude protected - tj da se samo koristi unutar ove klase - nipošto izvan!!!
    protected addUpdateReservation(reservation: Reservation) {
        const reservations = this.getReservations().map((r: Reservation) => {
            return { ...r }; //break baobab reference
        });
        const target = reservations.find(
            (r: Reservation) => r.id === reservation.id && r.roomInfoId === reservation.roomInfoId
        );
        if (target) {
            //update only provided atributes
            Object.keys(reservation).forEach((attribute: string) => {
                (target as any)[attribute] = (reservation as any)[attribute];
            });
            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
            baobab.root.select(['state', 'reservationForm2', 'reservations']).set(reservations);
        } else {
            if (reservations.length === 0) {
                //new reservation
                reservations.push(reservation);
                baobab.root.select(['state', 'reservationForm2', 'reservations']).set(reservations);
            } else {
                //ovo znači da je grupna rezervacija jer dodajemo još jednu na stog
                const groupUuid = baobab.root.select(['state', 'reservationForm2', 'groupReservation', 'uuid']).get();
                if (groupUuid) {
                    reservation.groupUuid = groupUuid;
                } else {
                    const groupRes: GroupInfoModel = {
                        uuid: uuidv4(),
                        checkOutTimestamp: reservation.checkOutTimestamp,
                        checkInTimestamp: reservation.checkInTimestamp,
                        invoicedStatus: ReservationInvoicedStatus.notPaid,
                        customerId: this.getReservations()[0].customerId,
                        companyId: this.getReservations()[0].companyId,
                        status: GroupInfoStatus.active,
                        receptionistUuid: baobab.root.select(['authTokenInfo', 'user', 'uuid']).get(),
                        type: 'reservation',
                    };
                    baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(groupRes);
                    this.setGroupName();
                    reservation.groupUuid = groupRes.uuid;
                }
                reservations.push(reservation);
                const _allOtherReservations = reservations;
                const _reservations: Reservation[] = [];
                for (let i = 0; i < _allOtherReservations.length; i++) {
                    const res: any = { ..._allOtherReservations[i] };
                    res.groupUuid = reservation.groupUuid;
                    res.isPending = true;
                    _reservations.push(res);
                }
                baobab.root.select(['state', 'reservationForm2', 'reservations']).set(_reservations);
            }
        }
    }

    async createNewRes(room: RoomInfoModel, acp: any) {
        if (room.id) {
            const { checkInTimestamp, checkOutTimestamp } = this.getActiveReservation();
            let targetPriceList = null;
            if (room) {
                const targetPricelists: AccommodationPricelistModel[] = getRoomPricelist(room, acp);
                if (targetPricelists?.length === 1) {
                    targetPriceList = acp.find(
                        (a: AccommodationPricelistModel) => targetPricelists[0].id && a.id === targetPricelists[0].id
                    );
                }
            }
            const accommodationPriceListId = targetPriceList?.id ? targetPriceList.id : null;
            await this.addReservation(false, room.id, checkInTimestamp, checkOutTimestamp, 1, accommodationPriceListId);
        }
    }

    async removeFromGroupRes() {
        //  const { groupUuid } = this.getActiveReservation();
        const activeReservation = { ...this.getActiveReservation() };
        activeReservation.groupUuid = null;
        // if(activeReservation.statusEnum !== reservationEnums.canceled && activeReservation.statusEnum !== reservationEnums.autoBlocked){
        //     activeReservation.previousGroupUuid = groupUuid;
        // }
        this.updateActiveReservation(activeReservation);
    }

    getCustomersWithInvalidVisaIds() {
        const invalidIds: number[] = [];
        const reservationGuestReferences: ReservationGuestReferenceModel[] = [];
        const reservationGuests: CustomerModel[] = [];
        const requiredVisaMap = baobab.root.select(['model', 'RequiredVisaMap']).get();
        const requiredVisaMapCCodes = requiredVisaMap.map((v: any) => v.countryCode).filter((v: any) => v !== null);

        const activeReservation = this.getActiveReservation();
        activeReservation?.VisaInfo?.forEach((grf: ReservationGuestReferenceModel) => {
            reservationGuestReferences.push(grf);
        });

        activeReservation?.Guests?.forEach((c: CustomerModel) => {
            reservationGuests.push(c);
        });

        for (const r of reservationGuestReferences) {
            if (
                r.documentValidityPeriod &&
                r.placeEntry &&
                r.visaEntryDate &&
                r.visaTypeAndNumber &&
                r.visaValidityPeriod &&
                r.documentId &&
                r.documentType
            ) {
                //do nothing....
            } else {
                const customer = reservationGuests.find((c) => c.id === r.customerId);
                const nationalityCode = customer && customer.nationality ? Number(customer.nationality) : null;
                if (requiredVisaMapCCodes.includes(nationalityCode)) {
                    if (customer?.id) {
                        invalidIds.push(customer.id);
                    }
                } else if (r.documentId === null || r.documentType === null) {
                    if (customer?.id) {
                        invalidIds.push(customer.id);
                    }
                }
            }
        }

        return invalidIds;
    }

    getReservationValidationReport(reservation: Reservation): ValidationReport {
        const reservations = baobab.root.select(['model', 'Reservation']).get();
        const overlaps = getOverlappingReservations(reservation, reservations as ReservationModel[]);
        const validationInfo = validateReservation(reservation, defaultReservationValidationParams);
        const isFullyValid = overlaps.length === 0 && validationInfo.isValid;
        return { overlaps, validationInfo, isFullyValid };
    }

    getFullValidationReport(params: ValidationParams | null): ValidationReport {
        const allReservationsForOverlaps = baobab.root.select(['model', 'Reservation']).get();
        const overlaps: ReservationModel[] = [];
        let _isValid = true;
        const _messages: string[] = [];

        this.getReservations().forEach((reservation) => {
            const ovlps = getOverlappingReservations(reservation, allReservationsForOverlaps as ReservationModel[]);
            const vInfo = validateReservation(reservation, params ? params : defaultReservationValidationParams);

            ovlps.forEach((o) => {
                overlaps.push(o);
            });

            const { isValid, messages } = vInfo;
            if (!isValid) {
                _isValid = false; //if any is invelid, then all are invalid!!!
            }

            messages.forEach((msg: string) => {
                if (!_messages.includes(msg)) {
                    _messages.push(msg);
                }
            });
        });

        const isFullyValid = overlaps.length === 0 && _isValid;
        const validationInfo = { isValid: _isValid, messages: _messages };
        return { overlaps, validationInfo, isFullyValid };
    }

    async addGuestToReservation(customer: CustomerModel, fixedTaxId: number) {
        const target = { ...this.getActiveReservation() };
        if (target.id) {
            const customerId = customer.id;
            const alreadyAdded = target?.VisaInfo?.find((c) => Number(c.customerId) === Number(customerId));
            if (!alreadyAdded) {
                const resRef = await addGuestToRes(customerId, target.id, fixedTaxId);
                target.VisaInfo = [...target.VisaInfo];
                target.VisaInfo.push({ ...resRef });
                target.Guests = [...target.Guests];
                target.Guests.push({ ...customer });
                const adultsNumber: number =
                    target && target.adultsNumber !== null && target.adultsNumber !== undefined
                        ? target.adultsNumber
                        : 1;
                target.adultsNumber =
                    target && target.Guests && target.Guests.length >= adultsNumber
                        ? target.Guests.length
                        : adultsNumber;
                this.updateActiveReservation(target);
            }
        }
    }

    async removeGuestFromReservation(resGuestRefId: number) {
        const activeReservation = { ...this.getActiveReservation() };
        let customerDelete: number | null = null;
        activeReservation.VisaInfo = [...activeReservation.VisaInfo].filter((v) => {
            if (v.id !== Number(resGuestRefId)) {
                return v;
            } else {
                customerDelete = v.customerId;
                return null;
            }
        });
        activeReservation.Guests = [...activeReservation.Guests].filter((g) => g.id !== customerDelete);
        activeReservation.adultsNumber = activeReservation.Guests.length;
        await removeGuestFromRes(resGuestRefId);
        this.updateActiveReservation(activeReservation);
    }

    protected async saveSelfServiceCheckIn(reservation: Reservation) {
        if (reservation.enableSelfCheckIn && reservation.id) {
            const clonedStateSelfServiceCheckIn = { ...reservation.SelfServiceCheckIn } as any;
            delete clonedStateSelfServiceCheckIn.pinCode;
            const _selfCheckIn = await saveSelfCheckIn(clonedStateSelfServiceCheckIn, reservation.id);
            const clonedRes = { ...reservation };
            clonedRes.SelfServiceCheckIn = { ..._selfCheckIn };
            this.updateActiveReservation(clonedRes);
        }
    }

    setCondoUserUuid(condoUserUuid: string | null) {
        const activeReservation = { ...this.getActiveReservation() };
        activeReservation.condoUserUuid = condoUserUuid;
        this.updateActiveReservation(activeReservation);
    }

    async save(isGroupForm: boolean): Promise<string> {
        let redirectPath = '';
        try {
            const { isFullyValid } = this.getFullValidationReport(null);

            if (!isFullyValid) {
                throw new Error(
                    translate('Reservation is not valid! Please ensure validity before sending to the server!')
                );
            }

            let reloadToReservationId = null; //only for non group reservation, if reload is required!!!!
            const groupCover = this.getGroupReservation();
            const activeReservation = this.getActiveReservation();

            if (groupCover) {
                //it is a group reservation
                //beware!!! in this step, you can have reservation in this form which are ejected from the group cover but still showin in the tabs...
                const activeGroupReservations = this.getReservations().filter((r) => {
                    return r.groupUuid === groupCover.uuid;
                });
                const ejectedReservations = this.getReservations().filter((r) => {
                    return r.groupUuid !== groupCover.uuid;
                });
                const newGroupCover = { ...groupCover };
                newGroupCover.type = 'reservation';
                for (const r of ejectedReservations) {
                    await saveOrUpdateReservation(r);
                }
                await saveGroupRes(activeGroupReservations, newGroupCover);
            } else {
                //it is a single reservation
                const updatedReservation = await saveOrUpdateReservation(activeReservation);
                if (!activeReservation.id) {
                    reloadToReservationId = updatedReservation.id;
                }
            }

            //SAVE SELF CHECKIN IN DATA
            for (const r of this.getReservations()) {
                await this.saveSelfServiceCheckIn(r); //save any potential selfReservationInfo
            }

            //reload data and redirect router
            if (isGroupForm && groupCover) {
                // redirectPath = `/groupReservationForm?uuid=${groupCover.uuid}`;
                if (activeReservation.groupUuid) {
                    await this.loadGroup(groupCover.uuid, activeReservation.id);
                } else {
                    await this.loadGroup(groupCover.uuid);
                }
            } else if (reloadToReservationId) {
                if (activeReservation.condoUserUuid) {
                    // redirectPath = `/condoReservation/${activeReservation.RoomInfo.id}/${reloadToReservationId}/edit`;
                    await this.loadCondoReservation(reloadToReservationId);
                } else {
                    // redirectPath = `/reservations/${reloadToReservationId}/edit`;
                    await this.loadReservation(reloadToReservationId);
                }
            } else if (activeReservation.id) {
                if (!activeReservation.condoUserUuid) {
                    // kad removam rezervaciju iz grupne prikazat ce mi nju
                    // redirectPath = `/reservations/${activeReservation.id}/edit`;
                    await this.loadReservation(activeReservation.id);
                } else {
                    await this.loadCondoReservation(activeReservation.id);
                }
                //edit condo rezervacije ne treba redirectati nigdje
            }

            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(false);
        } catch (err) {
            console.log(err);
        }
        return redirectPath;
    }

    async deleteMinibarDataLog(minibarLog: MinibarLogWithIncludedModels) {
        // if (minibarLog.InvoiceItem) {
        //     return null;
        // }
        const activeReservation = { ...this.getActiveReservation() };
        let findItemInLog: MinibarLogWithIncludedModels | undefined = [...activeReservation.MinibarLogs].find(
            (m) => m.id === minibarLog.id
        );

        //ovo nevalja! Mora biti na backendu funkcija koja ovo automatizira na neki način - promjjeni type na starom i dodaj novi. To mora biti unutar transakcije!!!!
        if (activeReservation && findItemInLog && activeReservation.roomInfoId) {
            await deleteMinibarLog(findItemInLog);
            activeReservation.MinibarLogs = [...activeReservation.MinibarLogs].filter((l) => l.id !== minibarLog.id);
            this.updateActiveReservation(activeReservation);
        }
    }

    //tehnički - ok je da ovdje ostane jer je vezano za rezervaciju. Jedino što treba malo validacija ubaciti unutar funkcije....
    async sendCheckInDataNotification() {
        const activeReservation = this.getActiveReservation();
        if (activeReservation.id) {
            return await sendSelfCheckInDataNotification(activeReservation.id);
        }
    }

    async updateReservationGuestReference(updatedGuestReference: ReservationGuestReferenceModel) {
        await updateResGuestReference(updatedGuestReference);
        const reservations = this.getReservations().map((r: Reservation) => {
            return { ...r }; //break baobab reference
        });
        const target = reservations.find(
            (r: ReservationModel) => r.id === updatedGuestReference.reservationId
        ) as Reservation;
        target.VisaInfo = [...target.VisaInfo].map((v) => {
            if (v.id === updatedGuestReference.id) {
                return { ...updatedGuestReference };
            } else {
                return { ...v };
            }
        });
        baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
        baobab.root.select(['state', 'reservationForm2', 'reservations']).set(reservations);
        this.getCustomersWithInvalidVisaIds();
    }

    async getDoorAccessLogsForReservation(reservationId: number) {
        return await getDoorAccessLogsForRes(reservationId);
    }

    async getAllReservationsFromTo(from: any, to: any, page: number, rows: number) {
        return await _getAllReservationsFromTo(moment(from).startOf('day'), moment(to).endOf('day'), page, rows);
    }

    async deleteReservation(reservationId: number) {
        await deleteRes(reservationId);
    }

    async updateCustomer(customer: CustomerModel) {
        await updateCus(customer);
    }

    async loadCondoReservation(reservationId: number) {
        startGlobalLoading();
        try {
            //try to find reservation in baobab - condo user dont have permissions to read public reservation info!!!!
            await loadUserCondoData();
            const condoReservation: Reservation = baobab.root
                .select('model', 'Reservation', { id: reservationId })
                .get();
            if (condoReservation) {
                baobab.root.select(['state', 'reservationForm2', 'reservations']).set([condoReservation]);
            }

            baobab.root.select(['state', 'reservationForm2', 'isPending']).set(false);
        } catch (err) {
            console.log('err', err);
        }
        stopGlobalLoading();
    }

    updateActiveReservationRoomInfo(roomInfo: RoomInfoModel) {
        if (roomInfo.id) {
            const activeReservation = { ...this.getActiveReservation() };
            activeReservation.RoomInfo = { ...roomInfo };
            activeReservation.roomInfoId = roomInfo.id;
            this.updateActiveReservation(activeReservation);
        }
    }

    clearAll() {
        baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set({});
        baobab.root.select(['state', 'reservationForm2', 'reservations']).set([]);
        baobab.root.select(['state', 'reservationForm2', 'activeReservationIndex']).set(0);
        baobab.root.select(['state', 'reservationForm2', 'isPending']).set(false);
    }

    updateActiveReservation(updatedReservation: ReservationModel) {
        const activeReservationIndex = this.getActiveReservationIndex();
        const updatedReservations = this.getReservations().map((r: Reservation, index: number) => {
            if (index === Number(activeReservationIndex)) {
                return { ...updatedReservation };
            } else {
                return r;
            }
        });
        baobab.root.select(['state', 'reservationForm2', 'isPending']).set(true);
        baobab.root.select(['state', 'reservationForm2', 'reservations']).set(updatedReservations);
    }

    async createMinibarProformaInvoice(reservationId: number) {
        await createMinibarProformaInvoice(reservationId);
        const activeReservation = { ...this.getActiveReservation() };
        activeReservation.MinibarLogs = await getMinibarConsumption(reservationId);
        activeReservation.Invoices = await getPosTerminalAndMinibarProformaInvoices(reservationId);
        this.updateActiveReservation(activeReservation);
        baobab.root.select(['state', 'reservationForm2', 'isPending']).set(false);
    }

    async addOrRemoveInvoice(invoice: InvoiceModelWithInvoiceItems, add = true) {
        await saveInvoice(invoice);
        const activeReservation = { ...this.getActiveReservation() };
        let invoices: InvoiceModelWithInvoiceItems[] = [...activeReservation.Invoices];
        if (add) {
            invoices.push(invoice);
        } else {
            invoices = invoices.filter((i: InvoiceModelWithInvoiceItems) => i.id !== invoice.id);
        }

        activeReservation.Invoices = invoices;
        this.updateActiveReservation(activeReservation);
        baobab.root.select(['state', 'reservationForm2', 'isPending']).set(false);
    }
    async addExistingGroup(groupUuid: string) {
        const grup = await getGroupInfo(groupUuid);
        baobab.root.select(['state', 'reservationForm2', 'groupReservation']).set(grup);
        const activeReservation = { ...this.getActiveReservation() };
        activeReservation.groupUuid = groupUuid;
        this.updateActiveReservation(activeReservation);
    }

    async loadSingleReservation(reservationId: number) {
        try {
            const reservation: ReservationModel = await getReservation(reservationId);
            if (reservation) {
                const resWithModels: ReservationModel | Reservation = await getReservationFromReservationModels(
                    reservation
                );
                return resWithModels;
            } else {
                return null;
            }
        } catch (err) {
            console.log('err', err);
        }
    }
}

export { getOverlappingReservations };
export { ReservationController };
