import React, { Component } from 'react';
import _ from 'lodash';
import { reservationStatusColorsHEX, reservationStatusColorsHEXCondo, reservationStatusEnumsDesc } from '../../data/reservationStatusEnums';
import translate from '@data/translations';

class ReservationStatusLegend extends Component {
    clickHandler(i, e) {
        e.preventDefault();
        this.props.toggleHideReservationStatusEnums(i);
    }
    condoResFilter(i, e) {
        e.preventDefault();
        this.props.toggleHideCondoReservations(i);
    }

    render() {
        let items = [];
        for (let i = 0; i <= 5; i++) {
            items.push(
                <div className="" key={'legend' + i}>
                    <label onClick={this.clickHandler.bind(this, i)} className="checkbox-container">
                        {reservationStatusEnumsDesc[i]}
                        <input
                            onChange={() => {}}
                            checked={!_.includes(this.props.hideReservationStatusEnums, i)}
                            type="checkbox"
                        />
                        <span style={{ backgroundColor: reservationStatusColorsHEX[i] }} className="checkmark"></span>
                    </label>
                </div>
            );
        }
        items.push(
            <div className="" key={'legend condo'}>
                <label onClick={this.condoResFilter.bind(this, !this.props.hideCondoReservations)} className="checkbox-container">
                    {translate("Condo reservations")}
                    <input
                        onChange={() => {}}
                        checked={!this.props.hideCondoReservations}
                        type="checkbox"
                    />
                    <span style={{ backgroundColor: reservationStatusColorsHEXCondo[1] }} className="checkmark"></span>
                </label>
            </div>
        );
        return (
            <div className='reservation-status-checkboxes'>
                {items}
            </div>
        );
    }
}

export default ReservationStatusLegend;
