import React from 'react';
import FeatureFlag from '../../components/FeatureFlag';
import '../roomInfo/style.css';

function ActivityFilterToolbar(props) {
    return (
        <FeatureFlag flag="activityDashboard">
            <div className="form-inline ">
                <button
                    onClick={props.toggleActivityFilter.bind(this, 0)}
                    className={
                        props.activityTypes.includes(0)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-sign-in" /> {window.translate('Early bird')}
                </button>
                <button
                    onClick={props.toggleActivityFilter.bind(this, 2)}
                    className={
                        props.activityTypes.includes(2)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-sign-in" /> {window.translate('Check in')}
                </button>

                <button
                    onClick={props.toggleActivityFilter.bind(this, 4)}
                    className={
                        props.activityTypes.includes(4)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-sign-out" /> {window.translate('Check out')}
                </button>

                <button
                    onClick={props.toggleActivityFilter.bind(this, 6)}
                    className={
                        props.activityTypes.includes(6)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-moon-o text-info" /> {window.translate('Stay over')}{' '}
                </button>

                <button
                    onClick={props.toggleActivityFilter.bind(this, 7)}
                    className={
                        props.activityTypes.includes(7)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-clock-o  text-info" /> {window.translate('Late check in')}{' '}
                </button>
                <button
                    onClick={props.toggleActivityFilter.bind(this, 8)}
                    className={
                        props.activityTypes.includes(8)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-hand-o-right text-info" /> {window.translate('Checked In')}{' '}
                </button>
                <button
                    onClick={props.toggleActivityFilter.bind(this, 5)}
                    className={
                        props.activityTypes.includes(5)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-hand-o-left text-info" /> {window.translate('Checked Out')}{' '}
                </button>
                <button
                    onClick={props.toggleActivityFilter.bind(this, 9)}
                    className={
                        props.activityTypes.includes(9)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-hand-o-left text-info" /> {window.translate('Autoblock')}{' '}
                </button>
                <button
                    onClick={props.toggleActivityFilter.bind(this, 'allCheckIn')}
                    className={
                        props.activityTypes.includes(0) &&
                        props.activityTypes.includes(2) &&
                        props.activityTypes.includes(7)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-hand-o-right text-info" /> {window.translate('All check-in')}{' '}
                </button>
                <button
                    onClick={props.toggleActivityFilter.bind(this, 'allCheckOut')}
                    className={
                        props.activityTypes.includes(4) && props.activityTypes.includes(6)
                            ? 'btn btn-sm btn-secondary mr-1 mb-1'
                            : 'btn btn-sm btn-light border mr-1 mb-1'
                    }
                >
                    <i className="fa fa-hand-o-left text-info" /> {window.translate('All check-out')}{' '}
                </button>
            </div>
        </FeatureFlag>
    );
}

export default ActivityFilterToolbar;
