import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import _ from 'lodash';
//@ts-ignore
import { translate } from '@data/translations';
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';
import { Reservation, ReservationController } from './resController';
import { RoomInfoModel } from '@common/modelDefinition';
import { getAvailableRooms, getTypeOfRoomWithRoomIds } from './api';
import DateTimeSelector from './DateTimeSelector';
import RoomAttributes from '@components/attributes';
import { CustomSelect, customStyles } from '@pages/dashboard/toolbar';

const ctrl = new ReservationController();

interface ShowAvailableRoomsForReservationModalProps {
    reservation: Reservation;
    rooms: RoomInfoModel[];
    attributes: any;
    changeRoomHandler?: (room?: RoomInfoModel, priceListEnumSelection?: string) => void;
}

function ShowAvailableRoomsForReservationModal(props: ShowAvailableRoomsForReservationModalProps) {
    const { reservation, rooms, attributes } = props;

    const [avlRooms, setAvlRooms] = React.useState([] as RoomInfoModel[]);
    const [selectedTag, setSelectedTag] = React.useState('');
    const [numberOfBeds, setNumberOfBeds] = React.useState('');
    const [roomAttr, setRoomAttr] = React.useState([] as any[]);
    const [selectedAttribute, setAttribute] = React.useState([] as any[]);
    const [currentRoomAttr, setCurrentRoomAttr] = React.useState([] as any[]);
    const [currentRoomTags, setCurrentRoomTags] = React.useState([] as any[]);
    const [checkInTimestamp, setCheckInTimestamp] = React.useState<number | null>(null);
    const [checkOutTimestamp, setCheckOutTimestamp] = React.useState<number | null>(null);

    React.useEffect(() => {
        const loadData = async () => {
            const _currentRoom = rooms.find((r: any) => r.id === reservation.roomInfoId);
            const attr = _currentRoom?.roomAttributes ? JSON.parse(_currentRoom.roomAttributes) : [];
            const _currentRoomAttr: any[] = [];
            attr.forEach((a: any) => {
                const attrName = attributes.find((atr: any) => atr.id === a);
                if (attrName) {
                    _currentRoomAttr.push(attrName.name);
                }
            });
            setCurrentRoomAttr(_currentRoomAttr);
            const _currentTags = _currentRoom && _currentRoom.tags ? _currentRoom.tags.split(';') : [];
            setCurrentRoomTags(_currentTags);
            if (!checkInTimestamp) {
                setCheckInTimestamp(reservation.checkInTimestamp);
            }
            if (!checkOutTimestamp) {
                setCheckOutTimestamp(reservation.checkOutTimestamp);
            }

            const _avlRooms = await getAvailableRooms(checkInTimestamp, checkOutTimestamp, reservation.roomInfoId);
            const typeOfRoomWithRoomIds = reservation && reservation.providerReservation ? await getTypeOfRoomWithRoomIds('wuBook', reservation.roomInfoId) : null

            if(typeOfRoomWithRoomIds){
                const avlRoomsForProviderReservations = _avlRooms.filter(room => {
                    //nadji sobe koje su istog tipa kao i soba na kojoj je trenutna rez
                    const roomId = room.id; 
                    return typeOfRoomWithRoomIds.includes(roomId);
                  });
                setAvlRooms(avlRoomsForProviderReservations);
            }else{
                setAvlRooms(_avlRooms);
            }
           

            const _roomAttrIds: number[] = [];
            _avlRooms.forEach((r) => {
                if (r.roomAttributes) {
                    const attr = JSON.parse(r.roomAttributes);
                    attr.forEach((a: number) => {
                        if (!_roomAttrIds.includes(a)) {
                            _roomAttrIds.push(a);
                        }
                    });
                }
            });
            const _roomAttrObj: any[] = [];
            _roomAttrIds.forEach((r) => {
                const attribute = attributes.find((a: any) => a.id === r);
                if (attribute) {
                    _roomAttrObj.push(attribute);
                }
            });
            setRoomAttr([_roomAttrObj]);
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [checkInTimestamp, checkOutTimestamp, reservation.roomInfoId]);

    const showChangePricelistModal = (room: RoomInfoModel) => {
        setModalContent(
            <div className="container-fluid">
                <div className="text-center">
                    {translate('Please select?')}
                    <hr />
                </div>
                <div className="text-center">
                    {translate('Do you want to use same pricelist on new room or select new one?')}
                    <hr />
                </div>
                <div className="text-center">
                    <ConfirmButtons
                        onConfirm={() => {
                            ctrl.updateActiveReservationRoomInfo(room);
                            closeModal();
                        }}
                        onCancel={() => {
                            ctrl.updateActiveReservationRoomInfo(room);
                            closeModal();
                        }}
                        confirmText="Keep pricelist"
                        cancelText="Change pricelist"
                    />
                </div>
            </div>,
            '', // header
            false, // showCloseButton
            'modal-md'
        );
    };

    const selectTagFilter = (_selectedTag: string | null) => {
        if (_selectedTag === selectedTag || _selectedTag === null) {
            setSelectedTag('');
        } else {
            setSelectedTag(_selectedTag);
        }
    };

    const selectAttributeFIlter = (values: any[] | null) => {
        if (values === null) {
            setAttribute([]);
        } else {
            const attrFilter = values.map((v) => v);
            setAttribute(attrFilter);
        }
    };

    const filterRoomsByAttribute = (availableRooms: RoomInfoModel[]) => {
        let filteredRooms = availableRooms.map((r: any) => {
            if (selectedAttribute.length > 0) {
                let eject = false;
                selectedAttribute.forEach((att) => {
                    if (
                        (r.roomAttributes && !JSON.parse(r.roomAttributes).includes(att.value)) ||
                        r.roomAttributes === null
                    ) {
                        eject = true;
                        return true;
                    }
                });
                if (eject) {
                    return null;
                } else {
                    return r;
                }
            } else {
                return r;
            }
        });
        filteredRooms = filteredRooms.filter((i) => {
            return i !== null;
        });
        return filteredRooms;
    };

    const tags = _.uniq(
        _.flatten(
            _.filter(
                rooms.map((r: RoomInfoModel) => {
                    if (r.tags) {
                        return r.tags.split(';');
                    } else {
                        return null;
                    }
                }),
                null
            )
        )
    );

    let availableRooms = [...avlRooms];

    if (selectedTag && selectedTag.length > 0) {
        availableRooms = availableRooms.filter((r) => {
            if (r.tags && r.tags.includes(selectedTag)) {
                return r;
            }
            return null;
        });
    }

    if (selectedAttribute.length > 0) {
        availableRooms = filterRoomsByAttribute(availableRooms);
    }

    if (numberOfBeds !== '' && numberOfBeds !== null) {
        availableRooms = availableRooms.filter((r: RoomInfoModel) => {
            return r.bedCount === Number(numberOfBeds);
        });
    }

    //now eject all rooms which are in current state object of controller (pending reservations, not saved)
    const takenRoomInfoIds: number[] = ctrl.getReservations().map((r) => r.roomInfoId);
    availableRooms = availableRooms.filter((room: RoomInfoModel) => {
        if (room.id) {
            return !takenRoomInfoIds.includes(room.id);
        } else {
            return false;
        }
    });

    const tagsOptions = tags.map((tag: any) => {
        return {
            value: tag,
            label: tag,
        };
    });

    const tagsFilter = (
        <div className=" mt-2 display-flex">
            <div className="">
                <input
                    type="number"
                    style={{ width: '60px' }}
                    className=" mr-1 form-control form-control-sm d-inline"
                    value={numberOfBeds}
                    min={0}
                    onChange={(e) => {
                        setNumberOfBeds(e.target.value);
                    }}
                />
                <small>
                    <i className="fa fa-bed" />
                </small>
            </div>
            <div className="mx-2">
                <CustomSelect
                    isMulti
                    placeholder={translate('By tag')}
                    styles={customStyles}
                    options={tagsOptions}
                    onChange={(values: any) => {
                        if (values === null) {
                            selectTagFilter(null);
                        } else {
                            selectTagFilter(values[values.length - 1].value);
                        }
                    }}
                    value={selectedTag !== '' ? [{ value: selectedTag, label: selectedTag }] : []}
                ></CustomSelect>
            </div>
        </div>
    );

    const attrOptions = roomAttr.map((attr: any) => {
        return {
            value: attr.id,
            label: translate(attr.name),
        };
    });

    const attrValues = selectedAttribute.map((attr: any) => {
        return {
            value: attr.value,
            label: translate(attr.label),
        };
    });

    const attributesFilter = (
        <CustomSelect
            isMulti
            placeholder={translate('By attributes')}
            styles={customStyles}
            options={attrOptions}
            className=""
            onChange={(values: any) => {
                if (values === null) {
                    selectAttributeFIlter(null);
                } else {
                    selectAttributeFIlter(values);
                }
            }}
            value={attrValues}
            width="auto"
        ></CustomSelect>
    );

    return (
        <div>
            {translate('Current room attributes')}
            <div className="row">
                <div className="col-12 d-inline-flex">
                    <small>
                        {currentRoomAttr.map((r: any, key: number) => {
                            return (
                                <div key={key} className="p-1 btn-outline-info d-inline-flex">
                                    {translate(r)},{' '}
                                </div>
                            );
                        })}
                    </small>
                </div>
            </div>
            {translate('Current room tags')}
            <div className="row">
                <div className="col-12 d-inline-flex">
                    <small>
                        {currentRoomTags.map((t: any, key: number) => {
                            return (
                                <div key={key} className="p-1 btn-outline-info d-inline-flex">
                                    {translate(t)},{' '}
                                </div>
                            );
                        })}
                    </small>
                </div>
            </div>
            <br />
            {translate('Available rooms')}
            <div className="row">
                <div className="col-6">
                    <small>
                        <div className="p-1 uppercase">
                            <b>{translate('Check in')}:</b>
                        </div>
                        {checkInTimestamp ? (
                            <DateTimeSelector
                                disabledDays={{
                                    before: new Date(),
                                }}
                                dateTimePickHandler={(timestamp: number) => {
                                    setCheckInTimestamp(timestamp);
                                }}
                                value={checkInTimestamp}
                            />
                        ) : null}
                    </small>
                </div>
                <div className="col-6 ">
                    <small>
                        <div className="p-1 uppercase">
                            <b>{translate('Check out')}:</b>
                        </div>
                        {checkOutTimestamp ? (
                            <DateTimeSelector
                                disabledDays={{
                                    before: new Date(),
                                }}
                                dateTimePickHandler={(timestamp: number) => {
                                    setCheckOutTimestamp(timestamp);
                                }}
                                value={checkOutTimestamp}
                            />
                        ) : null}
                    </small>
                </div>
            </div>
            {tagsFilter}
            <div className="mt-2">{attributesFilter}</div>
            <div className="custom-list-wrapper">
                <div className="custom-list-header">
                    <div style={{ width: '100px' }}> {translate('Name')} </div>
                    <div style={{ width: '50px' }}>
                        {' '}
                        <i className="fa fa-bed" />{' '}
                    </div>
                    <div style={{ width: '210px' }}>
                        {' '}
                        <i className="fa fa-tag" />{' '}
                    </div>
                    <div style={{ width: '190px' }}>{translate('Attributes')}</div>
                    <div style={{ width: '80px' }}>
                        {' '}
                        <i className="fa fa-bolt" />{' '}
                    </div>
                </div>

                {availableRooms && availableRooms.length > 0 ? availableRooms.map((room: RoomInfoModel, index: number) => {
                    const spareBedCount = room?.spareBedCount ? room.spareBedCount : 0;
                    const roomAttr: any[] = [];
                    if (room.roomAttributes) {
                        JSON.parse(room.roomAttributes).forEach((r: any) => {
                            const attr = attributes.find((a: any) => a.id === r);
                            if (attr) {
                                roomAttr.push(attr.name);
                            }
                        });
                    }

                    return (
                        <div key={index} className="custom-list-item">
                            <div style={{ width: '100px' }}> {room.name} </div>
                            <div style={{ width: '50px' }}>
                                {' '}
                                {room.bedCount}
                                {spareBedCount > 0 ? '+' + spareBedCount : null}{' '}
                            </div>
                            <div className="truncated" style={{ width: '210px' }}>
                                {room.tags}{' '}
                            </div>
                            <div className="truncated" style={{ width: '190px' }}>
                                <RoomAttributes room={room} attributes={attributes} />
                            </div>
                            <div style={{ width: '80px' }}>
                                <button
                                    onClick={() => {
                                        if (props.changeRoomHandler) {
                                            props.changeRoomHandler(room);
                                            closeModal();
                                        } else {
                                            if (reservation.accommodationPriceListId) {
                                                showChangePricelistModal(room);
                                            } else {
                                                ctrl.updateActiveReservationRoomInfo(room);
                                                closeModal();
                                            }
                                        }
                                    }}
                                    data-dismiss="modal"
                                    className="button-primary pull-right"
                                >
                                    {translate('SELECT')}
                                </button>
                            </div>
                        </div>
                    );
                }) : reservation && reservation.providerReservation ? <div>{translate("The reservation is from one of the booking channels, and you can transfer it only if you have a room that is of the same type as this room.")}</div> : ""}
            </div>
        </div>
    );
}

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        attributes: ['model', 'RoomAttribute'],
    },
    ShowAvailableRoomsForReservationModal
);
