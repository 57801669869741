// import sideMenuItems from './sideMenuItems'
import React  from "react";
import {ReactComponent as Home} from "../../assets/custom/icons/home.svg";
import {ReactComponent as PropertyManagement} from "../../assets/custom/icons/propertyManagement.svg";
import {ReactComponent as Reports} from "../../assets/custom/icons/reports.svg";
import {ReactComponent as RoomsAndHardware} from "../../assets/custom/icons/hardware.svg";
import {ReactComponent as Database} from "../../assets/custom/icons/database.svg";
import {ReactComponent as AdvancedSettings} from "../../assets/custom/icons/settings_cog.svg";
import {ReactComponent as Logout} from "../../assets/custom/icons/sign_out.svg";

import baobabTree from "../../data/state"
import _ from "lodash"
import { logout } from '../../data/actions';

function logMeOut () {
    logout()
}

function toggleMainViewButtons (mainViewType) {
    
    let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
    if (_.includes(temp, 'timeline')) {
        _.remove(temp, (t) => t === 'timeline');
    }
    if (_.includes(temp, 'tiles')) {
        _.remove(temp, (t) => t === 'tiles');
    }
    if (_.includes(temp, 'activity')) {
        _.remove(temp, (t) => t === 'activity');
    }
    if (_.includes(temp, 'journal')) {
        _.remove(temp, (t) => t === 'journal');
    }
    if (_.includes(temp, 'occupancy')) {
        _.remove(temp, (t) => t === 'occupancy');

    }
// --   OVO OVDJE PROMIJENITI JOPA_-----
    if(mainViewType === "occupancy"){
        temp.push("sidebar_dailyInfo")
    }
    
    temp.push(mainViewType);
    baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    // setTimeout(() => {
    //     clearReservationsFilterFields();
    // }, 10);
}

export default {
    home: {
        id:"home",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Home',
        label: 'Home',
        icon: <Home/>,
        items:null,
        link:"/?roomsOnPrem",
        // whenClicked:()=>{toggleMainViewButtons("timeline")},
        whenClicked:()=>{toggleMainViewButtons("tiles")},
        description:"Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services."
    },

    dashboard: {
        id:"dashboard",
        permissionCheckName: "dashboard",
        featureFlags: ["onPrem"],
        fullLabel: 'Property Management',
        label: 'Rooms',
        icon: <PropertyManagement/>,
        items: [
            { itemLabel: 'Rooms', link:  '/?rooms', permissionCheckName:"pricePanel_accomodation", featureFlags:["onPrem"], whenClicked:()=>{toggleMainViewButtons("tiles")} },
            { itemLabel: 'Situation center', link:  '/allRoomEvents', permissionCheckName:"situationCenter", featureFlags:["onPrem"], whenClicked:()=>{toggleMainViewButtons("situationCenter")} },
        ],
        // link:"/",
        description:"Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms."
    },

    dataFolders: {
        id:"dataFolders",
        permissionCheckName: "foldersPanel",
        featureFlags: ["onPrem"],
        fullLabel: 'Database',
        label: 'Data',
        icon: <Database/>,
        items: [
            { itemLabel: 'Users', link: '/users', permissionCheckName:"advancedSettingsPanel_users", featureFlags:["onPrem"] },
            { itemLabel: 'Rooms', link: '/roomsAdministration', permissionCheckName:"roomsPanel_rooms", featureFlags:["onPrem"] },
          
        ],
        description:"Browse and create customers and companies required by reservation system."
    },

    roomsAndHardware: {
        id:"roomsAndHardware",
        permissionCheckName: "roomsPanel",
        featureFlags: ["onPrem"],
        fullLabel: 'Hardware',
        label: 'Rooms',
        icon: <RoomsAndHardware/>,
        items: [
            { itemLabel: 'SOS groups', link: '/sosGroups', permissionCheckName:"roomsPanel_sosGroups", featureFlags:["onPrem"] },
            { itemLabel: 'Controllers', link: '/srcProxies', permissionCheckName:"roomsPanel_controllers", featureFlags:["onPrem"] },
            { itemLabel: 'SRC templates', link: '/srcTemplates', permissionCheckName:"roomsPanel_SRCTemplates", featureFlags:["onPrem"] },
            { itemLabel: 'Alarm settings', link: '/alarmAndRoomModeInterpreter', permissionCheckName:"roomsPanel_AlarmAndRoomMode", featureFlags:["onPrem"] },
            { itemLabel: 'Doors & rules', link: '/accessControl', permissionCheckName:"accessControlPanel_doorsAndRules", featureFlags:["onPrem"] },
            { itemLabel: 'Whitelists', link: '/whiteLists', permissionCheckName:"accessControlPanel_whitelists", featureFlags:["onPrem"] },
            { itemLabel: 'Document Scanners', link: '/docScannerDevices', permissionCheckName:"accessControlPanel_documentScannerDevices", featureFlags:["onPrem"] },
        ],
        description:"Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms."
    },
    reports: {
        id:"reports",
        permissionCheckName: "reports",
        featureFlags: ["onPrem"],
        fullLabel: 'Reports',
        label: 'Reports',
        icon: <Reports/>,
        items:null,
        link:"/menu/reports",
        description:"Setup language, company informations, currency and modems configuration. Manage API keys."
    },

    
    advancedSettings: {
        id:"advancedSettings",
        permissionCheckName: "advancedSettingsPanel",
        featureFlags: ["onPrem"],
        fullLabel: 'Config',
        label: 'Config',
        icon: <AdvancedSettings/>,
        items: [
            { itemLabel: 'Settings', link: '/generalSettings', permissionCheckName:"advancedSettingsPanel_settings", featureFlags:["onPrem"] },
            { itemLabel: 'Notification service settings', link: '/notificationTemplateSettings', permissionCheckName:"advancedSettingsPanel_notificationServiceSettings", featureFlags:["onPrem"] },
            { itemLabel: 'Visa Info', link: '/visaInfo', permissionCheckName:"advancedSettingsPanel_visaInfo", featureFlags:["onPrem"] },
            { itemLabel: 'Self check in administration', link: '/selfCheckInAdministrative', permissionCheckName:"advancedSettingsPanel_selfCheckInAdministration", featureFlags:["onPrem"] },
            { itemLabel: 'Licence', link: '/licence', permissionCheckName:"licencePanel_licence", featureFlags:["onPrem"] },
            { itemLabel: 'Help and support', link: '/help', permissionCheckName:"advancedSettingsPanel", featureFlags:["onPrem"] },
        ],
        description:"Setup language, company informations, currency and modems configuration. Manage API keys."
    },

    logout: {
        id:"logout",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Log Out',
        label: 'Log out',
        icon: <Logout/>,
        whenClicked:()=>{logMeOut()},
        // items: [],
        description:"All business conclusions in one place."
    },
};