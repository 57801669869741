import baobabTree from '../state/index';
import _ from 'lodash';
var handleSRCSettingsPayload = (payloadRaw) => {
    let roomInfo = _.clone(
        baobabTree.root.select(['model', 'RoomInfo', { remoteDeviceId: payloadRaw.remoteDeviceId }]).get()
    ); //.set({SRCSettings:payloadRaw.SRCSettings})
    roomInfo.SRCSettings = payloadRaw.SRCSettings;
    baobabTree.root.select(['model', 'RoomInfo', { remoteDeviceId: payloadRaw.remoteDeviceId }]).set(roomInfo);
};

export { handleSRCSettingsPayload };
