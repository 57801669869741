import React from 'react';
//@ts-ignore
import baobab from '../../data/state';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import './globalLoading.css';

var timeoutHolder:any = null;

function GlobalLoadingComponent({loading = 0}) {
    const [actionTimeoutNotification, setActionTimeoutNotification] = React.useState(false);

    const translate:any = (window as any).translate

    if (loading === 0) {
        clearTimeout(timeoutHolder);
    } else {
        clearTimeout(timeoutHolder);
        timeoutHolder = setTimeout(() => {
            setActionTimeoutNotification(true);
        }, 5000);
    }

    if (loading > 0) {
        return (
            <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                className="loader-cover-spin"
            >
                {actionTimeoutNotification && (
                    <div
                        className="border rounded shadow bg-white p-4"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <div className="text-secondary p-2 text-center">
                            {translate('reloadPageNote1')} <br />
                            {translate('reloadPageNote2')} <br />
                            {translate('reloadPageNote3')}
                        </div>

                        <div className="text-center mt-3">
                            <button
                                onClick={() => {
                                    setActionTimeoutNotification(false);
                                    window.location.reload();
                                }}
                                className="btn btn-outline-secondary p-2 "
                            >
                                {translate('Reload page')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
}

export function startGlobalLoading() {
    baobab.root.select('state', 'globalLoading').apply((prev:number) => prev + 1);
}

export function stopGlobalLoading() {

    baobab.root.select('state', 'globalLoading').apply((prev: number) => prev - 1);
}

const GlobalLoading = branch(
    {
        loading: ['state', 'globalLoading'],
    },
    GlobalLoadingComponent
);

export {GlobalLoading};

