import React from 'react';
import { withRouter } from 'react-router';

const AlertToastTemplate = (props)=>{
    const {className="", history, roomInfo, toastId, alarmName} = props;

    return (
            <div className={className}
                onClick={() => {
                    history.push(
                        '/roomInfo/' +
                            roomInfo.id +
                            '/' +
                            roomInfo.remoteDeviceId +
                            '?situationResponder&toastId=' +
                            toastId
                    );
                }}
            >
                <h5>{roomInfo.name}</h5>
                {alarmName}
            </div>
        );


}

export default withRouter(AlertToastTemplate);
