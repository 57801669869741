import {
    UserInfo,
    ReservationEditModel,
    CleaningStatusExtendedWithIncludedModels,
    CleaningStatusHeaderExtendedWithCleaningStatusModel,
} from '@common/modelDefinition';
//@ts-ignore
import { RouteComponentProps } from 'react-router-dom';

// import { translate } from '@data/translations';

export interface ActiveRoomEvent {
    roomInfoId: number;
    ruleName: string;
    id: number;
    openingTimestamp: number;
    closingTimestamp: number | undefined;
    reservationId: number | undefined;
    customerId: number | undefined;
    openingRoomIOChangesRawId: number | undefined;
    openingRoomEventRawId: number | undefined;
    closingRoomIOChangesRawId: number | undefined;
    closingRoomEventRawId: number | undefined;
}
export interface HouseholdDashboardProps {
    // buttonPermissions:string[];
    // rooms: RoomInfoEditModel[];

    loggedUser: UserInfo;
    history: RouteComponentProps['history'];
    cleaningStatusHeaders: CleaningStatusHeaderExtendedWithCleaningStatusModel[];
}

export interface RoomInfoStatusProps extends HouseholdDashboardProps {
    reservations: ReservationEditModel[];
    activeRoomEvents: ActiveRoomEvent[];
    cleaningStatus: CleaningStatusExtendedWithIncludedModels;
    showCleaningOptions: Function;
}

export interface StatusFilterToolbarProps {
    selectedHeaderId: number;
    activeFilters: string[];
    setActiveFilters: Function;
    setChangeMaidPassword: Function;
    history: RouteComponentProps['history'];
    changeMaidPassword: boolean;
    cleaningStatuses: CleaningStatusExtendedWithIncludedModels[];
}

export interface StatusInfosProps {
    cleaningRequest: ActiveRoomEvent | undefined;
    cleaningStatus: CleaningStatusExtendedWithIncludedModels;
}

export interface UpdateCleaningStatusProps {
    cleaningStatus: CleaningStatusExtendedWithIncludedModels;
    onMinibarManagementClick: Function;
    onMinibarClick: Function;
    loggedUser: UserInfo;
    setCleaningStatus:Function
}

enum CleaningStatusEnum {
    noActionTaken,
    cleaningRequested,
    stagedForCleaning,
    cleaningStarted,
    cleaningFinished,
    cleaningPostponed,
    cleaningSkipped,
}

const CleaningStatusColors = {
    noActionTaken: {
        color: 'black',
        backgroundColor: '#fff',
    },
    cleaningRequested: {
        color: 'white',
        backgroundColor: '#F8333C',
    },
    stagedForCleaning: {
        color: 'white',
        backgroundColor: '#FF6201',
    },
    cleaningStarted: {
        color: 'white',
        backgroundColor: '#9101FF',
    },
    cleaningFinished: {
        color: 'white',
        backgroundColor: '#00CC66',
    },
    cleaningPostponed: {
        color: 'white',
        backgroundColor: '#F8333C',
    },
    cleaningSkipped: {
        color: 'white',
        backgroundColor: '#04befe',
    },
};

enum CleaningStatusIcons {
    noActionTaken = ' fa fa-circle-thin ',
    cleaningRequested = ' fa fa-bell ',
    stagedForCleaning = ' fa fa-thumbs-up ',
    cleaningStarted = ' fa fa-street-view ',
    cleaningFinished = ' fa fa-trophy ',
    cleaningPostponed = ' fa fa-rotate-left ',
    cleaningSkipped = ' fa fa-step-forward ',
}

enum CleaningStatusTexts {
    noActionTaken = 'cl_noActionTaken',
    cleaningRequested = 'cl_cleaningRequested',
    stagedForCleaning = 'cl_stagedForCleaning',
    cleaningStarted = 'cl_cleaningStarted',
    cleaningFinished = 'cl_cleaningFinished',
    cleaningPostponed = 'cl_cleaningPostponed',
    cleaningSkipped = 'cl_cleaningSkipped',
}

enum ReservationStatusEnum {
    unknown,
    noReservation,
    arrival,
    dueOut,
    stay,
}

enum ReservationStatusText {
    unknown = 'Unknown',
    noReservation = 'No reservations',
    arrival = 'Guest arrival',
    dueOut = 'Due out',
    stay = 'Stay',
}

export enum CleaningInspectionEnum {
    noInspectionTaken,
    cleaningInspectionRequired, // integrirati na način da se generira automatski, nakon što proces obavi 'rookie' maid. Household manager ili recepcionar također treba biti u mogućnosti ručno podesiti ovaj zadatak, te ga dodijeliti novoj sobarici.
    cleaningInspectionStarted,
    cleaningInspectionFailed, //if failed move to -> stagedForCleaning or cleaningRequested
    cleaningInspectionPassed, //just info
}

const InspectionStatusColors = {
    noInspectionTaken: {
        color: 'black',
        backgroundColor: '#fff',
    },
    cleaningInspectionRequired: {
        color: 'white',
        backgroundColor: '#2a78f5',
    },
    cleaningInspectionStarted: {
        color: 'white',
        backgroundColor: '#18ad95',
    },
    cleaningInspectionFailed: {
        color: 'white',
        backgroundColor: '#F8333C',
    },
    cleaningInspectionPassed: {
        color: 'white',
        backgroundColor: '#00CC66',
    },
};

export enum CleaningInspectionColor {
    noInspectionTaken = '#fff',
    cleaningInspectionRequired = '#2a78f5',
    cleaningInspectionStarted = '#18ad95',
    cleaningInspectionFailed = '#F8333C',
    cleaningInspectionPassed = '#00CC66',
}

enum CleaningInspectionIcons {
    noInspectionTaken = ' fa fa-circle-thin ',
    cleaningInspectionRequired = ' fa fa-bell ',
    cleaningInspectionStarted = ' fa fa-street-view ',
    cleaningInspectionFailed = ' fa fa-thumbs-down ',
    cleaningInspectionPassed = ' fa fa-thumbs-up ',
}

enum MaidExpirence {
    'Rookie' = 1,
    'Intermediate' = 2,
    'Experienced' = 3,
}

export {
    CleaningStatusEnum,
    CleaningStatusColors,
    CleaningStatusIcons,
    CleaningStatusTexts,
    ReservationStatusEnum,
    ReservationStatusText,
    InspectionStatusColors,
    CleaningInspectionIcons,
    MaidExpirence,
};
