import {
    //  InvoiceModel,
    UserInfo,
    InvoiceModelWithInvoiceItems,
    InvoiceConclusionModel,
} from '@common/modelDefinition';

import rest from '@data/restWrapper';




export async function getUsers(): Promise<UserInfo[]> {
    return await rest('/getPagedUsers?page=' + 0 + '&rows=' + 500, 'GET');
}




export async function getLatestPosTerminalInvoices(posTerminalId: number) {
    return await rest('/getLatestPosTerminalInvoices/' + posTerminalId);
}

export async function searchInvoice(searchString: string): Promise<InvoiceModelWithInvoiceItems[]> {
    const res = await rest(`/searchInvoice?search=${searchString}`);
    // const newRes = res.filter((r: InvoiceModelWithInvoiceItems) => r.terminalType === 'posTerminal');
    return res;
}

export async function getInvoiceReconciliation(posTerminalId: number, reportType?:string) {
    const res = await rest('/getInvoiceReconciliation' , 'POST', { posTerminalId, reportType });
    return res;
}
export async function createInvoiceConclusion(invoiceConclusion: InvoiceConclusionModel, reportType?:string) {
    const res = await rest('/createInvoiceConclusion', 'POST', { invoiceConclusion, reportType });

    return res;
}
export async function getInvoiceConclusions(posTerminalId: number, from: number, to: number) {
    const res = await rest('/getInvoiceConclusions/' + posTerminalId + '?from=' + from + '&to=' + to);

    return res;
}

interface GetInvoiceConclusionDetailsProps{
    invoiceConclusionId?:number
    posTerminalId:number,
    group:string
}


interface GetBartendersReportProps{
    invoiceConclusionId?:number
    posTerminalId:number
    bartenderUuid?:string
}

export async function getInvoiceConclusionDetails(props: GetInvoiceConclusionDetailsProps) {
    const { invoiceConclusionId, posTerminalId, group } = props;
    const res = await rest('/getInvoiceConclusionDetails', 'POST', { invoiceConclusionId, posTerminalId, group });

    return res;
}

export async function getFinancialJournalDetails(props: any) {
    const { group, from, to, todaysDateOrSelectedDay } = props;
    const res = await rest('/getFinancialJournalDetails', 'POST', { group, from, to, todaysDateOrSelectedDay });
    return res;
}

export async function getBartendersReport(props: GetBartendersReportProps) {
    const { invoiceConclusionId, posTerminalId, bartenderUuid } = props;
    const res = await rest('/pos/getBartendersReport', 'POST', { invoiceConclusionId, posTerminalId, bartenderUuid });

    return res;
}

export async function updateInvoiceConslusion(invoiceConslusion: InvoiceConclusionModel) {
    await rest('/InvoiceConclusion/' + invoiceConslusion.id, 'PUT', invoiceConslusion);
}
