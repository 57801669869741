import * as React from 'react';
import { translate } from '@data/translations';
import { GroupReservation, Reservation, ReservationController } from '../reservations2/resController';
import moment from 'moment';
import { reservationStatusColors, reservationStatusEnumsDesc } from '@data/reservationStatusEnums';
import { GroupInfoStatus, ReservationStatus, UserInfo } from '@common/modelDefinition';
import FolderBox from '@components/documentTools/folderBox';
import rest from '@data/restWrapper';
import { toast } from 'react-toastify';
import { ReactComponent as Close } from '../../assets/custom/icons/ex.svg';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import ReservationPrintDetails from '@pages/reservations2/reservationPrintDetails';
//@ts-ignore
import _ from 'lodash';
import { invoicedStatusEnum } from '@common/enums';

interface ReservationDetailsProps {
    reservationId: number;
    loggedUser: UserInfo;
    mousePosition: any;
    setShowDetails: Function;
    setEditReservation: Function;
    history?: any;
    setPrintPreview?: Function;
    groupUuid?: string;
}

export enum DocumentTypeEnum {
    'P' = 'Passport',
    'I' = 'Identity card',
    'V' = 'Visa',
    'D' = 'Driving licence',
    'X' = 'Other',
}

function ReservationDetails(props: ReservationDetailsProps) {
    const {
        history,
        reservationId,
        loggedUser,
        mousePosition,
        setShowDetails,
        setEditReservation,
        setPrintPreview,
        groupUuid,
    } = props;
    const [printView, setPrintView] = React.useState(false);
    const [reservation, setReservation] = React.useState({} as Reservation);
    const [groupReservation, setGroupReservation] = React.useState(null as GroupReservation | null);

    const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
    const y_size =
        windowSize.current[0] < 1200
            ? mousePosition.y + 100
            : windowSize.current[1] < 820
            ? windowSize.current[1] - (windowSize.current[1] - 150)
            : windowSize.current[1] - (windowSize.current[1] - 250);
    const x_size =
        windowSize.current[0] < 700
            ? 0
            : windowSize.current[0] >= 700 && windowSize.current[0] < 1200
            ? mousePosition.x - 15
            : windowSize.current[0] >= 1200 && windowSize.current[0] < 1450
            ? windowSize.current[0] - (windowSize.current[0] - 550)
            : windowSize.current[0] >= 1450 && windowSize.current[0] < 1650
            ? windowSize.current[0] - (windowSize.current[0] - 700)
            : windowSize.current[0] - (windowSize.current[0] - 900);
    const [x, setX] = React.useState(x_size);
    const [y, setY] = React.useState(y_size);

    React.useEffect(() => {
        window.addEventListener('beforeprint', () => {
            setPrintView(true);
            if (setPrintPreview) {
                setPrintPreview(true);
            }
        });

        return () => {
            window.removeEventListener('beforeprint', () => {
                return;
            });
        };
        // eslint-disable-next-line
    }, [printView]);

    React.useEffect(() => {
        window.addEventListener('afterprint', () => {
            setPrintView(false);
            if (setPrintPreview) {
                setPrintPreview(false);
            }
        });
        return () => {
            window.removeEventListener('afterprint', () => {
                return;
            });
        };
        // eslint-disable-next-line
    }, [printView]);

    React.useEffect(() => {
        const ctrl2 = new ReservationController();
        const loadData = async () => {
            if (reservationId) {
                const _reservation = await ctrl2.loadSingleReservation(reservationId);
                if (_reservation) {
                    //@ts-ignore
                    setReservation(_reservation);
                }
            } else if (groupUuid) {
                await ctrl2.loadGroup(groupUuid);
                const _groupRes = ctrl2.getGroupReservation();
                setGroupReservation(_groupRes);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });

        return () => {
            ctrl2.clearAll(); //cleanup on unmount
        };
        // eslint-disable-next-line
    }, [reservationId, groupUuid]);

    const handleDragEnd = (event: any) => {
        setX(event.clientX);
        setY(event.clientY);
    };

    const saveNotesUpdate = async (reservationNotes: string) => {
        try {
            if (reservation) {
                let payload = {
                    id: reservation.id,
                    roomInfoId: reservation.roomInfoId,
                    notes: reservationNotes,
                    checkInTimestamp: reservation.checkInTimestamp,
                    checkOutTimestamp: reservation.checkOutTimestamp,
                    accommodationPriceListId: reservation.accommodationPriceListId,
                    adultsNumber: reservation.adultsNumber,
                    statusEnum: reservation.statusEnum,
                    childrenNumber: reservation.childrenNumber,
                };
                await rest('/safeSaveOrUpdateReservationModel', 'POST', payload);
                const newReservation = { ...reservation };
                newReservation.notes = reservationNotes;
                setReservation(newReservation);
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const holderVisaInfo = reservation?.VisaInfo?.find((r) => r.customerId === reservation?.Customer?.id);
    const documentTypeString: string = holderVisaInfo?.documentType ? holderVisaInfo.documentType : 'X';
    return !printView ? (
        <div
            draggable
            onDragEnd={handleDragEnd}
            className="reservation-form border shadow-sm"
            style={{ top: y, left: x }}
        >
            <div className="reservation-form-header">
                <div className="reservation-form-title">
                    {groupReservation
                        ? translate('Group reservation details')
                        : '#' + reservation?.id + ' - ' + translate('Reservation Details')}
                </div>
                <div className="close">
                    <Close style={{ cursor: 'pointer' }} onClick={() => setShowDetails(false)} />
                </div>
            </div>
            <br />

            {groupReservation ? (
                <div className="mt-8p px-4" style={{ minHeight: '500px' }}>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <div className="" style={{ width: '32px' }}>
                                    <i className="fa fa-building " aria-hidden="true"></i>
                                </div>
                            </div>
                            {translate('Group')}:
                        </div>
                        <div className="text-bold">{groupReservation?.groupName}</div>
                    </div>
                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-user mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Group holder')}:
                        </div>
                        <div className="">
                            {groupReservation?.Customer
                                ? groupReservation?.Customer?.firstName + ' ' + groupReservation?.Customer?.lastName
                                : groupReservation?.Company
                                ? groupReservation?.Company?.name
                                : ''}
                        </div>
                    </div>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-calendar-check-o mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Check in')}:
                        </div>
                        <div className="display-flex">
                            <div className="mb-2 text-bold">
                                {groupReservation?.checkInTimestamp
                                    ? moment(groupReservation?.checkInTimestamp).format('lll')
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-sign-out mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Check out')}:
                        </div>
                        <div className="display-flex">
                            <div className="mb-2 text-bold">
                                {groupReservation?.checkOutTimestamp
                                    ? moment(groupReservation?.checkOutTimestamp).format('lll')
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className="reservation-details-row ">
                        <div className="display-flex mr-16p">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-calendar-check-o mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Rooms')}:
                        </div>
                        <div className="display-flex text-bold" style={{ textAlign: 'right' }}>
                            {groupReservation?.Reservations?.map((g) => {
                                //@ts-ignore
                                if (g.RoomInfo) {
                                    //@ts-ignore
                                    return g.RoomInfo?.name + ', ';
                                } else {
                                    return '';
                                }
                            })}
                        </div>
                    </div>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <div className="" style={{ width: '32px' }}>
                                    <i className="fa fa-hotel " aria-hidden="true"></i>
                                </div>
                            </div>
                            {translate('Group status')}:
                        </div>
                        <div className={'text-bold ' + (groupReservation?.status ? ' color-success ' : 'color-error')}>
                            {translate(GroupInfoStatus[groupReservation?.status])}
                        </div>
                    </div>
                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-building mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Payment status')}:
                        </div>
                        <div className="">{translate(invoicedStatusEnum[groupReservation?.invoicedStatus])}</div>
                    </div>
                </div>
            ) : (
                <div className="mt-8p px-4" style={{ minHeight: '500px' }}>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <div className="" style={{ width: '32px' }}>
                                    <i className="fa fa-hotel " aria-hidden="true"></i>
                                </div>
                            </div>
                            {translate('Room')}:
                        </div>
                        <div className="text-bold">{reservation?.RoomInfo?.name}</div>
                    </div>
                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-building mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Company Name')}:
                        </div>
                        <div className="">{reservation?.Company?.name}</div>
                    </div>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-user mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Reservation holder')}:
                        </div>
                        <div className="display-flex text-bold">
                            {(reservation?.Customer?.firstName ? reservation?.Customer?.firstName : ' ') +
                                ' ' +
                                (reservation?.Customer?.lastName ? reservation?.Customer?.lastName : ' ')}
                        </div>
                    </div>

                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-user mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Personal document id')}:
                        </div>
                        <div className="display-flex text-bold">
                            {holderVisaInfo?.documentId
                                ? `${holderVisaInfo.documentId} - ${translate(
                                      DocumentTypeEnum[documentTypeString as keyof typeof DocumentTypeEnum]
                                  )}`
                                : ''}
                        </div>
                    </div>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-user mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Visa Type And Number')}:
                        </div>
                        <div className="display-flex text-bold">
                            {holderVisaInfo?.visaTypeAndNumber ? holderVisaInfo.visaTypeAndNumber : ' '}
                        </div>
                    </div>
                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-user mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Visa Validity Period')}:
                        </div>
                        <div className="display-flex text-bold">
                            {holderVisaInfo?.visaValidityPeriod ? holderVisaInfo.visaValidityPeriod : ' '}
                        </div>
                    </div>

                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-users mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Guests')}:{' '}
                        </div>
                        <div className="display-flex text-bold">
                            {reservation?.Guests?.map((g) => {
                                if (g.id !== reservation?.customerId) {
                                    return g.firstName + ' ' + g.lastName + ', ';
                                } else {
                                    return '';
                                }
                            })}
                        </div>
                    </div>

                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-calendar-check-o mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Check in')}:
                        </div>
                        <div className="display-flex">
                            <div className="mb-2 text-bold">{moment(reservation?.checkInTimestamp).format('lll')}</div>
                        </div>
                    </div>

                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-sign-out mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Check out')}:
                        </div>
                        <div className="display-flex">
                            <div className="mb-2 text-bold">{moment(reservation?.checkOutTimestamp).format('lll')}</div>
                        </div>
                    </div>

                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-bed mr-8p" aria-hidden="true"></i>
                            </div>{' '}
                            {translate('Beds/Spare beds')}:
                        </div>
                        <div className="display-flex text-bold">
                            {reservation?.RoomInfo?.bedCount} / {reservation?.RoomInfo?.spareBedCount}
                        </div>
                    </div>

                    <div className="reservation-details-row " style={{ backgroundColor: '#F3F6FB' }}>
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-users mr-8p" aria-hidden="true"></i>
                            </div>{' '}
                            {translate('Adults/Children')}:
                        </div>
                        <div className="display-flex text-bold">
                            {reservation?.adultsNumber} / {reservation?.childrenNumber}
                        </div>
                    </div>

                    <div className="reservation-details-row ">
                        <div className="display-flex">
                            <div className="" style={{ width: '32px' }}>
                                <i className="fa fa-info mr-8p" aria-hidden="true"></i>
                            </div>
                            {translate('Reservation status')}:
                        </div>
                        <div className="reservation-details-row  align-items-center">
                            <div className={'text-bold ' + reservationStatusColors[reservation?.statusEnum]}>
                                <b>{reservationStatusEnumsDesc[reservation?.statusEnum]}</b>
                            </div>{' '}
                        </div>
                    </div>

                    <ReservationNotes saveNotesUpdate={saveNotesUpdate} reservation={reservation} />

                    <FolderBox
                        showDeleteButton={loggedUser.role === 'admin' || loggedUser.role === 'superAdmin'}
                        showFileUploadButton={false}
                        {...reservation}
                    />
                </div>
            )}

            <div className="display-flex align-items-center justify-content-between px-4 pb-8p">
                {' '}
                <div className="display-flex">
                    <div
                        className="button-light border pointer mr-8p"
                        onClick={() => {
                            setEditReservation(true);
                        }}
                    >
                        {groupReservation ? translate('Edit group reservation') : translate('Edit reservation')}
                    </div>
                    {reservation?.statusEnum === ReservationStatus.checkedIn ? (
                        <button
                            onClick={() => {
                                history.push('/checkoutAndInvoice/' + reservation?.id);
                            }}
                            className="button-secondary pull-right mr-2p"
                        >
                            {translate('Check out')}
                        </button>
                    ) : null}
                </div>
                <div
                    className=" pointer "
                    onClick={() => {
                        setShowDetails(false);
                    }}
                >
                    {translate('Close')}
                </div>
            </div>
        </div>
    ) : (
        <ReservationPrintDetails reservation={reservation} />
    );
}

interface ReservationNotesInterface {
    saveNotesUpdate: any;
    reservation: Reservation;
}

export const ReservationNotes = (props: ReservationNotesInterface) => {
    const [editMode, setEditMode] = React.useState(false);
    const [editingNotes, setEditingNotes] = React.useState('' as string | null | undefined);

    const { saveNotesUpdate, reservation } = props;

    const noteChangeHandler = (e: any) => {
        setEditingNotes(e.target.value);
    };

    const _saveNotesUpdate = () => {
        const val = _.clone(editingNotes);
        saveNotesUpdate(val);
        setEditMode(false);
        setEditingNotes('');
    };

    return (
        <div className="p-2" style={{ backgroundColor: '#F3F6FB' }}>
            <div className="reservation-details-row">
                <div>
                    <i className="fa fa-sticky-note-o mr-4p" aria-hidden="true"></i>
                    {translate('Notes')}:
                </div>
                {editMode ? null : (
                    <div
                        onClick={() => {
                            setEditMode(true);
                            setEditingNotes(reservation.notes);
                        }}
                        className="button-info"
                    >
                        <i className="fa fa-edit" />
                    </div>
                )}
            </div>
            <br />
            {editMode ? (
                <div>
                    <textarea
                        onChange={noteChangeHandler.bind(this)}
                        className="input input-modal"
                        value={editingNotes ? editingNotes : ''}
                    ></textarea>
                    <button
                        onClick={_saveNotesUpdate.bind(this)}
                        disabled={editingNotes === reservation.notes}
                        className="button-info mt-8p mb-4p pull-right"
                    >
                        {translate('SAVE')}
                    </button>
                </div>
            ) : (
                <i>
                    <small>{reservation?.notes}</small>
                </i>
            )}
        </div>
    );
};

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
    },
    ReservationDetails
);
