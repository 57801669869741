import React, { useState } from 'react';
import PageLoader from '@components/loaders/loader1';
import Sidebar from './sidebar';
import FeatureFlag, { isFeatureEnabled } from '@components/FeatureFlag';
import TimelineView from './reservationsTimeline';
import HwRoomView from './hwRoomView';
import Toolbar from './toolbar';
import baobabTree from '@data/state';
import ToolbarReservationMode from './toolbarReservationMode';
import SidebarToolbar from './sidebarToolbar';
import ActivityView from './activityView';
import ReservationForm from '../newReservations';
import { ReservationController } from '../reservations2/resController';
import { RoomInfoEditModel } from '@common/modelDefinition';
import { CleaningStatusHeaderExtendedWithCleaningStatusModel } from '@common/modelDefinition';
import baobab from '@data/state';
import { Reservation } from '@common/reservation';
import { UserInfo } from '@common/modelDefinition';
import translate from '@data/translations';
//@ts-ignore
import _ from 'lodash';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import JournalWithActivity from './journalWithActivity';
import ReservationDetails from '../newReservations/reservationDetails';
interface DashboardProps {
    reservations: Reservation[];
    location: any;
    history: any;
    uiStates: any;
    cleaningStatusHeaders: CleaningStatusHeaderExtendedWithCleaningStatusModel[];
    loggedUser: UserInfo;
}

function Dashboard(props: DashboardProps) {
    const [availableFromTimestamp, setAvailableFromTimestamp] = useState(Date.now() as null | number);
    const [availableToTimestamp, setAvailableToTimestamp] = useState(null as null | number);
    const [stayInDays, setStayInDays] = useState(null as null | number);
    const [groupReservations, setGroupReservations] = useState(false as boolean);
    const [searchTerm, setSearchTerm] = useState('' as string);
    const [numberOfBeds, setNumberOfBeds] = useState('' as string | number);
    const [numberOfSpareBeds, setNumberOfSpareBeds] = useState('' as string | number);
    const [tagsFilter, setTagsFilter] = useState([] as string[]);
    const [filterOutRoomsById, setFilterOutRoomsById] = useState([] as number[]);
    const [selectedRoomsForReservations, setSelectedRoomsForReservations] = useState([] as number[]);
    const [attributesFilter, setAttributesFilter] = useState([] as string[]);
    const [attributesFilteringType, setAttributesFilteringType] = useState(true as boolean);
    const [showOccupiedRooms, setShowOccupiedRooms] = useState(true as boolean);
    const [cleaningStatusesFilter, setCleaningStatusesFilter] = useState([] as number[]);
    const [showReservationsList, setShowReservationsList] = useState(false as boolean);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 } as any);
    const [reservationId, setReservationId] = useState(null as number | null);
    const [reservationUuid, setReservationUuid] = useState(null as string | null);
    const [showReservationDetails, setShowReservationDetails] = useState(null as number | null);
    const [, setPrintPreview] = React.useState(false as boolean);
    const reservations_ = props.reservations;
    const printJournal = props.location.search.lastIndexOf('printJournal') !== -1 ? true : false;

    const myRef: any = React.useRef(null);
    const executeScrollTop = () => myRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const parsedRoomInfo = baobab.root.select(['monkeys', 'parsedRoomInfo']).get();
    const roomsByTags = baobab.root.select(['monkeys', 'parsedAndTagedRoomInfo']).get();

    React.useEffect(() => {
        const loadData = () => {
            // let showComponentsFromBaobab =   baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get();
            let showComponents: string[] = [];
            if (props.history?.location?.search) {
                switch (props.history.location.search) {
                    case '?reservations':
                        showComponents = ['sidebar_dailyInfo', 'timeline'];
                        break;
                    // case '?journal':
                    //     showComponents = ['sidebar_dailyInfo', 'journal'];
                    //     break;
                    case '?rooms':
                        showComponents = ['sidebar_dailyInfo', 'tiles'];
                        break;
                    case "?roomsOnPrem":
                        showComponents = ["sidebar_dailyInfo", "tiles"]
                        break;
                    case '?printJournal':
                        showComponents = ['sidebar_dailyInfo', 'journal'];
                        break;
                    case '?activity':
                        showComponents = ['sidebar_dailyInfo', 'activity'];
                        break;
                    case '?occupancy':
                        showComponents = ['sidebar_dailyInfo', 'occupancy'];
                        break;
                    // case '?journal':
                    case '?journal':
                        showComponents = ['sidebar_dailyInfo', 'journal'];
                        break;

                    default:
                        showComponents = [''];
                        break;
                }
                baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(showComponents);
            }

            if (printJournal && !showComponents.includes('journal')) {
                props.history.push(``);
            }
        };

        loadData();
        // eslint-disable-next-line
    }, [printJournal, props.history]);

    // React.useEffect(() => {
    //     console.log('active Reservations');
    // }, []);

    const toggleTagFilter = (tag: string | null) => {
        if (tag === null) {
            setTagsFilter([]);
        } else {
            let _tagsFilter = [...tagsFilter];
            if (_tagsFilter.includes(tag)) {
                _tagsFilter = _tagsFilter.filter((t) => t !== tag);
            } else {
                _tagsFilter.push(tag);
                _tagsFilter = _tagsFilter.filter((t) => t === tag);
            }
            setTagsFilter(_tagsFilter);
        }
    };

    const toggleCleaningStatusesFilter = (clStatus: number | null) => {
        if (clStatus === null) {
            setCleaningStatusesFilter([]);
        } else {
            let _cleaningStatusesFilter = [...cleaningStatusesFilter];
            if (_cleaningStatusesFilter.includes(clStatus)) {
                _cleaningStatusesFilter = _cleaningStatusesFilter.filter((t) => t !== clStatus);
            } else {
                _cleaningStatusesFilter.push(clStatus);
                _cleaningStatusesFilter = _cleaningStatusesFilter.filter((t) => t === clStatus);
            }
            setCleaningStatusesFilter(_cleaningStatusesFilter);
        }
    };

    const toggleGroupReservations = (forcedValue = undefined) => {
        if (forcedValue !== undefined) {
            //@ts-ignore
            setGroupReservations(forcedValue);
        } else {
            setGroupReservations(!groupReservations);
        }
    };

    const setNumOfBeds = (e: any) => {
        if (e === '') {
            setNumberOfBeds('');
        } else if (e.target.value === '') {
            setNumberOfBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfBeds('');
            } else {
                setNumberOfBeds(Number(e.target.value));
            }
        }
    };

    const setNumOfSpareBeds = (e: any) => {
        if (e === '') {
            setNumberOfSpareBeds('');
        } else if (e.target.value === '') {
            setNumberOfSpareBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfSpareBeds('');
            } else {
                setNumberOfSpareBeds(Number(e.target.value));
            }
        }
    };

    const _setSearchTerm = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const toggleShowComponent = (componentName: string, forceShow = undefined, e: any) => {
        let temp = _.clone(props.uiStates.showComponents);

        if (props.uiStates.showComponents.includes(componentName)) {
            temp = temp.filter((t: string) => t !== componentName);
        } else {
            temp.push(componentName);
        }

        if (forceShow !== undefined) {
            if (forceShow === true) {
                temp.push(componentName);
                temp = _.uniq(temp);
            } else if (forceShow === false) {
                temp = temp.filter((t: string) => t !== componentName);
            }
        }

        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    };

    const setAvailableFromAndToTimestamps = (
        availableFromTimestamp: number,
        _availableToTimestamp: number,
        stayInDays: number
    ) => {
        const availableToTimestamp_ = _availableToTimestamp - 8 * 60 * 60 * 1000; //add some tollerance on filtering since this will always be at 12 o clock (noon)
        setAvailableFromTimestamp(availableFromTimestamp);
        setAvailableToTimestamp(_availableToTimestamp === null ? _availableToTimestamp : availableToTimestamp_);
        setStayInDays(stayInDays);
    };

    const toggleSelectRoomForReservation = (roomInfoId: number | null) => {
        if (roomInfoId === null) {
            setSelectedRoomsForReservations([]);
            setGroupReservations(false);
            setReservationUuid(null);
            setReservationId(null);
            // setAvailableToTimestamp(null);
            // setAvailableFromTimestamp(Date.now());
        } else {
            if (availableToTimestamp === null) {
                setAvailableToTimestamp(
                    availableFromTimestamp
                        ? new Date(new Date(availableFromTimestamp).getTime() + 86400000).getTime()
                        : new Date(new Date().getTime() + 86400000).getTime()
                );
            }
            let _selectedRoomsForReservations = [...selectedRoomsForReservations];
            if (_selectedRoomsForReservations.includes(roomInfoId)) {
                //remove it
                _.remove(_selectedRoomsForReservations, (r: number) => r === roomInfoId);
            } else {
                _selectedRoomsForReservations.push(roomInfoId);
            }
            setSelectedRoomsForReservations(_selectedRoomsForReservations);
        }
    };

    const handleAttributesSelect = (values: any) => {
        if (values === null) {
            setAttributesFilter([]);
        } else {
            const attrFilter = values.map((v: any) => v.value);
            setAttributesFilter(attrFilter);
        }
    };

    const filterRoomsByAttribute = (rooms: RoomInfoEditModel[]) => {
        let _attributesFilter = [...attributesFilter];
        let filteredRooms = rooms.map((r) => {
            if (_attributesFilter.length > 0) {
                let eject = false;
                _attributesFilter.forEach((att) => {
                    if (r.roomAttributes && r.roomAttributes !== null) {
                        if (!JSON.parse(r.roomAttributes).includes(att)) {
                            eject = true;
                            return true;
                        }
                    } else {
                        eject = true;
                    }
                });
                if (eject) {
                    return null;
                } else {
                    return r;
                }
            } else {
                return r;
            }
        });
        return filteredRooms;
    };

    const filterRoomsByCleaningStatuses = (
        rooms: RoomInfoEditModel[],
        cleaningStatusHeaders: CleaningStatusHeaderExtendedWithCleaningStatusModel
    ) => {
        let _cleaningStatusesFilter = [...cleaningStatusesFilter];

        let filteredRooms = rooms.map((r) => {
            if (_cleaningStatusesFilter.length > 0) {
                let statusObj = cleaningStatusHeaders?.CleaningStatuses?.find((sh) => {
                    return sh?.RoomInfo?.id === r?.id;
                });
                let cleaningStatus = statusObj?.cleaningStatus;
                if (cleaningStatus === _cleaningStatusesFilter[0]) {
                    return r;
                } else {
                    return null;
                }
            } else {
                return r;
            }
        });
        return filteredRooms;
    };

    const filterRoomsByAnyAttribute = (rooms: RoomInfoEditModel[]) => {
        let _attributesFilter = [...attributesFilter];
        let filteredRooms: RoomInfoEditModel[] = [];
        if (_attributesFilter.length > 0) {
            rooms.forEach((r) => {
                const roomAttributes =
                    r.roomAttributes && JSON.parse(r.roomAttributes) ? JSON.parse(r.roomAttributes) : [];
                _attributesFilter.forEach((a) => {
                    if (roomAttributes.includes(a)) {
                        if (!filteredRooms.map((r) => r.id).includes(r.id)) {
                            filteredRooms.push(r);
                        }
                    }
                });
            });
        } else {
            filteredRooms = rooms;
        }
        return filteredRooms;
    };

    const roomsMap: any = {};

    for (const r of parsedRoomInfo) {
        roomsMap[r.id] = r;
    }

    if (roomsByTags?.length === 0) {
        return <PageLoader />;
    }

    let showOutOfServiceLegend = false;
    //in first step, filter all rooms by tag....
    let filteredRooms = parsedRoomInfo.map((r: RoomInfoEditModel) => {
        if (tagsFilter.length > 0) {
            let eject = false;
            tagsFilter.forEach((t) => {
                if (r.tags && !r.tags.includes(t)) {
                    eject = true;
                    return true;
                }
            });
            if (eject) {
                return null;
            } else {
                return r;
            }
        } else {
            return r;
        }
    });

    filteredRooms = filteredRooms.filter((i: RoomInfoEditModel) => (i ? i.availableForRent : null));

    if (props.cleaningStatusHeaders[0]) {
        filteredRooms = filterRoomsByCleaningStatuses(filteredRooms, props.cleaningStatusHeaders[0]);
    }

    //implement room name search
    filteredRooms = filteredRooms.map((roomInfo: RoomInfoEditModel) => {
        if (roomInfo && roomInfo.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return roomInfo;
        } else {
            return null;
        }
    });

    filteredRooms = filteredRooms.filter((f: RoomInfoEditModel) => f !== null);
    //bed search params...
    if (numberOfBeds) {
        filteredRooms = filteredRooms.filter((r: RoomInfoEditModel) => r.bedCount === Number(numberOfBeds));
    }

    if (numberOfSpareBeds) {
        filteredRooms = filteredRooms.filter((r: RoomInfoEditModel) => r.spareBedCount === Number(numberOfSpareBeds));
    }

    filteredRooms = filteredRooms.filter((f: RoomInfoEditModel) => f !== null);

    //aditional room removal....
    if (filterOutRoomsById.length > 0) {
        filteredRooms = !showOccupiedRooms
            ? filteredRooms.filter((r: RoomInfoEditModel) => !filterOutRoomsById.includes(r.id))
            : filteredRooms;
    }

    //if search term was a card code number
    let cardNumberSearch = parseInt(searchTerm, 10);
    if (!_.isNaN(cardNumberSearch) && _.isNumber(cardNumberSearch)) {
        let reservations = reservations_.filter((r) => {
            return (
                (r.guest1CodeCopy === cardNumberSearch ||
                    r.guest2CodeCopy === cardNumberSearch ||
                    r.guest3CodeCopy === cardNumberSearch) &&
                Number(r.statusEnum) === 2
            ); //checkedIn
        });
        if (reservations.length > 0) {
            filteredRooms = []; //RESETING filteredRooms
        }
        reservations.forEach((reservation) => {
            let room = parsedRoomInfo.find((r: RoomInfoEditModel) => r.id === reservation.roomInfoId);
            if (room) {
                filteredRooms.push(room);
            }
        });
    } else if (searchTerm) {
        const filteredRoomMap: any = {};
        for (const r of filteredRooms) {
            filteredRoomMap[r.id] = r.id;
        }

        reservations_.forEach((res) => {
            const guest = res.Customer;
            const guestNameFirst = guest ? `${guest.firstName} ${guest.lastName}` : '';
            const guestSurnameFirst = guest ? `${guest.lastName} ${guest.firstName}` : '';

            if (
                guest &&
                (guestNameFirst.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1 ||
                    guestSurnameFirst.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1)
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }

            if (
                res.Company &&
                res.Company.name &&
                res.Company.name.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }
        });
    }

    if (attributesFilteringType) {
        filteredRooms = filterRoomsByAnyAttribute(filteredRooms);
    } else {
        filteredRooms = filterRoomsByAttribute(filteredRooms);
    }

    //remove all nulls
    filteredRooms = filteredRooms.filter((f: RoomInfoEditModel) => f !== null);

    filteredRooms = _.orderBy(filteredRooms, ['name'], ['asc']);

    var { showComponents } = props.uiStates;

    if (
        (showComponents.includes('timeline') && !isFeatureEnabled('reservationsTimelineDashboard')) ||
        props?.loggedUser?.role === 'hwAdmin'
    ) {
        showComponents = ['tiles'];
    }

    const state = {
        availableFromTimestamp,
        availableToTimestamp,
        stayInDays,
        groupReservations,
        searchTerm,
        numberOfBeds,
        numberOfSpareBeds,
        tagsFilter,
        filterOutRoomsById,
        selectedRoomsForReservations,
        attributesFilter,
        attributesFilteringType,
        showOccupiedRooms,
        cleaningStatusesFilter,
    };

    const searchParam = props.history.location.search;
    const ctrl = new ReservationController();
    const _activeReservations = ctrl.getReservations();

    return (
        <div ref={myRef} className="mobile-page">
            {selectedRoomsForReservations?.length > 0 && !showReservationDetails ? (
                <ReservationForm
                    history={props.history}
                    mousePosition={mousePosition}
                    selectedRoomsForReservations={selectedRoomsForReservations}
                    toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                    reservationId={reservationId}
                    groupUuid={reservationUuid}
                    availableFromTimestamp={availableFromTimestamp}
                    availableToTimestamp={availableToTimestamp}
                    showReservationDetails={(resId: number) => {
                        setShowReservationDetails(resId);
                        setReservationId(resId)
                    }}
                />
            ) : null}
            {selectedRoomsForReservations?.length > 0 && showReservationDetails ? (
                <ReservationDetails
                    history={props.history}
                    mousePosition={{ x: 0, y: 0 }}
                    reservationId={showReservationDetails}
                    setShowDetails={() => {
                        setShowReservationDetails(null);
                        setPrintPreview(false);
                    }}
                    setEditReservation={() => {
                        setShowReservationDetails(null);
                    }}
                    setPrintPreview={setPrintPreview}
                />
            ) : null}

            {!printJournal && (searchParam === '?reservations' || searchParam === '' || searchParam === '?rooms') ? (
                <div className="hiddenn-on-mobile d-print-none">
                    {/* <h1>{translate('Reservation table')}</h1> */}
                    <Toolbar
                        showComponents={showComponents}
                        history={props.history}
                        {...state}
                        setNumberOfBeds={setNumOfBeds}
                        setNumberOfSpareBeds={setNumOfSpareBeds}
                        setHideRoomByIdFilter={(array: number[]) => {
                            setFilterOutRoomsById([...array]);
                        }}
                        toggleShowComponent={toggleShowComponent}
                        toggleTagFilter={toggleTagFilter}
                        setAvailableFromAndToTimestamps={setAvailableFromAndToTimestamps}
                        handleAttributesSelect={handleAttributesSelect}
                        setAttributesFilteringType={setAttributesFilteringType}
                        setSearchTerm={_setSearchTerm}
                        tagsFilter={tagsFilter}
                        attributesFilter={attributesFilter}
                        cleaningStatusesFilter={cleaningStatusesFilter}
                        setCleaningStatusesFilter={setCleaningStatusesFilter}
                        toggleCleaningStatusesFilter={toggleCleaningStatusesFilter}
                        attributesFilteringType={attributesFilteringType}
                    />
                </div>
            ) : null}

            <div className="unselectable px-0 mx-0">
                <div className="scrollbar-custom">
                    {showComponents.includes('timeline') ? (
                        <React.Fragment>
                            <FeatureFlag flag="reservationsTimelineDashboard">
                                <div className="hiddenn-on-mobile  d-print-none">
                                    <ToolbarReservationMode
                                        toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                                        toggleGroupReservations={toggleGroupReservations}
                                        showComponents={showComponents}
                                        toggleShowComponent={toggleShowComponent}
                                        setNumberOfBeds={setNumOfBeds}
                                        setNumberOfSpareBeds={setNumOfSpareBeds}
                                        setHideRoomByIdFilter={(array: number[]) => {
                                            setFilterOutRoomsById([...array]);
                                        }}
                                        setAvailableFromAndToTimestamps={setAvailableFromAndToTimestamps}
                                        toogleOccupiedRooms={() => setShowOccupiedRooms(!showOccupiedRooms)}
                                        setShowReservationsList={setShowReservationsList}
                                        showReservationsList={showReservationsList}
                                        setReservationId={setReservationId}
                                        setReservationUuid={setReservationUuid}
                                        {...state}
                                        {...props}
                                    />
                                </div>
                            </FeatureFlag>
                        </React.Fragment>
                    ) : null}

                    {
                        showComponents.includes('journal') ? (
                            <FeatureFlag flag="reservationsTimelineDashboard">
                                <JournalWithActivity
                                    {...props}
                                    reservations={reservations_}
                                    rooms={filteredRooms}
                                    executeScrollTop={executeScrollTop}
                                />
                            </FeatureFlag>
                        ) : null
                    }

                    {showComponents.includes('tiles') ? (
                        <div className="clear w-100" style={{ overflowX: 'auto', paddingBottom: '20px' }}>
                            <HwRoomView {...props} rooms={filteredRooms} />
                        </div>
                    ) : null}

                    {
                        showComponents.includes('activity') ? (
                            <FeatureFlag flag="activityDashboard">
                                <ActivityView
                                    history={props.history}
                                    reservations={reservations_}
                                    rooms={filteredRooms}
                                />
                            </FeatureFlag>
                        ) : null
                        //activity
                    }

                    {showComponents.includes('timeline') && showReservationsList ? (
                        <div className="mb-1 clear  d-print-none" style={{ position: 'relative' }}>
                            <TimelineView
                                selectedRoomsForReservations={selectedRoomsForReservations}
                                showComponents={showComponents}
                                availableFromTimestamp={availableFromTimestamp}
                                availableToTimestamp={availableToTimestamp}
                                numberOfBeds={numberOfBeds}
                                numberOfSpareBeds={numberOfSpareBeds}
                                history={props.history}
                                reservations={reservations_}
                                toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                                groupReservations={groupReservations}
                                rooms={filteredRooms}
                                setMousePosition={setMousePosition}
                                toggleGroupReservations={toggleGroupReservations}
                                setReservationId={setReservationId}
                                setReservationUuid={setReservationUuid}
                                activeReservations={_activeReservations}
                            />

                            {/* <div className="mb-1" style={{width:"200px", position:"absolute", right:0, top:0}}>
                         <div className="custom-list-header">
                             <div>{translate("Id")}</div>
                             <div>{translate("Holder")}</div>
                             <div>{translate("Room")}</div>
                             <div>{translate("Check in")}</div>
                             <div>{translate("Check out")}</div>
                             <div>{translate("Status")}</div>
                         </div>
                         <ReservationsList resList={reservations_} statusEnum={1} history={props.history} />
                         <ReservationsList resList={reservations_} statusEnum={2} history={props.history} />
                         <ReservationsList resList={reservations_} statusEnum={3} history={props.history} />
                     </div> */}
                        </div>
                    ) : null}

                    {showComponents.includes('timeline') && !showReservationsList ? (
                        <div className="mb-1 clear  d-print-none" style={{ position: 'relative', display: 'flex' }}>
                            <div id="timeline-view">
                                <TimelineView
                                    selectedRoomsForReservations={selectedRoomsForReservations}
                                    showComponents={showComponents}
                                    availableFromTimestamp={availableFromTimestamp}
                                    availableToTimestamp={availableToTimestamp}
                                    numberOfBeds={numberOfBeds}
                                    numberOfSpareBeds={numberOfSpareBeds}
                                    history={props.history}
                                    reservations={reservations_}
                                    toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                                    groupReservations={groupReservations}
                                    rooms={filteredRooms}
                                    setMousePosition={setMousePosition}
                                    toggleGroupReservations={toggleGroupReservations}
                                    setReservationId={setReservationId}
                                    setReservationUuid={setReservationUuid}
                                    activeReservations={_activeReservations}
                                />
                            </div>

                            <SidebarWrapper>
                                {/* <div className="">
                                    <div
                                        className="btn-group rounded  mr-2 "
                                        style={{display: "flex"}}
                                    >
                                        <button title="Daily info" type="button" className="btn btn-sm btn-light border">
                                            <i className="fa fa-pie-chart text-info"></i>
                                        </button>
                                        <button
                                            title="Weekly and monthly info"
                                            type="button"
                                            className="btn btn-sm btn-light border"
                                        >
                                            <i className="fa fa-area-chart text-info"></i>
                                        </button>
                                        <button
                                            title="Incomming reservations"
                                            type="button"
                                            className="btn btn-sm btn-light active border"
                                        >
                                            <i className="fa fa-calendar-check-o text-info"></i>
                                        </button>
                                        <button
                                            title="Checked in reservations"
                                            type="button"
                                            className="btn btn-sm btn-light border"
                                        >
                                            <i className="fa fa-sign-out text-info"></i>
                                        </button>
                                        <button
                                            title="Canceled reservations"
                                            type="button"
                                            className="btn btn-sm btn-light border"
                                        >
                                            <i className="fa fa-eraser text-info"></i>
                                        </button>
                                    </div>
                                </div>
                                <ReservationsList resList={reservations_} statusEnum={1} history={props.history} />
                                <ReservationsList resList={reservations_} statusEnum={2} history={props.history} />
                                <ReservationsList resList={reservations_} statusEnum={3} history={props.history} />
 */}

                                <Sidebar
                                    {...props}
                                    searchTerm={searchTerm}
                                    rooms={filteredRooms}
                                    availableFromTimestamp={availableFromTimestamp}
                                    availableToTimestamp={availableToTimestamp}
                                    toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                                    setReservationId={setReservationId}
                                    setReservationUuid={setReservationUuid}
                                >
                                    <SidebarToolbar setSearchTerm={_setSearchTerm} showComponents={showComponents} />
                                </Sidebar>
                            </SidebarWrapper>
                        </div>
                    ) : null}
                </div>

                {/* {props.history?.location?.search === '?occupancy' ? (
                    <div className="col-sm-12 mt-2 mt-md-0">
                        <Sidebar
                            {...props}
                            searchTerm={searchTerm}
                            rooms={filteredRooms}
                            availableFromTimestamp={availableFromTimestamp}
                            availableToTimestamp={availableToTimestamp}
                        >
                            <SidebarToolbar setSearchTerm={_setSearchTerm} showComponents={showComponents} />
                        </Sidebar>
                    </div>
                ) : null} */}
            </div>

            {showOutOfServiceLegend ? (
                <div className="text-white mt-3 ml-1">
                    <small>(*) - {translate('OUT OF SERVICE')}</small>
                </div>
            ) : null}
        </div>
    );
}

// const ReservationsList = (props) => {
//     const { resList, statusEnum, history } = props
//     const filteredResList = resList?.filter((r)=>r.statusEnum===statusEnum)

//     return (
//         <div className="my-2">
//             <span className="bold-letters">
//                 {statusEnum===1 ? translate("Incomming reservations")
//                     : statusEnum===2 ? translate("Checked in reservations")
//                     : statusEnum===3 ? translate("Canceled reservations") : ""}
//             </span>
//             <div>
//                 {filteredResList?.length===0 ?
//                     <div className="">{translate("List is empty")}</div>
//                     : filteredResList?.map((r, index)=>{
//                         if(r.statusEnum===statusEnum){
//                             return(
//                                 <div className=" pointer" key={index}
//                                     onClick={()=>history?.push('/reservations/' + r.id + '/edit')}
//                                     style={{    border: "1px solid green",
//                                         margin: "8px", borderRadius:"4px"}}
//                                 >
//                                     <div>{r.Customer?.firstName ? (r.Customer.firstName + " " + (r.Customer.lastName ? r.Customer.lastName : "")) : r.Company?.name ? r.Company.name :  ""} - {r.RoomInfo?.name ? r.RoomInfo.name : ""}</div>
//                                     <div>#{r.id} | {moment(r.checkInTimestamp).format("MM/DD/YYYY")} - {moment(r.checkOutTimestamp).format("MM/DD/YYYY")}</div>
//                                     <div className={reservationStatusColors[r.statusEnum]}>{reservationStatusEnumsDesc[r.statusEnum]}</div>
//                                 </div>
//                             )
//                         }else{
//                             return null
//                         }
//                     }).filter(r=>r!==null)}
//             </div>
//         </div>
//     )
// }

interface SidebarWrapperProps {
    children: any;
}

const SidebarWrapper = (props: SidebarWrapperProps) => {
    const [pageHeight, setPageHeight] = useState(300);

    React.useEffect(() => {
        setPageHeight(getTableHeight());
        // eslint-disable-next-line
    }, []);

    function getPos(el: any) {
        // yay readability
        for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return { x: lx, y: ly };
    }

    const getTableHeight = () => {
        let el = document.getElementById('timeline');
        // let footer = document.getElementById('main-footer');
        let mainAppDiv: any = document.getElementById('main-app-div');

        let positionOfTableHeaderElement = getPos(el);
        // let positionOfDocumentFooterElement = getPos(footer);
        // let heightOfTableHeaderElement = el.offsetHeight;

        const heightOfTheSpaceFromTableHeaderToDocumentFooter =
            mainAppDiv.scrollHeight - positionOfTableHeaderElement.y;
        const tableContentHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter - 50 - 23;
        return tableContentHeight;
    };
    return (
        <div
            id="sidebar"
            style={{
                width: '20%',
                display: 'inline-block',
                height: `${pageHeight}px`,
                overflow: 'auto',
                overflowX: 'hidden',
            }}
        >
            {props.children}
        </div>
    );
};

export default branch(
    {
        reservations: ['model', 'Reservation'],
        uiStates: ['uiStates', 'dashboard'],
        loggedUser: ['authTokenInfo', 'user'],
        cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'],
        activeReservations: ['state', 'reservationForm2', 'reservations'],
    },
    Dashboard
);
