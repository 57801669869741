import baobabTree from '../state/index';
import _ from 'lodash';
import rest from '../restWrapper';
import { reloadReservations } from '../actions/loaders';

var socketBaobabDataFunnel = (modelName, method, payload) => {
    const permissions = baobabTree.root.select(['state', 'userPermissions']).get();
    if (!permissions?.readModels?.includes(modelName)) {
        return;
    }

    try {
        //console.log("baobab untested", modelName, method, payload);
        let pkeyName = baobabTree.root.select(['pkeyMap', modelName]).get();
        let baobabRoute = null;

        if (baobabTree.root.select('model', modelName).get() !== undefined) {
            baobabRoute = ['model', modelName];
        }

        if (baobabRoute !== null) {
            //baobabRoute.push(modelName)
            switch (method) {
                case 'PUT': //na temelju pkey-a potraži zapis i izmjeni ga
                    if (!_.isArray(payload)) {
                        let keyObj = {};
                        keyObj[pkeyName] = payload[pkeyName];
                        let baobabObjectReference = _.clone(baobabTree.root.select(baobabRoute).get(keyObj));
                        if (baobabObjectReference !== undefined) {
                            _.forIn(payload, (val, key) => {
                                baobabObjectReference[key] = val;
                            });
                            baobabTree.root.select(_.concat(baobabRoute, [keyObj])).set(baobabObjectReference); //update the object....
                        } else {
                            //put it on the stack
                            baobabTree.root.select(baobabRoute).push(payload);
                        }
                    }
                    break;
                case 'DELETE': //ukloni novi zapis sa liste na temelju pkeyy-a
                    if (payload[pkeyName] !== undefined) {
                        baobabRoute.push({[pkeyName]:payload[pkeyName]});
                        const cursor = baobabTree.root.select(baobabRoute);
                        cursor.unset();
                    }
                    break;
                case 'POST': //dodaj novi zapis na listu ako ne postoji
                    if (!_.isArray(payload)) {
                        let keyObj = {};
                        keyObj[pkeyName] = payload[pkeyName];
                        let baobabObjectReference = baobabTree.root.select(baobabRoute).get(keyObj);
                        if (baobabObjectReference === undefined) {
                            baobabTree.root.select(baobabRoute).push(payload);
                        }
                    }
                    break;
                default: //ignore
                case 'GET': //GET is not supported by the socket funnel funnel. Manualy put data on baobab and take care
                    break;
            }
        }
    } catch (err) {
        console.warn('socketBaobabDataFunnel.error', err);
    }
};

var reloadModelData = async (modelName) => {
    try {
        if (!_.isUndefined(baobabTree.root.select('model', modelName).get())) {
            if (modelName === 'Reservation') {
                await reloadReservations();
            } else {
                let baobabRoute = ['model', modelName];
                let data = await rest(`/${modelName}?page=0&rows=1000`);
                baobabTree.root.select(baobabRoute).set(data);
            }
        }
    } catch (err) {
        console.log(err);
    }
};

export { reloadModelData };
export default socketBaobabDataFunnel;