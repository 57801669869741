import React, { Component } from 'react';
import pubsub from '../data/pubsub';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import { showSRCAlarmToast } from '../data/SRCAlarmAndAlertController';

class BackendUIResponder extends Component {
    componentDidMount = async () => {
        pubsub.on('socketPayload', this.socketUIHandlers);
        pubsub.on('toastMessage', this.toastMessage);
    };

    componentWillUnmount = () => {
        pubsub.removeListener('socketPayload', this.socketUIHandlers);
        pubsub.removeListener('toastMessage', this.toastMessage);
    };

    toastMessage = (message, type, autoClose = 3000) => {
        let toastConfig = {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose,
        };
        switch (type) {
            case 'error':
                toastConfig = {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose,
                };
                break;
            default:
                break;
        }
        toast(message, toastConfig);
    };

    socketUIHandlers = (payload) => {
        if (
            this.props.loggedUser?.role &&
            !this.props.otherSettings?.rolesToNotShowSystemMessages?.includes(this.props.loggedUser.role)
        ) {
            if (payload.model === 'ToastMessage') {
                //  console.log(payload);
                let message = '';
                let toastConfig = payload.entity.toastConfig
                    ? payload.entity.toastConfig
                    : {
                          type: toast.TYPE.INFO,
                          position: toast.POSITION.BOTTOM_RIGHT,
                          autoClose: 3000,
                      };
                if (_.isString(payload.entity)) {
                    message = payload.entity;
                } else {
                    message = payload.entity.message;
                }
                toast(message, toastConfig); //console.log("ToastMessage", payload);
            } else if (payload.model === 'RoomEventTicket') {
                const ticket = payload.entity;
                const roomInfo = this.props.rooms.find((r) => r.id === ticket.roomInfoId);
                showSRCAlarmToast(roomInfo, ticket);
            }
        }
    };

    render() {
        return <div></div>;
    }
}

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        otherSettings: ['otherSettings'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    BackendUIResponder
);
