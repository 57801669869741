import React from 'react';
// import {ReactComponent as Search} from '../../assets/custom/icons/search.svg'

export default function CustomInput(props) {
    const onInputChange = (e) => {
        props.onInputChange(e);
    };

    return (
        <div id="input-container" className="input-container" style={{width: props.width ? props.width : ""}}>
            {props.icon ? props.icon : null}
            <input
                id="input"
                onKeyPress={
                    props.onInputKeyPress
                        ? (e) => {
                              props.onInputKeyPress(e);
                          }
                        : null
                }
                onChange={(e) => {
                    onInputChange(e);
                }}
                className={props.icon ? 'input-field-with-icon' : 'input-field'}
                type="text"
                title={props.placeholder}
                placeholder={props.placeholder}
                value={props.value}
                name="usrnm"
            />
        </div>
    );
}
