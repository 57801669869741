/*just include this file in index.js*/

import baobabTree from './state';
import pubsub from './pubsub';

var intervalSeconds = 5;

const licenceValidityCheck = () => {
	const licence = baobabTree.root.select(['licence']);
	const l = licence.get();

	if(l===null || !l){
		return null;
	}

	if ((l.licence && !l.licence.isValid && l.isTrialActive) || process.env.NODE_ENV === 'development') {
		intervalSeconds = 12 * 60 * 60 * 1000; //every 12 hours
		if (process.env.NODE_ENV === 'development') {
			pubsub.emit(
				'toastMessage',
				`This is DEVELOPMENT version of software. All features are included.`,
				'info',
				60000
			); //type=error or info
		} else {
			pubsub.emit(
				'toastMessage',
				`This is TRIAL version of software.`,
				'info',
				60000
			); //type=error or info
		}
	} else {
		if (l.licence && l.licence.isValid) {
			//sve 5
			intervalSeconds = 300;
		} else {
			intervalSeconds = 60;
			pubsub.emit(
				'toastMessage',
				'Invalid licence. Some features may not work. Please contact sales.',
				'error',
				15000
			);
		}
	}

	//sada odradi notifikacijski check za korisnike, uređaje, sobe


	if (l.licence.decodedLicence && l.licence.decodedLicence.devicesCount &&  l.currentDevicesCount > Number(l.licence.decodedLicence.devicesCount)) {
		setTimeout(() => {
			pubsub.emit(
				'toastMessage',
				`Licenced devices count exceeded! ${l.currentDevicesCount} / ${l.licence.decodedLicence.devicesCount}`,
				'error',
				15000
			);
		}, 30 * 1000); //taman kad prva poruka završi
	}


	if (l.licence.decodedLicence && l.licence.decodedLicence.roomsCount && l.currentRoomsCount > Number(l.licence.decodedLicence.roomsCount)) {
		setTimeout(() => {
			pubsub.emit(
				'toastMessage',
				`Licenced rooms count exceeded! ${l.currentRoomsCount} / ${l.licence.decodedLicence.roomsCount}`,
				'error',
				15000
			);
		}, 45 * 1000); //taman kad prva poruka završi
	}


/*
	if (l.usersCountExceeded) {
		setTimeout(() => {
			pubsub.emit(
				'toastMessage',
				`Licenced users count exceeded! ${l.currentUsersCount} / ${l.licence.decodedLicence.usersCount}`,
				'error',
				15000
			);
		}, 15 * 1000); //taman kad prva poruka završi
	}
	*/

	setTimeout(() => {
		licenceValidityCheck();
	}, intervalSeconds * 1000);
};



export  {licenceValidityCheck};