import * as React from 'react';
import PageTitle from '@components/pageTitle';
// import ReservationForm from '../newReservations';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import './style.css';

import moment from 'moment';
import 'moment/min/locales.min';

import { getFinancialJournalDetails } from '@pages/posTerminal/sideMenu/api';
import translate from '@data/translations';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    InvoiceConclusionDetailsHeader,
    InvoiceConclusionDetailsList,
} from '@pages/posTerminal/sideMenu/invoiceConclusion';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';

export interface InvoiceConclusionDetailsModel {
    plu: number | null;
    name: string | null;
    price: number | null;
    discount: number | null;
    quantity: number;
    total: number;
    type: string;
    subtype: string | null;
}

interface InvoiceConclusionDetailsResult {
    invoiced: InvoiceConclusionDetailsModel[];
    proforma: InvoiceConclusionDetailsModel[];
}

const FinancialJournal = (props: any) => {
    const { systemLocale, defaultCurrency } = props;
    const [invoiceConclusionsDetails, setInvoiceConclusionsDetails] =
        React.useState<InvoiceConclusionDetailsResult | null>(null);
    const [from, setFromDate] = React.useState(null as number | null);
    const [to, setToDate] = React.useState(null as number | null);
    const [year, setYear] = React.useState(new Date().getFullYear() as number | null);
    const [month, setMonth] = React.useState(null as number | null);
    const [selectedDay, setSelectedDay] = React.useState(new Date().getTime() as number | null);

    React.useEffect(() => {
        const loadData = async () => {
            let todaysDateOrSelectedDay = selectedDay
            startGlobalLoading();
            const _invoiceConclusionsDetails = await getFinancialJournalDetails({
                group: 'type',
                from,
                to,
                todaysDateOrSelectedDay,
            });
            stopGlobalLoading();

            if (_invoiceConclusionsDetails) {
                setInvoiceConclusionsDetails(_invoiceConclusionsDetails);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [from, to, year, month, selectedDay]);

    const YearSelect = (props: any) => {
        const { _year, setYear } = props;
        let yearOptions = [];
        for (let i = _year - 2; i <= new Date().getFullYear(); i++) {
            yearOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return (
            <select
                className="form-control form-control-sm px-1 w-auto  bg-white"
                value={year ? year : ''}
                onChange={(e: any) => {
                    setYear(Number(e.target.value));
                    if(month){
                        const firstDay = getFirstDayOfMonth(Number(e.target.value), Number(month) - 1);
                        const lastDay = getLastDayOfMonth(Number(e.target.value), Number(month) - 1);
                        setFromDate(moment(firstDay).startOf('day').valueOf());
                        setToDate(moment(lastDay).endOf('day').valueOf());
                    }
                }}
            >
                {yearOptions}
            </select>
        );
    };

    const getFirstDayOfMonth = (year: number, month: number) => {
        return new Date(year, month, 1);
    };

    const getLastDayOfMonth = (year: number, month: number) => {
        return new Date(year, month + 1, 0);
    };

    const MonthSelect = (props: any) => {
        const { setMonth } = props;
        let monthOptions = [];
        for (let i = 1; i <= 12; i++) {
            monthOptions.push(
                <option key={i} value={i}>
                    {moment()
                        .month(i - 1)
                        .format('MMMM')}
                </option>
            );
        }
        return (
            <select
                className="form-control form-control-sm px-1 w-auto bg-white ml-16p"
                value={month ? month : ''}
                onChange={(e: any) => {
                    if(e.target.value === 'byDate'){
                        setSelectedDay(new Date().getTime());
                        setYear(new Date().getFullYear())
                        setMonth(null);
                        setFromDate(null);
                        setToDate(null);
                    }else{
                        const firstDay = getFirstDayOfMonth(Number(year), Number(e.target.value) - 1);
                        const lastDay = getLastDayOfMonth(Number(year), Number(e.target.value) - 1);
                        setMonth(Number(e.target.value));
                        setFromDate(moment(firstDay).startOf('day').valueOf());
                        setToDate(moment(lastDay).endOf('day').valueOf());
                        setSelectedDay(null);
                    }
                }}
            >
                <option key={'byDate'} value="byDate">
                    {translate('By Date')}
                </option>
                {monthOptions}
            </select>
        );
    };

    return (
        <div className="mobile-page">
            <PageTitle />
            <div className="d-flex">
                <YearSelect _year={new Date().getFullYear()} setYear={setYear} />
                <MonthSelect setMonth={setMonth} />
                {!month ? (
                    <DayPickerInput
                        inputProps={{
                            style: { width: '110px' },
                            className: ' form-control form-control-sm ml-16p ',
                        }}
                        value={selectedDay ? new Date(selectedDay) : new Date()}
                        format={props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY'}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        onDayChange={async (e: any) => {
                            setSelectedDay(new Date(e).getTime());
                        }}
                        dayPickerProps={{
                            disabledDays: { after: new Date() },
                        }}
                    />
                ) : null}
            </div>

            <div className="d-flex mt-2" style={{ fontWeight: 'bold' }}>
                <div>{translate('Selected date(s):')}</div>{' '}
                <div className="ml-2">
                    {selectedDay
                        ? moment(selectedDay)
                              .locale(systemLocale)
                              .format(props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY')
                        : from && to
                        ? moment(from)
                              .locale(systemLocale)
                              .format(props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY') +
                          ' - ' +
                          moment(to)
                              .locale(systemLocale)
                              .format(props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY')
                        : ''}
                </div>
            </div>

            <div>
                <div className="col-12 mt-2 mb-3">
                    <h5 className="mt-2 mb-2 pointer text-center p-2">
                        {<b>{translate('Invoices').toUpperCase()}</b>}{' '}
                    </h5>
                </div>
                {invoiceConclusionsDetails && invoiceConclusionsDetails.invoiced?.length ? (
                    <div>
                        {' '}
                        <InvoiceConclusionDetailsHeader />
                        <InvoiceConclusionDetailsList
                            invoiceConclusionDetails={invoiceConclusionsDetails?.invoiced}
                            defaultCurrency={defaultCurrency}
                        />
                    </div>
                ) : (
                    <div className="text-center">{translate('There is no data for selected date(s).')}</div>
                )}
            </div>
            {/* <div className="mt-5">
                <div className="col-12 mt-2 mb-3">
                    <h5 className="mt-2 mb-2 pointer text-center p-2">
                        {<b>{translate('Proforma invoices').toUpperCase()}</b>}{' '}
                    </h5>
                </div>
                {invoiceConclusionsDetails && invoiceConclusionsDetails.proforma?.length > 0 ? (
                    <div>
                        {' '}
                        <InvoiceConclusionDetailsHeader />
                        <InvoiceConclusionDetailsList
                            invoiceConclusionDetails={invoiceConclusionsDetails?.proforma}
                            defaultCurrency={defaultCurrency}
                        />
                    </div>
                ) : (
                    <div className="text-center">{translate('There is no data for selected date(s).')}</div>
                )}
            </div> */}
        </div>
    );
};

export default branch(
    {
        systemLocale: ['locale'],
        defaultCurrency: ['defaultCurrency'],
        otherSettings: ['otherSettings'],
    },
    FinancialJournal
);
