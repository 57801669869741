import React from 'react';

const LoadingButton = ({ className, onClick, children, disabled }) => {
    const [actionPending, setActionPending] = React.useState(false);
    return (
        <button
            disabled={actionPending || disabled ? true : false}
            onClick={async (e) => {
                setActionPending(true);
                await onClick(e);
                setActionPending(false);
            }}
            className={className}
        >
            {actionPending ? (
                <React.Fragment>
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    {window.translate('Processing...')}
                </React.Fragment>
            ) : (
                children
            )}
        </button>
    );
};

export default LoadingButton;
