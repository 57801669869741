import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

class PageNotFoundScreen extends Component {
    render() {
        return (
            <div className="">
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
            </div>
        );
    }
}

export default branch(
    {
        //showLoadingScreen:["ui","showLoadingScreen"]
    },
    PageNotFoundScreen
);
