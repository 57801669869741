import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order'; //DONT use baobab here

import ActiveReservations from "./sidebarActiveReservations"

// function getPos(el) {
//     // yay readability
//     for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
//     return { x: lx, y: ly };
// }

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            elemHeight: 300
        }
    }
    //  componentDidMount() {
    //     let el = document.getElementById('activity-sidebar');
    //     let footer = document.getElementById('main-footer');

    //     let positionOfTableHeaderElement = getPos(el);
    //     let positionOfDocumentFooterElement = getPos(footer);

    //     const heightOfTheSpaceFromTableHeaderToDocumentFooter =
    //         positionOfDocumentFooterElement.y - (positionOfTableHeaderElement.y);
    //     const elemHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter-48;
    //     this.setState({ elemHeight });
    // }
    render() {
        //0-earlyBird, 1-confirmed, 2-checkedIn 3-canceled, 4- closed, 5-autoblocked

        // const {elemHeight = 300} = this.state;

        return (
            <div id="activity-sidebar" className="p-2 bg-white rounded-sm shadow-sm">
                {this.props.children}
                <ActiveReservations {...this.props} />
            </div>
        );
    }
}

export default branch(
    {
        uiStates: ['uiStates', 'dashboard'],
    },
    Sidebar
);
