import React from 'react';
import { ReactComponent as Search } from '../../assets/custom/icons/search.svg';
import CustomInput from '../../components/custom/input';

const SearchField = (props) => {
    const onSearch = async (e) => {
        if (e.key === 'Enter') {
            props.history.push('/search?' + e.target.value);
        }
    };

    return (
        <CustomInput
            onInputKeyPress={onSearch.bind(this)}
            onInputChange={(e) => {
                if (props.onChangeHandler) {
                    props.onChangeHandler(e);
                }
            }}
            icon={<Search />}
            placeholder={window.translate('Search for a customer or company name')}
        />
    );
};

export default SearchField;
