import React from 'react';
import rest from '@data/restWrapper';
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';
import AssignPriceList from '../reservations2/AssignPriceList';
import numbro from 'numbro';
import { checkOfferMismatch } from '../reservations2/utils';
import { ReservationController } from '../reservations2/resController';
import { translate } from '@data/translations';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { PriceOffer } from '@common/modelDefinition';
import { reservationEnums } from '@data/reservationStatusEnums';
import { ReactComponent as InfoIcon } from '../../assets/custom/icons/info.svg';

/*
OVAJ FAJL TREBA ADEKVATNO TIPIZIRATI!!!!  - ali neka sada stoji ovako!!!
*/

const ctrl = new ReservationController();

function AccommodationPriceOffer(props: any) {
    const { reservation } = props;

    const [offerInvoiceItems, setOfferInvoiceItems] = React.useState([]);
    const [proformaInvoiceItems, setProformaInvoiceItems] = React.useState([]);

    const [priceOfferIntervals, setPriceOfferIntervals] = React.useState([]);
    const [, /* proformaPriceOfferIntervals */ setProformaPriceOfferIntervals] = React.useState([]);

    const { defaultCurrency } = useBranch({
        defaultCurrency: ['defaultCurrency'],
    });

    const {
        id,
        accommodationPriceListId,
        checkOutTimestamp,
        checkInTimestamp,
        adultsNumber,
        childrenNumber,
        genuineInvoiceIssued,
        openPrice,
        statusEnum,
        canceledReservationInvoicingStatus,
    } = reservation;

    const reservationOfferArr = reservation.rememberedAccommodationPriceInfo
        ? reservation.rememberedAccommodationPriceInfo
        : null;
    const reservationOffer =
        reservationOfferArr && reservationOfferArr.length > 0
            ? reservationOfferArr[reservationOfferArr.length - 1]
            : reservation;

    React.useEffect(() => {
        const loadProformaOffer = async () => {
            const { invoiceItems: proformaItems, priceOfferByLinkedIntervals: proformaOffer } = await rest(
                '/getAccommodationOffer',
                'POST',
                { reservation: reservationOffer }
            );
            setProformaInvoiceItems(proformaItems);
            setProformaPriceOfferIntervals(proformaOffer);
        };
        const loadCurrentOffer = async () => {
            const { invoiceItems: items, priceOfferByLinkedIntervals: offer } = await rest(
                '/getAccommodationOffer',
                'POST',
                { reservation: reservation }
            );
            setOfferInvoiceItems(items);
            setPriceOfferIntervals(offer);
        };
        if (accommodationPriceListId || openPrice || statusEnum === 3) {
            loadProformaOffer().catch((err) => {
                console.warn(err);
            });

            loadCurrentOffer().catch((err) => {
                console.warn(err);
            });
        }
        // eslint-disable-next-line
    }, [
        id,
        accommodationPriceListId,
        checkInTimestamp,
        checkOutTimestamp,
        adultsNumber,
        childrenNumber,
        reservation.rememberedAccommodationPriceInfo,
        openPrice,
        statusEnum,
        canceledReservationInvoicingStatus,
    ]); //ako ikada djeca postanu faktor u cijeni, ovdjde ubaci childrenNumber

    if (!accommodationPriceListId && !openPrice && canceledReservationInvoicingStatus !== 2) {
        return <AssignPriceList {...props} />;
    }

    const mismatchOffer = checkOfferMismatch({
        invoiceItems: offerInvoiceItems,
        proformaInvoiceItems,
        reservations: [reservation],
    });

    const hasMissmatch = mismatchOffer?.length > 0 && !genuineInvoiceIssued;

    const totalToPay = proformaInvoiceItems
        .map((i: any) => i.bruttoValueWithDiscount)
        .reduce((a: number, b: number) => a + b, 0);
    const totalTax = proformaInvoiceItems.map((i: any) => i.totalTaxValue).reduce((a: number, b: number) => a + b, 0);

    const totalToPayChangeDetected = offerInvoiceItems
        .map((i: any) => i.bruttoValueWithDiscount)
        .reduce((a: number, b: number) => a + b, 0);
    const totalTaxChangeDetected = offerInvoiceItems
        .map((i: any) => i.totalTaxValue)
        .reduce((a: number, b: number) => a + b, 0);

    const preview = (
        <div className="mt-4p border-top">
            <div className="display-flex mobile-page-form">
                <div className="flex border-right pr-4p">
                    <div className="text-bold mb-4p mt-4p display-flex align-items-center justify-content-between">
                        <div className="display-flex align-items-center">
                            {reservation && (reservation.accommodationPriceListId || reservation.openPrice) ? (
                                <button
                                    disabled={reservation.genuineInvoiceIssued === 1 ? true : false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const _res = { ...reservation };
                                        _res.openPrice = null;
                                        _res.accommodationPriceListId = null;
                                        ctrl.updateActiveReservation(_res);
                                    }}
                                    className="button-info mr-8p"
                                >
                                    <i className="fa fa-exchange" />
                                </button>
                            ) : null}
                            {translate('Given price offer')}:{' '}
                        </div>
                        <div
                            className="fill-primary pointer"
                            onClick={() => {
                                setModalContent(
                                    <div>
                                        <DisplayCompressedPriceOffer
                                            showClearPriceListButton={false}
                                            invoiceItems={offerInvoiceItems}
                                            defaultCurrency={defaultCurrency}
                                            reservation={reservation}
                                            res={reservation}
                                        />

                                        <OfferBreakdown
                                            reservation={reservation}
                                            priceOfferIntervals={priceOfferIntervals}
                                            invoiceItems={offerInvoiceItems}
                                            defaultCurrency={defaultCurrency}
                                        />
                                    </div>,
                                    translate('Accommodation info'),
                                    false,
                                    'modal-md'
                                );
                            }}
                        >
                            <InfoIcon />
                        </div>
                    </div>
                    <React.Fragment>
                        <div className={hasMissmatch ? 'color-error ' : 'color-primary '}>
                            <div className="clear display-flex align-items-center">
                                <div className="reservation-form-accommodation">
                                    {totalToPay
                                        ? numbro(totalToPay).format({
                                              mantissa: 2,
                                              trimMantissa: false,
                                              thousandSeparated: true,
                                          })
                                        : 0}{' '}
                                    {defaultCurrency}
                                    &ensp;|&ensp;
                                    <b>{translate('Tax')}:</b>{' '}
                                    {totalTax
                                        ? numbro(totalTax).format({
                                              mantissa: 2,
                                              trimMantissa: false,
                                              thousandSeparated: true,
                                          })
                                        : 0}{' '}
                                    {defaultCurrency}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                </div>

                {hasMissmatch ? (
                    <div className="flex ml-4p ">
                        <div className="text-bold mt-4p mb-4p">{translate('Given price offer change detected')}:</div>
                        <React.Fragment>
                            <div className={'color-primary '}>
                                <div className="clear display-flex align-items-center">
                                    <div className="reservation-form-accommodation">
                                        {totalToPayChangeDetected
                                            ? numbro(totalToPayChangeDetected).format({
                                                  mantissa: 2,
                                                  trimMantissa: false,
                                                  thousandSeparated: true,
                                              })
                                            : 0}{' '}
                                        {defaultCurrency}
                                        &ensp;|&ensp;
                                        <b>{translate('Tax')}:</b>{' '}
                                        {totalTaxChangeDetected
                                            ? numbro(totalTaxChangeDetected).format({
                                                  mantissa: 2,
                                                  trimMantissa: false,
                                                  thousandSeparated: true,
                                              })
                                            : 0}{' '}
                                        {defaultCurrency}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        <div className="text-center">
                            <ApplyButton
                                buttonText={translate('Apply new price')}
                                reservation={reservation}
                                invoiceItems={offerInvoiceItems}
                                priceOfferIntervals={priceOfferIntervals}
                            />
                        </div>
                    </div>
                ) : !genuineInvoiceIssued ? (
                    <div className="flex ml-4p">
                        <UndoApplyNewOffer buttonText={translate('Undo last offer')} reservation={reservation} />
                    </div>
                ) : null}
            </div>
        </div>
    );

    return <div>{preview}</div>;
}

const DisplayCompressedPriceOffer = (props: any) => {
    const {
        title = '',
        reservation,
        defaultCurrency,
        invoiceItems,
        res,
    } = props;
    const { adultsNumber = 1, childrenNumber = 0 } = res;
    const totalToPay = invoiceItems
        .map((i: any) => i.bruttoValueWithDiscount)
        .reduce((a: number, b: number) => a + b, 0);
    const daysCount = invoiceItems.map((i: any) => i.quantity).reduce((a: number, b: number) => a + b, 0);
    const totalTax = invoiceItems.map((i: any) => i.totalTaxValue).reduce((a: number, b: number) => a + b, 0);
    const totalDiscount = invoiceItems.map((i: any) => i.discountValue).reduce((a: number, b: number) => a + b, 0);

    return (
        <div>
            <b>{title} </b>

            <div className={''}>
                <div className="clear">
                    <div className="display-flex">
                        <div className="text-bold display-flex ">
                            {translate('Adults')}: {adultsNumber};
                        </div>{' '}
                        &ensp;|&ensp;
                        <div className="text-bold display-flex ">
                            {translate('Children')}: {childrenNumber};
                        </div>
                        &ensp;|&ensp;
                        <div className="text-bold display-flex ">
                            {translate('Days')}: {daysCount};
                        </div>
                        &ensp;|&ensp;
                    </div>{' '}
                    <div className="text-bold mt-4p display-flex align-items-center mb-4p">
                        {translate(
                            reservation.statusEnum !== reservationEnums.canceled ? 'Accommodation' : 'Cancellation fee'
                        )}
                        :
                        <div className="reservation-form-accommodation mt-2p mb-4p ml-4p">
                            {totalToPay
                                ? numbro(totalToPay).format({
                                      mantissa: 2,
                                      trimMantissa: false,
                                      thousandSeparated: true,
                                  })
                                : 0}{' '}
                            {defaultCurrency}
                            &ensp;|&ensp;
                            <b>{translate('Tax')}:</b>{' '}
                            {totalTax
                                ? numbro(totalTax).format({ mantissa: 2, trimMantissa: false, thousandSeparated: true })
                                : 0}{' '}
                            {defaultCurrency}
                        </div>
                    </div>
                    {totalDiscount ? (
                        <span>
                            <b>{translate('Discount')}:</b>{' '}
                            {totalDiscount
                                ? numbro(totalDiscount).format({
                                      mantissa: 2,
                                      trimMantissa: false,
                                      thousandSeparated: true,
                                  })
                                : 0}{' '}
                            {defaultCurrency}
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const OfferBreakdown = (props: any) => {
    const { priceOfferIntervals, reservation, alert = '', defaultCurrency } = props;

    const { adultsNumber } = reservation;

    let listStyles = alert ? 'alert alert-danger custom-list-item pb-2p' : 'alert alert-success custom-list-item pb-2p';
    if (alert === undefined) {
        listStyles = 'custom-list-item pb-2p';
    }

    return (
        <div className="mt-2p ">
            {priceOfferIntervals.map((p: any, index: number) => {
                //zašto je dva puta index redeklariran???? ovo nekada istražiti!
                const pricesPerAdult = p.apRules.map((price: number, index: number) => {
                    let result = null;
                    if (adultsNumber === index + 1) {
                        result = (
                            <span>
                                <b>{index + 1}</b> {translate('person(s)')} &#8594; {price} {defaultCurrency}{' '}
                                {translate('per day')}
                            </span>
                        );
                    } else {
                        result = (
                            <small>
                                {index + 1} &#8594; {price} {defaultCurrency}
                            </small>
                        );
                    }
                    return <span key={index}>{result}; &ensp;</span>;
                });

                const _from = moment(p.from).format('LL');
                const _to = moment(p.to).format('LL');
                const header =
                    _from === _to ? (
                        <b>{_from}</b>
                    ) : (
                        <b>
                            {_from} &#8594; {_to}
                        </b>
                    );
                return (
                    <div
                        style={{ width: '100%', margin: 0, padding: 0, marginBottom: '2px' }}
                        key={index}
                        className={listStyles + ' '}
                    >
                        <div>{header}</div>{' '}
                        <div>
                            <small> {pricesPerAdult}</small>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const ApplyButton = (props: any) => {
    //ovo tipizirati!!!!
    const { reservation, /* invoiceItems,*/ priceOfferIntervals, buttonText = translate('Apply') } = props;
    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                setModalContent(
                    <div className="container-fluid">
                        <div className="text-center">
                            {translate('Please confirm.')}
                            <hr />
                        </div>

                        <div className="text-center">
                            <ConfirmButtons
                                onConfirm={async () => {
                                    const res = { ...reservation };
                                    const {
                                        accommodationPriceListId,
                                        checkInTimestamp,
                                        checkOutTimestamp,
                                        adultsNumber,
                                        childrenNumber,
                                        openPrice,
                                        canceledReservationInvoicingStatus,
                                        id,
                                    } = reservation;
                                    const newOffer: PriceOffer = {
                                        accommodationPriceListId,
                                        checkInTimestamp,
                                        checkOutTimestamp,
                                        adultsNumber,
                                        childrenNumber,
                                        priceOfferIntervals: priceOfferIntervals, // OVDJE
                                        openPrice,
                                        canceledReservationInvoicingStatus,
                                        id,
                                    };
                                    const offers = [...res.rememberedAccommodationPriceInfo];

                                    offers.push(newOffer);
                                    res.rememberedAccommodationPriceInfo = offers;
                                    ctrl.updateActiveReservation(res);
                                    closeModal();
                                }}
                                onCancel={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>,

                    translate('Apply new offer'), // header
                    false, // showCloseButton
                    'modal-md'
                );
            }}
            className="button-warning mt-8p"
        >
            {buttonText}
        </div>
    );
};

const UndoApplyNewOffer = (props: any) => {
    const { reservation, buttonText = translate('Undo last offer') } = props;
    if (!reservation.rememberedAccommodationPriceInfo || reservation.rememberedAccommodationPriceInfo.length < 2) {
        return null;
    }

    return (
        <div className="text-center">
            <div
                onClick={() =>
                    setModalContent(
                        <div className="container-fluid">
                            <div className="text-center">
                                {translate('Please confirm.')}
                                <hr />
                            </div>

                            <div className="text-center">
                                <ConfirmButtons
                                    onConfirm={async () => {
                                        const res = { ...reservation };
                                        const offers = [...res.rememberedAccommodationPriceInfo];
                                        offers.pop();
                                        res.rememberedAccommodationPriceInfo = offers;
                                        ctrl.updateActiveReservation(res);
                                        closeModal();
                                    }}
                                    onCancel={() => {
                                        closeModal();
                                    }}
                                />
                            </div>
                        </div>,

                        translate('Undo last offer'), // header
                        false, // showCloseButton
                        'modal-md'
                    )
                }
                className="button-light mt-8p"
            >
                {buttonText}
            </div>
        </div>
    );
};

export default AccommodationPriceOffer;
