import rest from '@data/restWrapper';
import baobab from '@data/state/index';

import {
    RoomInfoModel,
    ReservationModel,
    SelfServiceCheckInModel,
    GroupInfoModel,
    ReservationGuestReferenceModel,
    CustomerModel,
    UserInfo,
    CompanyModel,
    InvoiceType,
    MinibarLogModel,
    SelfCheckInLogModel,
    NotificationTemplateModel,
    MinibarLogWithIncludedModels,
    MessageBrokerInformationModel,
    InvoiceModel,
    InvoiceModelWithInvoiceItems,
} from '@common/modelDefinition';
//@ts-ignore
import { GroupReservation } from './resController';
import { v4 as uuidv4 } from 'uuid';

export async function getUserInfo(userUuid: string): Promise<UserInfo> {
    return await rest('/getUser/' + userUuid);
}

export async function getAvailableRooms(
    checkInTimestamp: number | null,
    checkOutTimestamp: number | null,
    removeRoomInfoId?: number | null
): Promise<RoomInfoModel[]> {
    if (checkInTimestamp && checkOutTimestamp) {
        const occupiedRoomIds = await rest(`/getOccupiedRoomsFromTo?from=${checkInTimestamp}&to=${checkOutTimestamp}`);
        const allRooms = baobab.root.select(['model', 'RoomInfo']).get();

        const avlRooms = allRooms.filter((r: RoomInfoModel) => {
            if (removeRoomInfoId === r.id || !r.availableForRent) {
                return false;
            }
            return !occupiedRoomIds.includes(r.id);
        });
        return avlRooms;
    } else {
        return [];
    }
}

export async function searchCustomer(searchString: string): Promise<CustomerModel[]> {
    return await rest(`/searchCustomer?search=${searchString}`);
}

export async function searchCompany(searchString: string): Promise<CompanyModel[]> {
    return await rest(`/searchCompany?search=${searchString}`);
}

export async function searchInvoice(searchString: string): Promise<InvoiceModelWithInvoiceItems[]> {
    const res = await rest(`/searchInvoice?search=${searchString}`);
    const newRes = res.filter(
        (r: InvoiceModelWithInvoiceItems) =>
            r.invoiceType === 2 && r.reclamation === 0 && !r.reservationId && !r.groupUuid && r.invoiceNumberFull
    );
    return newRes
}

// invoiceType: 2,
// reclamation: 0,
// reservationId: {
//     [Op.eq]: null,
// },
// groupUuid: {
//     [Op.eq]: null,
// },
// invoiceNumberFull: {
//     [Op.ne]: null,
// }
export async function getReservation(reservationId: number): Promise<ReservationModel> {
    return await rest(`/Reservation/${reservationId}`);
}

export async function getSelfServiceCheckInByResId(reservationId: number): Promise<SelfServiceCheckInModel> {
    return await rest('/getSelfServiceCheckInByReservationId/' + reservationId);
}

export async function getGroupInfo(groupUuid: string) {
    return await rest(`/getGroupInfo/` + groupUuid);
}

export async function getPosTerminalAndMinibarProformaInvoices(reservationId: number) {
    return await rest(`/getPosTerminalAndMinibarInvoicesForReservation/${InvoiceType.proforma}/${reservationId}`);
}

export async function getMinibarConsumption(reservationId: number): Promise<MinibarLogWithIncludedModels[]> {
    return await rest(`/getMinibarConsumption/${reservationId}`, 'POST', {});
}

export async function saveMinibarLog(itemInLog: any) {
    return await rest('/saveMinibarLog', 'POST', [itemInLog]);
}

export async function deleteMinibarLog(itemInLog: any) {
    return await rest('/MinibarLog/' + itemInLog.id, 'DELETE');
}

export async function getLastMinibarLogForRoom(roomId: number, posItemId: number): Promise<MinibarLogModel[]> {
    return await rest(`/getLastMinibarLogForRoom/${roomId}/${posItemId}`);
}

export async function getSelfCheckInLogsForRes(reservationId: number): Promise<SelfCheckInLogModel[]> {
    return await rest('/getSelfCheckInLogsByReservationId/' + reservationId);
}

//ovo treba samo i samo kada se soba mijenja... a i tad je upitno!!!! jer rezervacije imaš u baobabu
export async function getResForRoom(roomInfoId: number): Promise<ReservationModel[]> {
    return await rest('/getReservationsForRoom/' + roomInfoId);
}

export async function updateResGuestReference(updatedGuestReference: ReservationGuestReferenceModel) {
    return await rest('/ReservationGuestReference/' + updatedGuestReference.id, 'PUT', updatedGuestReference);
}

export async function _getAllReservationsFromTo(from: any, to: any, page: number, rows: number) {
    return await rest('/getAllReservationsFromTo?from=' + from + '&to=' + to + '&page=' + page + '&rows=' + rows);
}

export async function deleteRes(reservationId: number) {
    return await rest('/Reservation/' + reservationId, 'DELETE');
}

export async function updateCus(customer: CustomerModel) {
    return await await rest('/createOrUpdateCustomer', 'POST', customer);
}

export async function sendSelfCheckInDataNotification(reservationId: number) {
    return await rest('/selfCheckIn/sendCheckInDataNotification', 'POST', {
        reservationId: reservationId,
    });
}

export async function getDoorAccessLogsForRes(reservationId: number) {
    return await rest('/getDoorAccessLogsForReservation/' + reservationId);
}

export async function getGroupRes(statuses?: number[], short?: boolean): Promise<GroupReservation[]> {
    const groupRes: GroupReservation[] = await rest(`/getGroupReservations`, 'POST', {
        statuses,
        short,
    });

    return groupRes;
}

export async function addGuestToRes(
    customerId: number | undefined,
    reservationId: number,
    fixedTaxId: number
): Promise<ReservationGuestReferenceModel> {
    if (reservationId && customerId) {
        return await rest('/ReservationGuestReference', 'POST', {
            customerUuid: uuidv4(),
            customerId,
            reservationId,
            fixedTaxId,
        });
    } else {
        throw new Error('Reservation id missing');
    }
}

export async function removeGuestFromRes(resGuestRefId: number) {
    return await rest('/ReservationGuestReference/' + resGuestRefId, 'DELETE');
}

export async function saveOrUpdateReservation(reservation: ReservationModel) {
    return await rest('/safeSaveOrUpdateReservationModel', 'POST', reservation);
}

export async function saveSelfCheckIn(
    clonedStateSelfServiceCheckIn: SelfServiceCheckInModel,
    reservationId: number | undefined
) {
    if (reservationId) {
        return await rest('/saveSelfCheckIn', 'POST', {
            ...clonedStateSelfServiceCheckIn,
            reservationId,
        });
    } else {
        throw new Error('Reservation id missing');
    }
}

export async function saveGroupRes(reservations: ReservationModel[], groupCover: GroupInfoModel) {
    return await rest('/transactionalSaveGroupReservations', 'POST', {
        reservations,
        groupCover,
    });
}

export async function updateResGuests(resGuestRef: ReservationGuestReferenceModel) {
    return await rest('/ReservationGuestReference/' + resGuestRef.id, 'PUT', resGuestRef);
}

export async function getResGuests(reservationId: number | undefined) {
    if (reservationId) {
        return await rest('/getReservationGuests/' + reservationId);
    } else {
        throw new Error('Reservation id missing');
    }
}

export async function getCustomer(customerId: number | undefined): Promise<CustomerModel> {
    if (customerId) {
        return await rest('/Customer/' + customerId);
    } else {
        throw new Error('Reservation id missing');
    }
}

export async function getCompany(companyId: number) {
    return await rest('/Company/' + companyId);
}

export async function getOrCreateCustomerFromDocScan(
    docScanId: number,
    reservationId: number | undefined,
    customerId: number | undefined,
    visa?: boolean
): Promise<CustomerModel> {
    // if (reservationId) {
    return await rest('/getOrCreateCustomerFromDocumentScan/' + docScanId, 'POST', {
        reservationId,
        customerId,
        visa,
    });
    // } else {
    //     throw new Error('Reservation id missing');
    // }
}

export async function getNotificationTemplate(): Promise<NotificationTemplateModel[]> {
    return await rest('/NotificationTemplate');
}

//nije bila optimizirana. Ne moraš za svaku sobu ići na bacnekd i pitati sobe ako ti se već nalaze u baobabu
export async function getRoom(roomInfoId: number): Promise<RoomInfoModel> {
    const roomInfoMaps: any = baobab.root.select(['map', 'RoomInfo']).get();
    const result: RoomInfoModel = roomInfoMaps[roomInfoId];
    if (result) {
        return result;
    } else {
        const roomInfo: RoomInfoModel = await rest('/RoomInfo/' + roomInfoId, 'GET');
        return roomInfo;
    }
}

export async function createMinibarProformaInvoice(reservationId: number) {
    await rest('/createMinibarProformaInvoice', 'POST', {
        reservationId,
    });
}
export async function getReservationNotifications(
    reservationId: number,
    selfServiceCheckInId: number
): Promise<MessageBrokerInformationModel[]> {
    return await rest(`/getReservationNotification`, 'POST', {
        reservationId,
        selfServiceCheckInId,
    });
}

export async function sendNotificationOnAction(
    model: string,
    reservationId: number | undefined
): Promise<MessageBrokerInformationModel[]> {
    return await rest(`/sendNotificationOnAction`, 'POST', {
        model,
        reservationId,
    });
}

export async function saveInvoice(invoice: InvoiceModel) {
    await rest('/Invoice/' + invoice.id, 'PUT', invoice);
}

export async function getTypeOfRoomWithRoomIds(providerName: string, roomInfoId: number) {
    return await rest('/getTypeOfRoomWithRoomIds', 'POST', {
        providerName,
        roomInfoId,
    });
}