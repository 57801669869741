import rest from '@data/restWrapper';
import baobab from '@data/state';
import { ThermostatPayload } from './thermostat';

export async function loadUserCondoData() {
    const dataPayload = await rest('/condo/getUserCondoData');
    for (const modelName of Object.keys(dataPayload)) {
        baobab.root.select(['model', modelName]).set(dataPayload[modelName]);
    }
}

export async function getOccupancyOverview(userUuid: string) {
    return await rest('/getOccupancyOverview/' + userUuid);
}

export async function getCondoRoomInfoAndReservationsInfo(roomInfoId: number) {
    return await rest('/getCondoRoomInfoAndReservationsInfo/' + roomInfoId);
}

export async function getCondoThermostatData(roomInfoId: number) {
    const condoUserThermostatData = await rest('/src/fetchCondoUserThermostatData/' + roomInfoId, 'POST');
    const { thermostatSettings, sensor_t1 } = condoUserThermostatData;
    
    if (thermostatSettings) {
        const defaultThermostatTemperature_t1 = thermostatSettings.defaultThermostatTemperature_t1
            ? thermostatSettings.defaultThermostatTemperature_t1
            : 0;
        const maxCOMFORT_t1 = thermostatSettings.maxCOMFORT_t1 ? thermostatSettings.maxCOMFORT_t1 : 0;
        const minCOMFORT_t1 = thermostatSettings.minCOMFORT_t1 ? thermostatSettings.minCOMFORT_t1 : 0;

        const thermostatMode_t1 = thermostatSettings.thermostatMode_t1;

        const thermostatPayloads: ThermostatPayload[] = [
            { generalSettingsParamEnum: 'defaultThermostatTemperature_t1', value: defaultThermostatTemperature_t1 },
            { generalSettingsParamEnum: 'maxCOMFORT_t1', value: maxCOMFORT_t1 },
            { generalSettingsParamEnum: 'minCOMFORT_t1', value: minCOMFORT_t1 },
        ];

        const result = { thermostatPayloads, thermostatMode_t1, sensor_t1 };

        return result;
    } else {
        return null;
    }
    // console.log(allData);
}

export async function condoUpdateThermostatValues(thermostatPayloads: ThermostatPayload[], remoteDeviceId: string) {
    for (const thermostatPayload of thermostatPayloads) {
        await rest('/src/setGeneralSettingsByte/' + remoteDeviceId, 'POST', thermostatPayload);
    }
}
