import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import memoize from 'memoize-one';
//import baobab from '../state';
//always look to this and next year in generating repeat copies of pricelist
const repeatRulesEnum = {
    noRepeat: 'noRepeat',
    weeklyOnDays: 'weeklyOnDays',
    monthly: 'monthly',
    yearly: 'yearly',
};

var getDaysArray = memoize(function (start, end, forceFullISODayMatch = false) {
    let arr = [];
    if (forceFullISODayMatch) {
        let startFrom = moment(new Date(start));
        let endISOString = new Date(end).toISOString(true).substr(0, 10);
        let tempStartISOString = new Date(start).toISOString(true).substr(0, 10);
        if (start < end) {
            if (tempStartISOString === endISOString) {
                arr.push(tempStartISOString);
            } else {
                while (tempStartISOString !== endISOString) {
                    tempStartISOString = new Date(startFrom.valueOf()).toISOString(true).substr(0, 10);
                    arr.push(tempStartISOString);
                    startFrom.add(1, 'd');
                }
            }
        }
    } else {
        for (var dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).toISOString(true).substr(0, 10));
        }
    }
    return arr;
});

const getOverlappingPriceListsOnStack = (priceLists) => {
    let overlapps = [];
    let baselines = _.filter(priceLists, (pl) => pl.parentId === null);
    _.forEach(baselines, (baseline) => {
        let plist = _.filter(priceLists, (pl) => pl.parentId === baseline.id);
        let weights = _.uniq(_.map(plist, 'weight'));
        _.forEach(weights, (w) => {
            let plistWithSameWeight = _.filter(plist, (p) => p.weight === w);
            let plistWithSameWeightAndDays = _.flatten(
                _.map(plistWithSameWeight, (p) => {
                    return {
                        ...p,
                        days: getDaysArray(p.validFrom, p.validTo),
                    };
                })
            );

            let conflictingDates = _.groupBy(_.flatten(_.map(plistWithSameWeightAndDays, 'days')), (d) => d);
            conflictingDates = _.filter(conflictingDates, (val) => val.length > 1);
            conflictingDates = _.map(conflictingDates, (val) => val[0]);

            let overlappingIds = [];
            if (conflictingDates.length > 0) {
                _.forEach(plistWithSameWeightAndDays, (p) => {
                    let intersects = _.intersection(p.days, conflictingDates);
                    if (intersects.length > 0) {
                        if (!_.includes(overlappingIds, p.id)) {
                            overlappingIds.push(p.id);
                            p.intersects = intersects;
                            overlapps.push(p);
                        }
                    }
                });
            }
        });
    });
    return overlapps;
};

const getNewRepeats = (pl, startFromYear = new Date().getFullYear(), timelineYearsCount = 1) => {
    let priceListSpans = [];
    let repeatRules = {};

    if (pl.weight === 0) {
        return pl;
    }

    if (pl.repeatRules === null || pl.repeatRules === undefined) {
        return priceListSpans;
    }

    repeatRules = JSON.parse(pl.repeatRules);
    if (repeatRules.noRepeat) {
        priceListSpans.push(pl);
    } else {
        let fromBorder = pl.infinite ? new Date(`${startFromYear}`) : pl.validFrom;
        let toBorder = pl.infinite ? new Date(`${startFromYear + timelineYearsCount}`) : pl.validTo;
        let momentDatePointer = moment(fromBorder);
        let keys = Object.keys(repeatRules);

        if (_.includes(keys, repeatRulesEnum.weeklyOnDays)) {
            let latentTempValue = null;
            while (momentDatePointer.valueOf() <= toBorder) {
                let temp = _.clone(pl);
                let merged = false;
                if (_.includes(repeatRules.weeklyOnDays, momentDatePointer.isoWeekday())) {
                    //+1 since JS is counting days from 0-6 and ISO format in (weeklyOnDays) is from 1 to 7
                    temp.validFrom = momentDatePointer.startOf('day').valueOf();
                    temp.validTo = momentDatePointer.endOf('day').valueOf();
                    if (latentTempValue !== null) {
                        if (latentTempValue.validTo + 1000 > temp.validFrom) {
                            //merge them
                            latentTempValue.validTo = temp.validTo;
                            merged = true;
                        } else {
                            priceListSpans.push(latentTempValue);
                        }
                        if (merged === false) {
                            latentTempValue = temp;
                        }
                    } else {
                        latentTempValue = temp;
                    }
                }
                //console.log(repeatRules.weeklyOnDays, momentDatePointer.day());
                momentDatePointer.add(1, 'd'); //dodaje jedan dan!!!!
            }

            if (latentTempValue !== null) {
                //add latest
                priceListSpans.push(latentTempValue);
                latentTempValue = null;
            }
        } else if (_.includes(keys, repeatRulesEnum.monthly)) {
            let latentTempValue = null;
            while (momentDatePointer.valueOf() <= toBorder) {
                let temp = _.clone(pl);
                let merged = false;
                if (_.includes(repeatRules.monthly, momentDatePointer.date())) {
                    temp.validFrom = momentDatePointer.startOf('day').valueOf();
                    temp.validTo = momentDatePointer.endOf('day').valueOf();

                    if (latentTempValue !== null) {
                        if (latentTempValue.validTo + 1000 > temp.validFrom) {
                            //merge them
                            latentTempValue.validTo = temp.validTo;
                            merged = true;
                        } else {
                            priceListSpans.push(latentTempValue);
                        }
                    }
                    if (merged === false) {
                        latentTempValue = temp;
                    }
                }
                momentDatePointer.add(1, 'd'); //dodaje jedan dan!!!!
            }
            if (latentTempValue !== null) {
                //add latest
                priceListSpans.push(latentTempValue);
                latentTempValue = null;
            }
        } else if (_.includes(keys, repeatRulesEnum.yearly)) {
            let startDayPointer = moment(new Date(repeatRules.yearly.from));
            let endDayPointer = moment(new Date(repeatRules.yearly.to));
            while (startDayPointer.valueOf() <= toBorder) {
                let temp = _.clone(pl);
                temp.validFrom = startDayPointer.startOf('day').valueOf();
                temp.validTo = endDayPointer.endOf('day').valueOf();
                priceListSpans.push(temp);
                startDayPointer.add(1, 'y'); //dodaje jednu godinu!!!!
                endDayPointer.add(1, 'y'); //dodaje jednu godinu!!!!
            }
        }
    }
    return priceListSpans;
};

export {
    getOverlappingPriceListsOnStack
};

export default (accommodationPriceLists, startFromYear, timelineYearsCount) => {
    let result = _.map(accommodationPriceLists, (pl) => {
        return getNewRepeats(pl, startFromYear, timelineYearsCount);
    });
    return _.flatten(result);
};
