//0-earlyBird, 1-confirmed, 2-checkedIn 3-canceled, 4- closed, 5 - autoblocked

export const reservationStatus = {
    earlyBird: 0,
    confirmed: 1,
    checkedIn: 2,
    canceled: 3,
    closed: 4,
    autoblocked: 5, // mislim da je falilo ovo?
};

export const reservationStatus2 = {
    0: 'earlyBird',
    1: 'confirmed',
    2: 'checkedIn',
    3: 'canceled',
    4: 'closed',
    5: 'autoblocked',
};

export enum ReservationStatus {
    'earlyBird',
    'confirmed',
    'checkedIn',
    'canceled',
    'closed',
    'autoblocked',
}

export enum ReservationStatusText {
    'Early bird',
    'Confirmed',
    'Checked in',
    'Canceled',
    'Closed',
    'Auto blocked',
}

export const invoicedStatusEnum = {
    0: 'notPaid',
    1: 'fully paid',
    2: 'Partial payment',
};

export enum InvoicedStatusEnum {
    'notPaid',
    'fully paid',
    'Partial payment',
}

export enum InvoicedStatusEnumText {
    'Not paid',
    'Fully paid',
    'Partial payment',
}

export enum InvoiceReclamationStatusEnums  {
    'Regular',
    'Cancelled',
    'Storno',
};

export enum InvoiceTypeEnums  {
    'Regular',
    'Advance',
    'Proforma',
};

export enum TypeOfPaymentEnums  {
    'G'='Cash',
    'K'='Card',
    'C'='Cheque',
    'V'='Virman',
    'T'='Virman',
};

export enum TerminalTypeEnums  {
    'reservation'='Checkout',
    'posTerminal'='Pos Terminal',
    'reservationConsumption'='Connected terminals',
};

export enum CleaningStatusEnums  {
     'noActionTaken',
     'cleaningRequested',
     'stagedForCleaning',
     'cleaningStarted',
     'cleaningFinished',
     'cleaningPostponed',
     'cleaningSkipped',
};
export enum ReservationCleaningStatusEnums  {
     'Unknown',
     'No reservations',
     'Guest arrival',
     'Due out',
     'Stay',
};

export const genderTypesHologic = {
    male: 'M',
    female: 'F',
    undefined: 'O',
};

export const genderTypesH = {
    M: 'male',
    F: 'female',
    O: 'undefined',
};

export const documentTypesHologic = {
    identityCard: 'I',
    drivingLicence: 'D',
    passport: 'P',
    visa: 'V',
    other: 'X',
};

export const documentTypesH = {
    I: 'identityCard',
    D: 'drivingLicence',
    P: 'passport',
    V: 'visa',
    X: 'other',
};

export const genderTypesFromEStranac = {
    1: 'M',
    2: 'F',
    3: 'O',
};

export const documentTypesFromEStranac = {
    1: 'P',
    2: 'I',
    3: 'X',
};
