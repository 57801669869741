import React, { Component } from 'react';
import rest from '../../../data/restWrapper';
import PageLoader from '../../../components/loaders/loader1';
import AccessCodes from '../../SRCSettings/accessCodes';
import CommunicationError from '../../SRCSettings/communicationError';
import { branch } from 'baobab-react/higher-order';

import { checkIfRoomHasSettings } from '../../../data/controllers/SRCStatus';
import _ from 'lodash';


class BasicSRCSettings extends Component {
    constructor() {
        super();
        this.state = {
            showConnectionError: false,
            remoteDeviceId: null,
            basicSRCSettings: {},
            showMessage: null,
        };
    }
    async componentDidMount() {
        let remoteDeviceId = this.props.remoteDeviceId;
        if (this.props.match && this.props.match.params.remoteDeviceId) {
            remoteDeviceId = this.props.match.params.remoteDeviceId;
        }
        this.redrawComponent(remoteDeviceId);
    }
    async componentDidUpdate(nextProps) {
        if (this.props.remoteDeviceId) {
            //for modal view
            if (nextProps.remoteDeviceId !== this.props.remoteDeviceId) {
                this.redrawComponent(this.props.remoteDeviceId);
            }
        } else {
            if (nextProps.match && nextProps.match.params.remoteDeviceId) {
                if (nextProps.match.params.remoteDeviceId !== this.props.match.params.remoteDeviceId) {
                    this.redrawComponent(this.props.match.params.remoteDeviceId);
                }
            } else {
                this.setState({ remoteDeviceId: null });
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if(this.props?.rooms?.length !== nextProps.rooms.length){
            return true
        }
        if(JSON.stringify(this.state) !== JSON.stringify(nextState)){
            return true
        }
        return false
    }

    async redrawComponent(remoteDeviceId) {
        if (remoteDeviceId) {
            let roomInfo = _.find(this.props.rooms, (r) => r.id === Number(this.props.roomInfoId));
            if (checkIfRoomHasSettings(roomInfo)) {
                try {
                    this.setState({ remoteDeviceId: null, showConnectionError: false });
                    let basicSRCSettings = await rest(
                        '/src/fetchBasicSRCSettingsByRoomInfoId/' + this.props.roomInfoId,
                        'POST'
                    );
                    this.setState({ remoteDeviceId, basicSRCSettings, showConnectionError: false });
                } catch (err) {
                    this.setState({ showConnectionError: true, remoteDeviceId });
                }
            } else {
                this.setState({
                    showMessage: 'SRC settings not available for this device. Probably SOS Calling Device.',
                });
            }
        }
    }
    render() {
        let forceReloadButton = (
            <div className="mb-2">
                <button
                    onClick={() => {
                        this.redrawComponent(this.state.remoteDeviceId);
                    }}
                    className="btn btn-outline-primary"
                >
                    <i className="fa fa-refresh mr-2" aria-hidden="true"></i>
                    {window.translate("Force reload")}
                </button>
            </div>
        );

        if (this.state.showMessage) {
            return <div>{this.state.showMessage}</div>;
        }

        if (this.state.showConnectionError) {
            return (
                <div>
                    {forceReloadButton}
                    <CommunicationError />
                </div>
            );
        }
        if (this.state.remoteDeviceId === null) {
            return (
                <PageLoader
                    style={{ height: '20rem', paddingTop: '8rem' }}
                    header="READING BASIC SRC SETTINGS"
                    heading="(please wait)"
                />
            );
        }
        return (
            <div>
                <AccessCodes
                    reservation={this.props.reservation}
                    basicMode={true}
                    remoteDeviceId={this.state.remoteDeviceId}
                    roomSettings={this.state.basicSRCSettings}
                    locale={this.props.locale}
                />
            </div>
        );
    }
}

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        locale: ['locale'],
    },
    BasicSRCSettings
);
