import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import { getBreadcrumbObjectForComponentsLink } from './pageTitleUtils';
import translate from '@data/translations';

class PageTitle extends Component {

    render() {
        let breadCrumbArr = []
        if(this.props.pagePath){
            breadCrumbArr = getBreadcrumbObjectForComponentsLink(this.props.pagePath)
        }
        // const breadCrumbArr = getBreadcrumbObjectForComponentsLink(window.location.hash.substring(window.location.hash.indexOf("#") + 1))
        if (this.props.alternate) {
            return (<div>
                <h1 className='page-title'>
                    {this.props.title || this.props.pageTitle}
                </h1>
            </div>
            );
        } else {
            return (
                <div>
                    <h1 className='page-title'>
                    <div className='breadcrumb-wrapper'>
                    {breadCrumbArr.map((obj,ind) => {
                        if(ind === breadCrumbArr.length - 1){
                            return <span key={ind}><span  >
                            {translate(obj.title)}
                        </span> 
                        </span>
                        }else{
                            return <span key={ind}><a href={'#' + obj.link} >
                            {translate(obj.title)}
                        </a>{" / "}
                        </span>
                        }
                    })
                    }
                    </div>
                        {this.props.title || this.props.pageTitle}
                    </h1>
                </div>
            );
        }
    }
}

export default branch(
    {
        pageTitle: ['pageTitle'],
        pagePath: ['pagePath'],
    },
    PageTitle
);