import React from 'react';
import baobabTree from './data/state';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { branch } from 'baobab-react/higher-order';
import asyncComponent from "./components/asyncComponent";

import PageNotFound from './pages/pageNotFound';
import PageLoader from './components/loaders/loader1';

import MasterLayout from './pages/masterLayout';

import componentPageTitle from '@components/pageTitleUtils';
const Login = asyncComponent(() => import('./authPages/login/index'));


var MasterLayoutWithRouter = withRouter(MasterLayout);

const PublicRoutes = (props) => {
	function onEnterRouteHandler(pageTitle, nextRouteObj) {		
		baobabTree.root.select("pageTitle").set(pageTitle);
		baobabTree.root.select("pagePath").set(nextRouteObj.match.path);
		const licence = baobabTree.root.select("licence").get();
		if(licence?.licence?.decodedLicence?.pack?.features?.includes("onPrem") && nextRouteObj.location.pathname === "/" && !nextRouteObj.location.search){
			return <Redirect to={"?roomsOnPrem"}></Redirect>
		}
		if (props.fixedRoute && props.fixedRoute !== "" && nextRouteObj.location.pathname !== props.fixedRoute) {
			return <Redirect to={props.fixedRoute} />;
		} else if (props.dashboardRoute && props.dashboardRoute !== "" && props.dashboardRoute !== "/" && nextRouteObj.location.pathname === "/") {
			return <Redirect to={props.dashboardRoute} />;
		} else if((!props.locale || !props.hotelName || !props.defaultCurrency) && window.location.hash.lastIndexOf("firstTimeSetup")===-1){
			return <Redirect to={"/firstTimeSetup"} />;
		} else {
			return null;
		}
		//console.log("TU SAM", pageTitle);
	}
	return (
		<Router {...props}  >

			<MasterLayoutWithRouter {...props}  >
				<Switch {...props}>

					{componentPageTitle.map((itm, ind) => {
						return (<Route key={ind} ignoreScrollBehavior exact path={itm.path} component={itm.component} children={onEnterRouteHandler.bind(this, window.translate(itm.pageTitle))} />)
					}
					)}

					<Route pageTitle="Page not found" component={PageNotFound} />

				</Switch>
			</MasterLayoutWithRouter>

		</Router>
	);
}


const UnauthorizedRouter = (props) => (
	<Router {...props} ignoreScrollBehavior>
		<Switch {...props}>
			<Route
				exact
				path="/"
				render={(_props) => {
					return <Login {..._props} {...props} showRegistration={props?.about?.adminUsersCount === 0} />
				}}
			/>
			<Route pageTitle="Login"
			 render={(_props) => {
				return <Login {..._props} {...props} showRegistration={props?.about?.adminUsersCount === 0} />
			}}
			 />
		</Switch>
	</Router>
);


const ExportRoutes = (props) => {
	if (props.authTokenInfo || props.keycloak?.authenticated) {
		if (props.socketConnection && props.socketConnection.showConnectingScreen) {
			return (
				<PageLoader
					header={props.socketConnection.connectingMessage}
					heading={props.socketConnection.heading}
				/>
			);
		} else {
			return <PublicRoutes {...props} />;
		}
	} else {
		if (props.socketConnection.showConnectingScreen) {
			return (
				<PageLoader
					header={props.socketConnection.connectingMessage}
					heading={props.socketConnection.heading}
				/>
			);
		} else {
			return <UnauthorizedRouter {...props} />;
		}
	}
};

export default branch(
	{
		about: ['about'],
		locale: ['locale'],
		defaultCurrency: ['defaultCurrency'],
		hotelName: ['monkeys', 'companyInfo', 'hotelName'],
		authTokenInfo: ['authTokenInfo'],
		fixedRoute: ['state', 'userPermissions', 'fixedRoute'],
		dashboardRoute: ['state', 'userPermissions', 'dashboardRoute'],
		socketConnection: ['socketConnection'],
		licenceType: ['licence', 'licence', 'decodedLicence', 'licenceType'],
		keycloak: ['keycloak'],
	},
	ExportRoutes
);
