import * as React from 'react';
import { ReservationController } from '../reservations2/resController';
import { translate } from '@data/translations';
import { SelfCheckInLogModel } from '@common/modelDefinition';

interface SelfCheckInLogsProps {
    // reservation: Reservation;
}

function SelfCheckInLogs(props: SelfCheckInLogsProps) {
    const ctrl = new ReservationController();
    const reservation = ctrl.getActiveReservation();

    const selfCheckInLogs: SelfCheckInLogModel[] = reservation.SelfCheckInLogs; //kupim ove logove iz reservation modela - ne useEffecta!!!

    if (!reservation.enableSelfCheckIn) {
        return null;
    }

    return (
        <div className="mt-4p">
            <div className="custom-list-header" style={{ width: '100%', margin: 0, padding: 0 }}>
                <div>{translate('Date')}</div>
                <div>{translate('Method')}</div>
                <div>{translate('Description')}</div>
            </div>
            <div className="" style={{maxHeight: "300px", overflowY: "auto"}}>
                {selfCheckInLogs?.length > 0 ? (
                    selfCheckInLogs.map((s, key) => {
                        const classN = s.methodSuccessfullyPassed ? '' : ' text-danger';
                        return (
                            <div
                                key={key}
                                className={'custom-list-item ' + classN}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >
                                <div>{new Date(s.timestamp).toLocaleString()}</div>
                                <div className="overflow-hidden">{s.methodName}</div>
                                <div>{s.description}</div>
                            </div>
                        );
                    })
                ) : (
                    <div>{translate('No self check in logs')}</div>
                )}
            </div>
        </div>
    );
}

export default SelfCheckInLogs;
